import React, { useRef } from "react";
import moment from "moment";

import {MenuInput} from "../../router_component";
import PropTypes from "prop-types";
import styles from "assets/scss/components/menuCard.module.scss";
import {useMenu} from "../../router_context";
import useUi from "hooks/ui.hook";
import md5 from "md5";
import deepClone from "helpers/deepClone";

import { Copy, Clipboard } from "react-feather";


const MenuCard = (props) => {
  const { color, date, origin } = props;
  const [ctx, dispatch] = useMenu();
  const [ui] = useUi();
  const template = ui?.groupement?.template;
  
  const _copy = () => {
    const repasState = ctx.state[date][ctx.meal];
    const _copy = {};

    Object.entries(repasState).forEach(([category, dish]) => {
      Object.entries(dish).forEach(([_dishId, dishData]) => {
        if (dishData.origin === origin) {
          if (!_copy[category]) _copy[category] = [];

          _copy[category].push(dishData);
        }
      });
    });

    dispatch({ type: "setProperty", property: "copy", value: _copy });
  };

  const _paste = () => {
    const _repasState = deepClone(ctx.state[date][ctx.meal]);

    Object.entries(ctx.copy).forEach(([_category, _dishes]) => {
      if(template[ctx.meal].menus[origin].categories.includes(_category)){
        if(!_repasState[_category])_repasState[_category] = {};
        const _currentDishes = Object.values(_repasState[_category]).filter(_d => _d.origin === origin);
        _dishes.forEach(_dish => {

          if(!_currentDishes.find(_d => _d.name === _dish.name)){

            const order = Object.entries(_repasState[_category]).filter(([, dish]) => dish.origin === origin).length + 1;
            const hash = md5(`${date}-${ctx.meal}-${_category}-${origin}-${_dish.name}-${order}`);
            _repasState[_category][hash] = {
              ..._dish,
              origin,
              order,
            };
          }
        });
  
      }
    });
    
    dispatch({
      type: "setState",
      state: {
        ...ctx,
        state: {
          ...ctx.state,
          [date]: {
            ...ctx.state[date],
            [ctx.meal]: _repasState
          },
        },
      },
    });
  };

  return (
    <div className={`${styles.container} ${styles[color]}`}>
      {ui &&
        ctx &&
        ui.groupement &&
        ui.groupement.template &&
        ui.groupement.template[ctx.meal] &&
        ui.groupement.template[ctx.meal].menus &&
        ui.groupement.template[ctx.meal].menus[origin] && template[ctx.meal].menus[origin].type === "daily" &&
        <div style={{ width: "100%", paddingTop: 10, position: "relative" }}>
          <div style={{ position: "absolute", right: 5, top: 5, display: "flex", flexDirection: "row", gap: 5 }}>
            <div title="copier" style={{ cursor: "pointer" }} onClick={_copy}>
              <Copy size={20} />
            </div>
            {ctx.copy ? <div title="coller" style={{ cursor: "pointer" }} onClick={_paste}>
              <Clipboard size={20} />
            </div> : null}
          </div>
        </div>
      }
      {ui &&
        ctx &&
        ui.groupement &&
        ui.groupement.template &&
        ui.groupement.template[ctx.meal] &&
        ui.groupement.template[ctx.meal].menus &&
        ui.groupement.template[ctx.meal].menus[origin]
        ? ui.groupement.template[ctx.meal].menus[origin].categories.map(
          (element) => (
            ctx.meal !== "Déjeuner" || moment(date).weekday() < 6 ?
              <React.Fragment key={element}>
                <h3>{element}</h3>
                <MenuInput
                  category={element}
                  date={date}
                  color={color}
                  origin={origin}
                />
              </React.Fragment>
              :
              element !== "garniture" ?
                <React.Fragment key={element}>
                  <h3>{element === "plat" ? "cocotte" : element}</h3>
                  <MenuInput
                    category={element}
                    date={date}
                    color={color}
                    origin={origin}
                  />
                </React.Fragment>
                : null
          )
        )
        : null}
    </div>
  );
};

MenuCard.defaultProps = {
  color: "green",
};

MenuCard.propTypes = {
  color: PropTypes.any,
  date: PropTypes.any,
  origin: PropTypes.string,
};

export default MenuCard;
