import firebase from "firebase";
const firestore = firebase.firestore;

export const getAllLogs_rt = (_, cb, err) => {
  const subscribe = firestore()
                      .collection("logguer")
                      .orderBy("createdAt", "desc")
                      .onSnapshot(snap => {
                        const data = snap.docs.map(doc => ({
                          uid: doc.id,
                          ...doc.data()
                        }));
                        cb(data);
                      }, err ?? (() => {}));
  return subscribe;
}

export const getLogById = async (uid) => {
  try {
    let doc = await firestore().collection("logguer").doc(uid).get();
    return {uid: doc.id, ...doc.data()};
  } catch (e) {
    console.error(e);
    return null;
  }
}

export const addLog = async ({
  app = "major",
  feature = null,

  userId = null,
  etabId = null,
  serial = null, //if possible, serial id of the device

  title = "",
  message = "",
  position = null, //dans le code, (fichier->fonction)
  level = "info", //info, warning, error
}) => {
  try {
    await firestore().collection("logguer").add({
      app,
      feature,
    
      userId,
      etabId,
      serial,
    
      title,
      message,
      position,
      level,
      createdAt: new Date()
    });
  } catch (e) {
    console.error(e);
  }
}