import React, { useEffect, useState } from "react";
import useUI from "@/hooks/ui.hook";
import { useMenuReservation } from "../../router_context";
import deepClone from "@/helpers/deepClone";
import { getSignatureById } from "@/services/ressources/orders";
import { decompressString, unConcat, generateSVGFromPaths, generatePathsFromSiplifiedSVGPaths } from "@/helpers/svg";
import { ReactSVG } from 'react-svg'

const SignatureDisplay = (props) => {
  const { showSignature, page, reservation } = props;
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();

  const [signature, setSignature] = useState(null);

  useEffect(() => {
    if(!showSignature) { setSignature(null); return; }
    (async () => {
      const orders = reservation
      const signature = await getSignatureById({etabId: ui.establishment.uid, orderId: orders[page]?.orderId});
      if (!signature) return;

      // Decompression
      const unziped = await decompressString(signature);

      // Deconcatenation
      const rebuilded = unConcat(unziped);

      // Reconstruction
      const signatureSVG = `data:image/svg+xml;utf8,${encodeURIComponent(generateSVGFromPaths(generatePathsFromSiplifiedSVGPaths(rebuilded)))}`;
      setSignature(signatureSVG);
    })();
  }, [showSignature, page]);

  return (
    <>
      {showSignature && signature && 
        <div style={{zindex: 1000, width: "100%", height: "100%", backgroundColor: "#ffffff", position: "absolute", top: 0, left: 0, display: "flex", justifyContent: "center", alignItems: "center"}}>
          <ReactSVG 
            src={signature}
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 100%; height: 100%; position: absolute');
            }}
            loading={() => <div>Loading SVG...</div>}
            fallback={() => <div>Failed to load SVG</div>}
            style={{height: "100%", width: "100%"}}
          />
        </div>
      }
    </>
  );
};

export default SignatureDisplay;