import React from "react";
import PropTypes from "prop-types";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import moment from "moment";
import Logo from "./assets/Logo.png";
// import MenuHeader from "./assets/menuHeader.png";
// import Decoration from "./assets/textDecoration.png";

import CenturyGothic from "fonts/centuryGothic/CenturyGothic.ttf";
import CenturyGothicBold from "fonts/centuryGothic/CenturyGothicBold.ttf";

import Brandon from "fonts/brandon/BrandonGrotesque-Regular.ttf";
import BrandonBold from "fonts/brandon/BrandonGrotesque-Bold.ttf";

Font.register({
  family: "CenturyGothic",
  fontWeight: "normal",
  src: CenturyGothic,
});
Font.register({
  family: "CenturyGothic",
  format: "bold",
  src: CenturyGothicBold,
});

Font.register({
  family: "Brandon",
  fontWeight: "normal",
  src: Brandon,
});
Font.register({
  family: "Brandon",
  format: "bold",
  src: BrandonBold,
});

const styles = StyleSheet.create({
  division: {
    display: "flex",
    flexDirection: "row",
  },
  //--------------------------  -------------------------------//
  //--------------------------  -------------------------------//
  page1Container: {
    height: 595,
    flex: 1,
    alignItems: "center"
  },
  page1Head: {
    backgroundColor: "#1c2140",
    height: 140,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  page1HeadMenu: {
    color: "white",
    fontSize: 26,
    fontFamily: "CenturyGothic",
    fontWeight:"bold",
  },
  page1HeadDuJour: {
    color: "#e76d90",
    fontSize: 24,
    fontFamily: "CenturyGothic",
    fontWeight:"bold",  },

  page1Content: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    width: "100%",
    height: "100%",
    justifyContent: "space-between"
  },
  titleDate: {
    marginTop: 10,
    color: "#1c2140",
    fontSize: 13,
    fontFamily: "CenturyGothic",
    fontWeight:"bold",  
  },
  //--------------------------  -------------------------------//
  menuPrice: {
    textAlign: "right",
    color: "#1c2140",
    fontFamily: "CenturyGothic",
    fontWeight:"bold",
  },
  repasContainer: {
    marginBottom: 25,
  },
  dishContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center"
  },
  dishName: {
    color: "#1c2140",
    fontFamily: "CenturyGothic",
    fontSize: 13,
  },
  dishCategory: {
    // color: "#1c2140",
    fontFamily: "CenturyGothic",
    fontWeight:"bold",
    fontSize: 13,
  },
  repasTitle: {
    color: "#e76d90",
    fontSize: 16,
    textAlign: "center",
    fontFamily: "CenturyGothic",
    fontWeight:"bold",
  },
  endOfMenuContainer: {
    width: "100%",
    marginTop: 5
  },
  endOfMenu: {
    textAlign: "center",
    fontFamily: "CenturyGothic",
    fontWeight:"bold",
    fontSize: 12
  },
  //--------------------------  -------------------------------//
  //--------------------------  -------------------------------//
  page2Container: {
    height: 590,
    flex: 1,
    alignItems: "center",
    marginTop: 5
  },
  page2Content: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  question: {
    color: "#e76d90",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 5,
    fontFamily: "CenturyGothic"
  },
  tradeOffer: {
    color: "#1c2140",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 5,
    fontFamily: "CenturyGothic"
  },
  remplacementContainer: {
    backgroundColor: "#1c2140",
    width: "90%",
    height: 180,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderRight: "10px solid #e76d90",
    borderBottom: "10px solid #e76d90",
  },
  categoryDrinkContainer: {
    width: "90%"
  },
  drinkTitle: {
    color: "#e76d90",
    fontWeight: "bold",
    marginBottom: 5,
    fontFamily: "CenturyGothic"
  },
  drinkContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  drinkText: {
    fontSize: 11,
    color: "#1c2140",
    fontFamily: "CenturyGothic"
  },
  note: {
    fontSize: 9,
    color: "#1c2140",
    fontFamily: "CenturyGothic"
  },
  pageDots: {
    flex: 1,
    overflow: "hidden",
    color: "#1c2140",
    fontFamily: "CenturyGothic",
    fontSize:7,
    marginHorizontal: 4
  },
  logo: {
    width: 110,
    height: 70,
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  remplcaementDishContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
  },
  dishWeekCategory: {
    color: "#ec871c",
    fontWeight: "bold",
    fontFamily: "CenturyGothic",
    fontSize: 12
  },
  dishWeek: {
    color: "white",
    fontWeight: "bold",
    fontFamily: "CenturyGothic",
    fontSize: 10,
    textAlign: "center",
  },
  separator: {
    width: "60%",
    height: 1,
    backgroundColor: "#ec871c"
  },
  bottom: {
    width: "100%",
    display: "flex",
    flexDirection:"row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bottomText: {
    fontSize: 9,
    marginTop: 10,
    marginLeft: 15,
    color: "#1c2140",
    fontFamily: "CenturyGothic",
  },
  allergeneIndex: {
    fontSize: 6,
    paddingBottom: 5,
    fontWeight: "bold",
    fontFamily: "CenturyGothic"
  }
});

const HeurusDayRestaurantPDF = (props) => {
  const { day, data, ui } = props;
  const template = ui.establishment.template;
  
  const sortedRepas = Object.keys(template).sort((a,b) => template[a].heure - template[b].heure);

  const boissons = [
    {label: "Soda canette 33cl (Coca-colas Coca-cola zéro, Orangina)", categorie: "noAlcool", prix: "2"},
    {label: "Jus de fruit (verre)", categorie: "noAlcool", prix: "1"},
    {label: "Bière sans alcool", categorie: "noAlcool", prix: "2"},
    {label: "Eau minérale bouteille 1L", categorie: "noAlcool", prix: "2"},
    {label: "Eau gazeuse canette 33cl", categorie: "noAlcool", prix: "2"},
    {label: "Eau gazeuse bouteille 1L", categorie: "noAlcool", prix: "3"},

    {label: "Vin au verre : rosé / blanc / rouge", categorie: "alcool", prix: "2"},
    {label: "Pichet de vin : 25 cl rosé / blanc / rouge", categorie: "alcool", prix: "3"},
    {label: "Pichet de vin : 50 cl rosé / blanc / rouge", categorie: "alcool", prix: "6", note: "Pour les vins en bouteille, n'hésitez pas à demander notre carte des vins"},
    {label: "Bière", categorie: "alcool", prix: "2.5"},
  ];

  const remplacement = {
    "Entrée": {
      "1": "Assiette de charcuterie",
      "2": "\ncrudités (tomates / carottes râpées)"
    },
    "Plat": {
      "1":  "Poisson", 
      "2": "Jambon",
      "3": "Omelette",
      "4":"Steak haché (bien cuit)",
    },
    "Accompagnement": {
      "1": "Pommes de terre",
      "2": "Haricots verts",
      "3": "Salade",
    },
    "Dessert": {
      "1": "Yaourt nature",
      "2":"Fruits",
      "3": "Fromage blanc",
      "4": "\nCrème dessert (vanille, chocolat)",
      "5": "Compote"
    }
  };

  const getTextSize = (text) => {
    const regex = /(.*)\(([\d-]+)\)/;
    const match = text.match(regex);

    const _text = match && match[1] ? match[1] : text;
    
    if (_text.length >= 45) {
      const size = 13 - Math.floor(_text.length / 25);
      return {fontSize: size };
    } else {
      return null;
    }
  };

  const allergenesIndexDisplay = (dish, _indxDish) => {
    const regex = /(.*)\(([\d-]+)\)/;
    const match = dish.match(regex);

    if (match) {
      return (
        <>
          <Text key={_indxDish} style={[styles.dishName, getTextSize(match[1])]}>
            {match[1]}
          </Text>
          <Text style={styles.allergeneIndex}>({match[2]})</Text>
         
        </>
      );
    } else {
      return (
        <>
          <Text key={_indxDish} style={[styles.dishName, getTextSize(dish)]}>{dish}</Text>
        </>
      );
    }
  };

  return (
    <Document>
        <Page size="A4" orientation="landscape">
          <View style={styles.division}>
            {Array.from({length: 2}).map((_, index) => (
              <View style={styles.page1Container} key={index}>
                <View style={styles.page1Head}>
                  <Text style={styles.page1HeadMenu}>MENU</Text>
                  <Text style={styles.page1HeadDuJour}>du jour</Text>
                </View>
                <View style={styles.page1Content}>
                  <Text style={styles.titleDate}>{moment(day).format("dddd D MMMM YYYY").charAt(0).toUpperCase() + moment(day).format("dddd D MMMM YYYY").slice(1)}</Text>
                  {sortedRepas.map((_repas) => (
                    <View style={styles.repasContainer} key={_repas}>
                      <Text style={styles.repasTitle}>{_repas}</Text>
                      <Text style={styles.menuPrice}>{_repas === "Déjeuner" ? "14,50 €" : "8,50 €"}</Text>
                      {Object.keys(template[_repas].menus).sort((a, b) => template[_repas].menus[a].weight - template[_repas].menus[b].weight).slice(0, 1).map(_menu => (
                        <View key={_menu}>
                          {template[_repas].menus[_menu].categories.map((_category, _indx) => (
                            <View key={_indx} style={styles.category}>
                              {data && data[_repas] && data[_repas][_category] &&
                                Object.values(data[_repas][_category])
                                  .filter(_dish => _dish.origin === _menu)
                                  .sort((a, b) => a.order - b.order)
                                  .map((dish, _indxDish, array) => (
                                    
                                    <View key={_indxDish} style={styles.dishContainer}>
                                      <Text style={styles.dishCategory}>{_category}</Text>
                                      <Text style={styles.pageDots}>
                                        {new Array(500).fill("•").join("")}
                                      </Text>
                                      {allergenesIndexDisplay(dish.name, _indxDish)}
                                    </View>
                                  ))
                              }
                            </View>
                          ))}
                          {_repas === "Déjeuner" ? (
                                <View style={styles.endOfMenuContainer}>
                                  <Text style={styles.endOfMenu}>- Fromage, Fromage blanc ou yaourt nature -</Text>
                                  <Text style={styles.endOfMenu}>- Café ou thé -</Text>
                                </View>
                              ) : null}
                        </View>
                      ))}
                    </View>
                  ))}
                  <View style={styles.bottom}>
                    <Text style={styles.bottomText}>Tarif service compris</Text>
                    <Image src={Logo} style={styles.logo} />
                  </View>
                </View>
              </View>
            ))}
          </View>
        </Page>
        <Page size="A4" orientation="landscape">
          <View style={styles.division}>
          {Array.from({length: 2}).map((_, index) => (
            <View style={styles.page2Container} key={index}>
              <Text style={styles.question}>Vous souhaitez autre chose ?</Text>
              <Text style={styles.tradeOffer}>Nous vous proposons :</Text>
              <View style={styles.page2Content}>
                <View style={styles.remplacementContainer}>
                {
                  Object.keys(remplacement).map((category, categoryIndex) => (
                    <View key={categoryIndex} style={styles.remplcaementDishContainer}>
                      <Text style={styles.dishWeekCategory}>- {category} -</Text>
                      <Text style={styles.dishWeek}>{Object.values(remplacement[category]).join(" / ")}</Text>
                    </View>
                  ))
                }
                </View>
                <View style={styles.categoryDrinkContainer}>
                  <Text style={styles.drinkTitle}>Boissons sans alcool</Text>
                  {boissons.filter(boisson => boisson.categorie === "noAlcool")
                  .map((boisson, index) => (
                    <>
                      <View key={index} style={styles.drinkContainer}>
                        <Text style={styles.drinkText}>{boisson.label}</Text>
                        <Text style={styles.pageDots}>
                          {new Array(500).fill("•").join("")}
                        </Text>
                        <Text style={styles.drinkText}>{boisson.prix}€</Text>  
                      </View>
                      {boisson.note ? <Text style={styles.note}>{boisson.note}</Text> : null}
                    </>
                  ))
                  }
                </View>
                <View style={styles.categoryDrinkContainer}>
                  <Text style={styles.drinkTitle}>Boissons alcoolisées</Text>
                  {boissons.filter(boisson => boisson.categorie === "alcool")
                  .map((boisson, index) => (
                    <>
                      <View key={index} style={styles.drinkContainer}>
                        <Text style={styles.drinkText}>{boisson.label}</Text>
                        <Text style={styles.pageDots}>
                          {new Array(500).fill("•").join("")}
                        </Text>
                        <Text style={styles.drinkText}>{boisson.prix}€</Text>  
                      </View>
                      {boisson.note ? <Text style={styles.note}>{boisson.note}</Text> : null}
                    </>
                  ))
                  }
                </View>
                <View style={styles.bottom}>
                    <Text style={styles.bottomText}>Tarif service compris</Text>
                    <Image src={Logo} style={styles.logo} />
                  </View>
              </View> 
                        
            </View>
          ))}     
          </View>
        </Page>
    </Document>
  );
};
HeurusDayRestaurantPDF.propTypes = {
  day: PropTypes.any.isRequired,
  data: PropTypes.object.isRequired,
  ui: PropTypes.any,
};

export default HeurusDayRestaurantPDF;
