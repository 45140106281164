import PropTypes from "prop-types";
import {
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import React, { useState, useMemo, useEffect } from "react";
import styles from "../styles/lastPage.module.scss";
import useGazette from "../../../context";
import useUI from "hooks/ui.hook";
import { Edit3, Plus, Trash } from "react-feather";
import contentTypes from "../contentTypesHeurus";
import Logo from "../assets/LogoWhiteText.png";
import Birthdayhat from "../assets/BirthdayHat.png";
import BirthdayCake from "../assets/BirthdayCake.png";
import moment from 'moment';

const LastPageMiniature = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className={styles.miniature}>
      Page de type &quot;Éphéméride&quot;
    </div>
  );
};

const LastPage = (props) => {
  const {
    page,
    onEditPage,
    onRemove,
    onAddContent,
    onRemoveContent,
    onEditContent,
  } = props;

  const [ctx, dispatch] = useGazette();
  
  const monthUsersBirthday = () => {
    const refDate = moment(ctx.editionDate) ?? moment(); 
    return ctx.users.filter(user => user.birthdate && moment(user.birthdate.toDate()).format("MM") === refDate.format("MM") && user.showBirthday)
    .sort((a, b) => {
      const dateA = moment(a.birthdate.toDate()).format("DDMM");
      const dateB = moment(b.birthdate.toDate()).format("DDMM");
      return dateA.localeCompare(dateB);
    });
  };

  const countTotalAge = () => {
    let total = 0;
    monthUsersBirthday().forEach(user => {
      total += moment().diff(moment(user.birthdate.toDate()), "years") + 1;
    });
    return total;
  };  

  // SI cette condition est remplie, on considère que la page vient d'être créée 
  if(page.title === "") {
    page.title = "Éphémérides";
    
    const defaultContents = {
      // "1": {
      //     contentType: "LastPage",
      //     format: "LastPage",
      //     title: "Titre",
      //     content: "Contenu",
      //     author: "Auteur"
      //   }
    };
    Object.keys(defaultContents).forEach(field => {
      page[field] = JSON.stringify(defaultContents[field]);
    });
  }
  return (
    <>
      <div className={styles.head}></div>
      <div className={styles.page}>
        <div className={styles.content}>
          {/* ANNIVERSAIRES */}
          <div>
            <div className={styles.sectionContainer}>
              <div className={styles.titleContainer}>
                <div className={styles.sectionTitle}>
                  ANNIVERSAIRES
                </div>
              </div>

              <div className={styles.sectionContent}>
                <div className={styles.birthdays}>
                  Souhaitons un joyeux anniversaire à {monthUsersBirthday().length} personne(s).
                </div>
                <div className={styles.totalBirthdays}>
                  <div className={styles.totalBirthdaysNumber}>
                    {countTotalAge()}
                  </div>
                  <div className={styles.totalBirthdaysText}>
                    C'est le nombre de bougies que nous allons souffler en {moment(ctx.editionDate ?? new Date()).format("MMMM") }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rowComponents}>
          {/* AGENDA */}
          {page["1"] &&
            contentTypes.find(
              (type) => type.slug === JSON.parse(page["1"]).contentType
            ) ? (
              <div className={`${styles.editoField}`}>
                <Trash
                  color="red"
                  size={40}
                  className={styles.trash}
                  onClick={() => onRemoveContent("1")}
                />
                <Edit3
                  color="blue"
                  size={40}
                  className={styles.edit}
                  onClick={() => onEditContent({ format: "agenda", key: "1" })}
                />
                {contentTypes
                  .find((type) => type.slug === JSON.parse(page["1"]).contentType)
                  .component({ ...JSON.parse(page["1"]) })}
              </div>
            ) : (
              <div
                className={`${styles.editoField} ${styles.add}`}
                onClick={() => onAddContent({ format: "agenda", key: "1" })}
              >
                <Plus size={120} />
              </div>
            )}
          {/* DICTON DU MOIS */}
          {page["2"] &&
            contentTypes.find(
              (type) => type.slug === JSON.parse(page["2"]).contentType
            ) ? (
              <div className={`${styles.editoField}`}>
                <Trash
                  color="red"
                  size={40}
                  className={styles.trash}
                  onClick={() => onRemoveContent("2")}
                />
                <Edit3
                  color="blue"
                  size={40}
                  className={styles.edit}
                  onClick={() => onEditContent({ format: "quote", key: "2" })}
                />
                {contentTypes
                  .find((type) => type.slug === JSON.parse(page["2"]).contentType)
                  .component({ ...JSON.parse(page["2"]) })}
              </div>
            ) : (
              <div
                className={`${styles.editoField} ${styles.add}`}
                onClick={() => onAddContent({ format: "quote", key: "2" })}
              >
                <Plus size={120} />
              </div>
            )}
          </div>
          {/* PIED DE PAGE */}
          <div>
            <div className={styles.footer}>
              <div className={styles.footerRow}>
                <img src={Logo} className={styles.logo}/>
                <div className={styles.footerText}>
                  La Gazette Heurus est une gazette interne destinée à nos résidents...
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <Trash
          color="red"
          size={40}
          className={styles.bottomTrashIcon}
          onClick={onRemove}
        />
      </div>
    </>
  );
};

const LastPagePdf = (props) => {
  const { page, ctx, index, contentTypes, ui } = props;

  const monthUsersBirthday = () => {
    const refDate = moment(ctx.editionDate) ?? moment(); 
    return ctx.users.filter(user => user.birthdate && moment(user.birthdate.toDate()).format("MM") === refDate.format("MM") && user.showBirthday)
    .sort((a, b) => {
      const dateA = moment(a.birthdate.toDate()).format("DDMM");
      const dateB = moment(b.birthdate.toDate()).format("DDMM");
      return dateA.localeCompare(dateB);
    });
  };

  const countTotalAge = () => {
    let total = 0;
    monthUsersBirthday().forEach(user => {
      total += moment().diff(moment(user.birthdate.toDate()), "years") + 1;
    });
    return total;
  };  

  const pdfStyles = StyleSheet.create({
    container: {
      width: "100%",
      height: "100%",
      padding: "10px"
    },
    sectionContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 5,
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      whidth: "20%",
    },
    sectionTitle: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: 16,
      marginBottom: 5,
      padding: 5,
      backgroundColor: "#1c2140",
      textAlign: "center",
      color: "white",
      fontFamily: "Brandon",
    },
    sectionContent: {
      maxHeight: 350,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    birthdays: {
      fontSize: 16,
      fontFamily: "Brandon",
      color: "white",
    },
    birthdayList: {
      display: "flex",
      flexDirection: "column",
      // justifyContent: "center",
      alignItems: "center",
      padding: 5,
      backgroundColor: "#e76d90",
      width: "60%",
    },
    birthdayHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      width: "100%",
    },
    birthdayhat: {
      height: 60,
    },
    userBirthdays: {
      display : "flex",
      flexDirection: "column",
      // justifyContent: "",
      alignItems: "flex-start",
      marginTop: 5,
      marginBottom: 5,
      gap: 5,
      width: "90%",
    },
    userBirthday: {
      color: "white",
      fontSize: 14,
      fontFamily: "Brandon",
    },
    
    totalBirthdays: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "35%",
      backgroundColor: "#ec871c",
    },
    totalBirthdaysNumberContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: 15,
      width: "100%",
      padding: 10,
    },
    birthdayCake: {
      height: 75,
    },
    totalBirthdaysNumber: {
      color: "white",
      fontWeight: "bold",
      fontSize: 32,
      padding: 5,
    },
    totalBirthdaysText: {
      color: "white",
      fontSize: 12,
      padding: 5,
    },
    rowContent: {
      marginTop: 10,
      display: "flex",
      flexDirection: "row",
      width: "100%",
      maxHeight: 200,
      justifyContent: "space-between",
      gap: 20,
    },
    footerContainer: {
      width: "100%",
      height: "200px",
      backgroundColor: "#1c2140",
    },
    footerRowContainer: {
      display: "flex",
      flexDirection: "row",
      gap: 20,
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      padding: 10,
      paddingTop: 20,
    },
    logo: {
      height: 65
    },
    footerText: {
      color: "white",
      fontSize: 12,
      fontFamily: "Brandon"
    },

    pageCountContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      backgroundColor: "white",
      borderRadius: 25,
      width: 30,
      height: 30,
      bottom: 5,
      right: 5,
    },
    pageCountText: {
      color: "black",
      fontSize: 24,
      fontWeight: 600,
    },
  });

  return (
    <Page size="A4" orientation="portrait">
      <View style={pdfStyles.page}>
      <View style={pdfStyles.container}>
      <View style={pdfStyles.pageDecorator}></View>
        {/* ANNIVERSAIRES */}
        <View style={pdfStyles.sectionContainer}>
          <View style={pdfStyles.titleContainer}>
            <Text style={pdfStyles.sectionTitle}>
              ANNIVERSAIRES
            </Text>
          </View>

          <View style={pdfStyles.sectionContent}>
            <View style={pdfStyles.birthdayList}>
              <View style={pdfStyles.birthdayHeader}>
                <Image src={Birthdayhat} style={pdfStyles.birthdayhat}/>
                <Text style={pdfStyles.birthdays}>
                  Souhaitons un joyeux anniversaire
                </Text>
              </View>
              <View style={pdfStyles.userBirthdays}>
                {monthUsersBirthday().map((user) => (
                  <Text style={pdfStyles.userBirthday}>- {user?.surname ?? ""} {user?.name ?? ""}, le {moment(user?.birthdate.toDate()).format("DD/MM")} ({moment().diff(moment(user?.birthdate.toDate()), "years") + 1} ans)</Text>
                ))}
              </View>
            </View>
            <View style={pdfStyles.totalBirthdays}>
              <View style={pdfStyles.totalBirthdaysNumberContainer}>
                <Text style={pdfStyles.totalBirthdaysNumber}>
                  {countTotalAge()}
                </Text>
                <Image src={BirthdayCake} style={pdfStyles.birthdayCake}/>
              </View>

              <Text style={pdfStyles.totalBirthdaysText}>
                C'est le nombre de bougies que nous allons souffler en {moment(ctx.editionDate ?? new Date()).format("MMMM") } !
              </Text>
            </View>
          </View>
        </View>
        <View style={pdfStyles.rowContent}>
          <View style={{width: "60%"}}>
          {/* AGENDA */}
            {page["1"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["1"]).contentType
              )
                ? contentTypes
                  .find(
                    (type) => type.slug === JSON.parse(page["1"]).contentType
                  )
                  .pdf({settings: ctx.settings, ...JSON.parse(page["1"]) })
                : null}
          </View>
          <View style={{width: "35%"}}>
            {/* DICTON DU MOIS */}
            {page["2"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["2"]).contentType
              )
                ? contentTypes
                  .find(
                    (type) => type.slug === JSON.parse(page["2"]).contentType
                  )
                  .pdf({settings: ctx.settings, ...JSON.parse(page["2"]) })
                : null}
          </View>
        </View>
      </View>
        <View style={pdfStyles.footerContainer}> 
          <View style={pdfStyles.footerRowContainer}>
            <Image src={Logo} style={[pdfStyles.logo, {flex: 2}]}/>
            <Text style={[pdfStyles.footerText, {flex: 5}]}>
              La Gazette Heurus est une gazette interne destinée à nos résidents et à tous ceux qui s'intéressent à la vie dans nos résidences. Certains articles sont communs à toutes les résidences tandis que d'autres sont spécifiques à chacune des résidences. Le contenu est supervisé par les résidents volontaires, accompagnés par les animatrices Heurus.
            </Text>
          </View>
          <View style={{padding: 20}}>
            <Text style={[pdfStyles.footerText, {textAlign: "center"}]}>
              Ne pas jeter sur la voie publique - Tous droits réservés à Heurus

            </Text>
            <Text style={[pdfStyles.footerText, {textAlign: "center"}]}>
              Contact : contact@heurus.com - 02 49 09 23 50
            </Text>
          </View>
          
        </View>
      </View>
      <View style={pdfStyles.pageCountContainer}>
        <Text style={pdfStyles.pageCountText}>{index + 1}</Text>
      </View>
    </Page>
  );
};
LastPage.propTypes = {
  page : PropTypes.any,
  onEditPage : PropTypes.any,
  onRemove : PropTypes.any,
  onAddContent : PropTypes.any,
  onRemoveContent : PropTypes.any,
  onEditContent : PropTypes.any,
};
LastPageMiniature.propTypes = {
  onClick : PropTypes.any,
};
LastPagePdf.propTypes = {
  page : PropTypes.any,
  ctx : PropTypes.any,
  index : PropTypes.any,
  contentTypes: PropTypes.any,
};

export { LastPage, LastPageMiniature, LastPagePdf };