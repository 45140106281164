import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

/*
Les MIME types sont disponibles ici :
https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types

acceptedFileTypes={{
              'image/png': ['.png'],
              'text/html': ['.html', '.htm'],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
              'application/vnd.ms-excel': ['.xls']
            }}

*/
const FileDropZone = ({ acceptedFileTypes, maxFileSizeMB, multiple, onFilesAccepted }) => {
    const maxFileSize = maxFileSizeMB ? maxFileSizeMB * 1024 * 1024 : null;

    const onDrop = (acceptedFiles, rejectedFiles) => {
        const errors = [];

        rejectedFiles.forEach(({file}) => {
            console.log("cc", file);
            if (maxFileSize && file.size > maxFileSize) {
                errors.push(`${file.name} est trop lourd. Taille maximale : ${maxFileSizeMB} Mo`);
            } else {
                errors.push(`${file.name} ne fait pas parti des types de fichiers supportés.`);
            }
        });

        
        if (errors.length === 0) {
            onFilesAccepted(acceptedFiles);
        }else{
            errors.forEach(element => {
                toast.error(element);
            });
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: acceptedFileTypes,
        maxSize: maxFileSize,
        multiple: multiple,
    });

    return (
        <div>
            <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center', cursor: "pointer" }}>
                <input {...getInputProps()} />
                <p>Glisser déposer les fichiers ici, ou cliquez pour sélectionner les fichiers</p>
            </div>
        </div>
    );
};

FileDropZone.propTypes = {
    acceptedFileTypes: PropTypes.objectOf(
        PropTypes.arrayOf(PropTypes.string)
    ),
    // acceptedFileTypes: PropTypes.array,
    maxFileSizeMB: PropTypes.number,
    multiple: PropTypes.bool,
    onFilesAccepted: PropTypes.func.isRequired,
};

FileDropZone.defaultProps = {
    acceptedFileTypes: [],
    maxFileSizeMB: null,
    multiple: false,
};

export default FileDropZone;