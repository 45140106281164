import React from "react";
import { Button } from "lib/components";
import { Settings } from "react-feather";
import {useHistory} from "react-router-dom";
const EditorInterface = (props) => {
  const history = useHistory();
  
  return (
    <>
      <div style={{display: "flex", flexDirection: "column", width: "100%", gap: 10, alignItems: "flex-end", justifyContent: "center", marginTop: 30 }}>
          <Button style={{display: "flex", alignItems: "center", gap: 5}} onClick={() => history.push("/dashboard/menus/supplements")}>
              <Settings />
              Editer les suppléments
            </Button>          
      </div>
    </>
  )
};

export default EditorInterface;