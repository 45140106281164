import { useEffect, useState } from "react";
import { ChevronRight, ChevronLeft, User, Edit, Trash2 } from "react-feather";
import { Button, Row, Icon, Container, Card, Input, Select } from "lib/components";
import styles from "../Scss/tableau.module.scss";
import { useServiceReservation } from "../../routerContext";
import { CategoriesCrudService } from "../../routerComponents";
import { toast } from "react-toastify";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

import PropTypes from "prop-types";

const TableauResa = (props) => {
    const { onModifyOpen } = props;
    const [ctx, { initCurrentAvailibility, updateCurrentWeekStart, removeAvailabiliy, updatePage }] = useServiceReservation();
    const [currentWeekStart, setCurrentWeekStart] = useState(new Date());
    const [currentWeek, setCurrentWeek] = useState([]);
    const [thisMonday, setThisMonday] = useState(false);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(null);
    const [search, setSearch] = useState("");
    const [searchSenior, setSearchSenior] = useState("");
    const [daysToShow, setDaysToShow] = useState(7);
    const [eventsParsed, setEventsParsed] = useState([]);

    useEffect(() => {
        const res = [];
        for (let index = 0; index < 7; index++) {
            const today = new Date();
            const dayOfWeek = today.getDay();
            const mondayDate = new Date(today);
            mondayDate.setDate(today.getDate() - dayOfWeek + 1);
            const theweek = new Date(currentWeekStart);
            theweek.setDate(currentWeekStart.getDate() + index);
            res.push(theweek);
            setCurrentWeek(res);
            setThisMonday(mondayDate);
            setCurrentWeekStart(mondayDate);
            updateCurrentWeekStart(mondayDate);
        }
    }, []);
    useEffect(() => {
        setDaysToShow(7);
    }, [search, searchSenior, category]);

    useEffect(() => {
        const categories = ctx.availableServices;
        setCategories(categories);
    }, [category, ctx.availableServices]);

    useEffect(() => {
        const res = [];
        for (let index = 0; index < 7; index++) {
            const theweek = new Date(currentWeekStart); // Date à afficher pour cette rangée
            theweek.setDate(currentWeekStart.getDate() + index);
            res.push(theweek);
            setCurrentWeek(res);
        }
    }, [currentWeekStart]);

    useEffect(() => {
        if (!ctx.planning || !ctx.prestataires || !ctx.availableServices) {
            return;
        }
        let planning = ctx.planning;
        let data = planning.map((i) => {
            const prestataire = ctx.prestataires.find((item) => item.uid === i.prestataireId);
            if (!prestataire) {
                return null;
            }
            const categorieType = ColorCategorie(i);
            return {
                title: prestataire.name,
                start: i.start,
                end: i.end,
                dispoUid: i.uid,
                category: categorieType.name,
                reservation: i.reservations,
                categoryColor: categorieType.color,
                others: {
                    ...i,
                },
            };
        }
        ).filter((item) => { //filtre si on cherche un senior pr n'avoir que ceux auquel le senior participe
            if (!item) {
                return false;
            }

            if (item.reservation && searchSenior.trim() !== "") {
                const filteredReservations = item.reservation.filter((resa) => {
                    const user = getSenior(resa);

                    return user?.toLowerCase().includes(searchSenior.toLowerCase());
                });

                if (filteredReservations.length > 0) {
                    return true;
                }
            }
            return searchSenior.trim() === "";
        }).filter((item) => { // Filtrer en fonction des résultats des recherches par catégorie ou par prestataire

            return item !== null && (category ? item.category === category.label : true) && item.title.toLowerCase().includes(search.toLowerCase());
        });
        setEventsParsed(data);
        if (searchSenior != "" || search != "" || category != null) {
            const today = new Date();
            const dayOfWeek = today.getDay();
            const mondayDate = new Date(today);
            mondayDate.setDate(today.getDate() - dayOfWeek + 1);
            mondayDate.setHours(0, 0, 0, 0);
            const newData = data.filter((dispo) => {//on recup ceux supérieur au jour ou l'on est
                return new Date(dispo.start).getTime() >= mondayDate.getTime();
            });
            const sortedEvents = newData.sort((a, b) => {// Trie par ordre croissant des horaires
                const dateA = new Date(a.start);
                const dateB = new Date(b.start);
                return dateA.getTime() - dateB.getTime();
            });
            const uniqueDates = new Set(); // Ensemble pour stocker les dates uniques
            sortedEvents.forEach(item => {
                const currentDate = new Date(item.start); // Convertir la date de début en objet Date
                currentDate.setHours(0, 0, 0, 0);
                uniqueDates.add(currentDate.getTime()); // Ajouter la date à l'ensemble
            });
            // Convertir l'ensemble en tableau et trier les dates
            const sortedDates = Array.from(uniqueDates).sort((a, b) => a - b);
            const formattedDates = sortedDates.map(dateString => { return new Date(dateString); });
            setCurrentWeek(formattedDates);
        } else {
            const res = [];
            for (let index = 0; index < 7; index++) {
                const today = new Date();
                const dayOfWeek = today.getDay();
                const mondayDate = new Date(today);
                mondayDate.setDate(today.getDate() - dayOfWeek + 1);
                const theweek = new Date(currentWeekStart);
                theweek.setDate(currentWeekStart.getDate() + index);
                res.push(theweek);
                setCurrentWeek(res);
                setThisMonday(mondayDate);
                setCurrentWeekStart(mondayDate);
                updateCurrentWeekStart(mondayDate);
            }
        }
    }, [ctx.planning, ctx.prestataires, ctx.availableServices, category, search, searchSenior]);

    const ColorCategorie = (data) => {
        if (!ctx.prestataires || !ctx.availableServices) {
            return "";
        }
        const prestataireService = ctx.prestataires.find((item) => item.uid === data.prestataireId).serviceRef;
        const service = ctx.availableServices.find(item => item.name === prestataireService);
        return service ? { color: service.color, name: service.name } : "";
    };

    const goToNextWeek = () => {
        const nextWeek = new Date(currentWeekStart);
        nextWeek.setDate(nextWeek.getDate() + 7);
        setCurrentWeekStart(nextWeek);
        updateCurrentWeekStart(nextWeek);

    };
    const returnToThisWeek = () => {
        setCurrentWeekStart(thisMonday);
        updateCurrentWeekStart(thisMonday);
    };


    const filterPlanningByDate = (date) => {
        let filteredEvents;
        filteredEvents = eventsParsed.filter(item => {
            return new Date(item.start).toLocaleDateString("fr-FR") === date.toLocaleDateString("fr-FR");
        });


        const sortedEvents = filteredEvents.sort((a, b) => {
            const dateA = new Date(a.start);
            const dateB = new Date(b.start);
            return dateA.getTime() - dateB.getTime(); // Trie par ordre croissant des horaires
        });

        return sortedEvents;
    };

    const goToPreviousWeek = () => {
        const previousWeek = new Date(currentWeekStart);
        previousWeek.setDate(previousWeek.getDate() - 7);
        setCurrentWeekStart(previousWeek);
        updateCurrentWeekStart(previousWeek);
    };

    const _onDelete = async (item) => {
        try {
            if (!window.confirm("Etes-vous sur de vouloir supprimer cette disponibilité? Cela entrainera la suppression de toutes les réservations qui lui sont liées")) return;
            //notification
            // let users = await getUsersByEtab({ etabId: ui.user.establishment }, () => { throw new Error("Une erreur est survenue"); });
            // users = users.filter(u => u.role === "senior");
            // users = users.map(u => u.uid);
            // await ms("sendPushToUserById", {
            //     userId: users,
            //     title: `Suppression d'un créneau pour ${item.title}, Rendez-vous à l'accueil`,
            //     body:`de ${item.start.toLocaleTimeString("fr-FR",{hour:"numeric",minute:"numeric"})} à ${item.end.toLocaleTimeString("fr-FR",{hour:"numeric",minute:"numeric"})}.`,
            // type:"service",
            // etabId:ui.user.establishment,
            // });
            removeAvailabiliy(item.dispoUid);

        } catch (e) {

            toast.error("la suppression n'a pas abouti");
            console.error("Erreur lors de la suppression de la prestation:", e);
            throw e;
        }
    };
    const getSenior = (resa) => {
        for (const userId in ctx.users) {
            if (userId == resa.userId) {
                const user = ctx.users[userId];
                return user.surname + " " + user.name;
            }
        }
    };

    return (
        <Card className={`${styles.card} ${searchSenior != "" || search != "" || category != null ? styles.cardSearching : ""}`} >
            <Row style={{ justifyContent: "space-between", marginRight: "10px", marginBottom: "40px" }}>
                <div className={`${styles.rechercheContainer} ${searchSenior != "" || search != "" || category != null ? styles.isSearching : ""}`}>
                    <div className={styles.recherche}>
                        Recherche :
                        <Input
                            placeholder="Nom du prestataire..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <Select
                        placeholder="Catégorie..."
                        clearable
                        options={categories.map((category) => ({ label: category.name, value: category.uid }))}
                        value={category}
                        onChange={(e) => setCategory(e ?? null)}
                    />
                    <Input
                        placeholder="Nom du senior..."
                        value={searchSenior}
                        onChange={(e) => setSearchSenior(e.target.value)}
                    />
                    <div className={styles.iconPoubelle} onClick={() => { setSearch(""); setSearchSenior(""); setCategory(null); }}>
                        <Icon icon={Trash2} size={20} color="dark" />
                    </div>
                </div>
                {(searchSenior === "" && search === "" && category === null) ? <Row>
                    <Icon icon={ChevronLeft} size="34" className={styles.arrowDate}
                        onClick={() => { goToPreviousWeek(); }} />

                    <div className={styles.todayButton} onClick={returnToThisWeek}>{"Revenir à Aujourd'hui"}</div>
                    <Icon icon={ChevronRight} size="34" className={styles.arrowDate}
                        onClick={() => { goToNextWeek(); }} />

                </Row> : null}
            </Row>

            <div>
                {(searchSenior !== "" || search !== "" || category !== null) ? <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", color: "#DF7189" }}>
                    <h4>Résultat(s) de votre recherche:</h4>
                </div> : null}
                <Row className={styles.titreColonne} >
                    <div style={{ flex: 1 }}></div>
                    <div style={{ fontWeight: "bold", fontSize: "18px", flex: 2, marginRight: "15px" }}>Horaire</div>
                    <div style={{ display: "flex", fontWeight: "bold", fontSize: "18px", justifyContent: "flex-start", width: "fit-content" }}>Catégorie</div>
                    <div style={{ display: "flex", justifyContent: "center", fontWeight: "bold", fontSize: "18px", flex: 4, marginLeft: "15px" }}>Prestataires</div>
                    <div style={{ fontWeight: "bold", fontSize: "18px", flex: 3 }}>Nombre de participants</div>
                    <div style={{ flex: 1 }}></div>
                </Row>
                {currentWeek.map((day, index) => {
                    const today = new Date();
                    const dayOfWeekDisplay = day.toLocaleDateString("fr-FR", { weekday: "short" });
                    const formattedDate = day.toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit" });
                    const isToday = day.toDateString() === today.toDateString();
                    const planningForDay = filterPlanningByDate(day);
                    if ((searchSenior !== "" || search !== "" || category !== null) && index < daysToShow || (searchSenior === "" && search === "" && category === null)) {
                        return (
                            <Row key={index} className={styles.rowContainer} style={{ marginBottom: (searchSenior != "" || search != "" || category != null) ? "10px" : "20px" }}>
                                {(searchSenior == "" && search == "" && category == null) || ((searchSenior != "" || search != "" || category != null) && planningForDay.length != 0) ? <div className={styles.day} style={{ color: isToday ? "#DF7189" : "black" }}>{dayOfWeekDisplay} {formattedDate}
                                </div> : null}
                                <div className={styles.column}>
                                    {planningForDay.length == 0 && (searchSenior != "" || search != "" || category != null) ? (
                                        <div></div>
                                    )
                                        :
                                        planningForDay.length == 0 ? (
                                            <Container className={styles.dayContainer} >
                                                <div className={styles.noPrestataire} > pas de prestataires pour {day.toLocaleDateString("fr-FR", { weekday: "long" })}
                                                </div>
                                            </Container>
                                        )
                                            : (
                                                planningForDay.map((item, idx) => {
                                                    let totalResaSenior = [];
                                                    item.reservation.map((resa) => {
                                                        const senior = getSenior(resa);
                                                        const startResa = resa.start.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit", hour12: false });
                                                        const endResa = resa.end.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit", hour12: false });
                                                        totalResaSenior.push(startResa + "-" + endResa + "  " + senior);
                                                        totalResaSenior.sort((a, b) => {
                                                            const startTimeA = a.split("-")[0];
                                                            const startTimeB = b.split("-")[0];
                                                            const dateA = new Date(`2000-01-01T${startTimeA}`);
                                                            const dateB = new Date(`2000-01-01T${startTimeB}`);
                                                            return dateA - dateB;
                                                        });
                                                    });
                                                    const myTooltip = totalResaSenior ? (
                                                        <>
                                                            {totalResaSenior.map((s) => (
                                                                <>
                                                                    <span style={{ textAlign: "left", display: "block" }}>{s}</span>
                                                                </>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        null
                                                    );
                                                    return (
                                                        <Container key={idx} className={`${styles.dayContainer} ${styles.select} ${item.others.isComplet ? styles.complet : ""}`} onClick={() => { initCurrentAvailibility(item.dispoUid); updatePage(4); }}>
                                                            <>
                                                                {item.others.isComplet && <div className={styles.completBand}>COMPLET</div>}
                                                                <div className={styles.timing}>{item.start.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit", hour12: false })} - {item.end.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit", hour12: false })}</div>
                                                                {item.category ? <CategoriesCrudService category={item.category}></CategoriesCrudService> : <div style={{ marginLeft: "80px" }}></div>}
                                                                <div className={styles.titlePresta}>{item.title}</div>
                                                                <div style={{ display: "flex", flex: "1" }}>
                                                                    <Tooltip

                                                                        html={myTooltip}
                                                                        position="bottom-end"
                                                                        trigger="mouseenter"
                                                                        arrow={true}
                                                                        interactive={true}
                                                                        theme="light"
                                                                    >
                                                                        <Row className={styles.participantRow}>
                                                                            <User />
                                                                            <div >{item.reservation.length} {"Participants "}
                                                                                <span style={{ fontStyle: "italic" }}>{"(voir)"}</span>
                                                                            </div>

                                                                        </Row>
                                                                    </Tooltip></div>
                                                                <Row className={styles.participantRow} onClick={(e) => e.stopPropagation()}>
                                                                    <Edit color="blue" size="20px" style={{ marginLeft: "50px", marginRight: "15px" }}
                                                                        onClick={() => { onModifyOpen(item); }}
                                                                    />
                                                                    <Trash2 color="red" size="20px" style={{ marginRight: "15px" }}
                                                                        onClick={() => { _onDelete(item); }}
                                                                    />
                                                                </Row>
                                                            </>
                                                        </Container>);
                                                }))
                                    }
                                </div>

                            </Row>
                        );
                    }

                })}
                {(searchSenior !== "" || search !== "" || category !== null) && daysToShow < currentWeek.length ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                    <Button onClick={() => setDaysToShow(daysToShow + 7)}>Voir plus de résultats ...</Button>
                </div> : null}
            </div>
        </Card>
    );
};
TableauResa.propTypes = {
    onModifyOpen: PropTypes.func
};

export default TableauResa;

