import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, ImagePicker, Select} from "../../../../lib/components";
import {toast} from "react-toastify";
import firebase from "firebase";
import useGroupement from "hooks/useGroupement";

const firestore = firebase.firestore;

const baseSelect = [{value: "national", label: "National"}];

const CreateEditNews = (props) => {
  const {
    isOpen,
    toggle,
    item
  } = props;
  const {groupement, isRegions, establishments, getRegionsEstablishments} = useGroupement();
  const [title, setTitle] = useState("");
  const [region, setRegion] = useState("national");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState([]);
  const [galery, setGalery] = useState([]);
  const [imageLoaderForm, setImageLoaderForm] = useState(false);

  useEffect(() => {
    if (!item) {
      setTitle("");
      setDescription("");
      setRegion("national");
      setImage([]);
      setGalery([]);
    } else {
      setTitle(item.title);
      setDescription(item.description);
      setRegion(item.type ?? "national");
      setImage(item.image ? [item.image] : []);
      setGalery(item.galery ?? []);
    }
  }, [item, isOpen]);


  const onSubmit = async () => {
    try {
      if (!item) {
        await firestore().collection("groupements").doc(groupement.uid)
          .collection("actus").add({
            title,
            description,
            image: image.length ? image[0] : null,
            galery,
            date: new Date(),
            updatedAt: new Date(),
            type: region
          });
      } else {
        await firestore().collection("groupements").doc(groupement.uid)
          .collection("actus").doc(item.uid).update({
            title,
            description,
            image: image.length ? image[0] : null,
            galery,
            updatedAt: new Date(),
            type: region
          });
      }
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  const remove = async () => {
    try {
      await firestore().collection("groupements").doc(groupement.uid)
        .collection("actus").doc(item.uid).delete();
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  return (

    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader>{item ? item.title : "Nouvel article"}</ModalHeader>
      <ModalBody>
        <Input type="text" label="Titre" value={title} onChange={e => setTitle(e.target.value)} />
        {isRegions ? <>
          <Select label="Région" value={{value: region, label: region}} onChange={e => setRegion(e?.value ?? null)} options={baseSelect.concat(groupement?.regions?.map(r => ({value: r, label: r})) ?? [])} />
        </> : null}
        <Input type="textarea" label="Contenu" value={description} onChange={e => setDescription(e.target.value)} />
        <ImagePicker value={image} onChange={setImage} label="Image mise en avant" max={1} setImageLoaderForm={setImageLoaderForm}/>
        <ImagePicker value={galery} onChange={setGalery} label="Galerie" max={15} setImageLoaderForm={setImageLoaderForm}/>
      </ModalBody>
      <ModalFooter>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", gap: 10}}>
          {!item ? <>
            <Button color="secondary" onClick={toggle}>Retour</Button>
            <Button color="primary" onClick={onSubmit} disabled={imageLoaderForm}>Enregistrer</Button>
          </> : <>
            <Button color="secondary" onClick={toggle}>Retour</Button>
            <Button color="danger" onClick={remove}>Supprimer</Button>
            <Button color="primary" onClick={onSubmit} disabled={imageLoaderForm}>Mettre à jour</Button>
          </>
          }
        </div>
      </ModalFooter>
    </Modal>
  );
};

CreateEditNews.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default CreateEditNews;