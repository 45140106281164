import { Input, Select } from "lib/components";
import React from "react";
import PropTypes from "prop-types";
import {
  Font,
  Image,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import { FormGroup } from "reactstrap";
import moment from "moment";
import styles from "assets/scss/pages/Gazette/contentTypes/article.module.scss";
import useGazette from "../context";
import ImagePickerCrop from "lib/components/Form/ImagePickerCrop";

import "../fonts";

const Article = (props) => {
  const { format } = props;
  const maxImages = 
    format === "full" ? 4 : 
      format === "half" ? 2 : 
        format === "tiers" ? 1 : 0;

  return (
    <div className={`${styles.editorView} ${styles[format]}`}>
      <div className={styles.text}>
        <h3 className={styles.title}>{props.title}</h3>
        <p className={styles.description}>{props.description}</p>
      </div>
      {props.galery ?
        <div className={styles.galery}>
          {props.galery.slice(0, maxImages).filter(i => i).map((image, index) => (
            <img src={image} className={styles.image} key={index} />
          ))}
          {new Array(maxImages - (props.galery.slice(0, maxImages).filter(i => i).length)).fill(null).map((_, index) => (
            <div className={styles.image}  key={index}>
            </div>
          ))}
        </div>
        : null}
    </div>
  );
};

const ArticleEditor = (props) => {
  const { onSet, onChange, onSubmit, onCancel, field } = props;
  const [ctx] = useGazette();
  const maxImages =
    field.format === "full"
      ? 4
      : field.format === "half"
        ? 2
        : field.format === "tiers"
          ? 1
          : 0;
  const ratio = field.format === "full"
    ? 1
    : field.format === "half"
      ? 1
      : field.format === "tiers"
        ? 1
        : 1;

  const _onChangeArticle = (e) => {
    const _article = ctx.articles.find((article) => article.uid === e.value);
    let _galery = [];
    _article.image ? _galery.push(_article.image) : null ;

    if(_article.galery){
      _article.galery.forEach(_img => {
        if(_galery.length < maxImages)
          _galery.push(_img);
      });
    }

    onSet({
      ...field,
      articleId: e.value,
      description: ctx.articles.find((article) => article.uid === e.value)
        .description,
      title: ctx.articles.find((article) => article.uid === e.value).title,
      galery: _galery,
      date: ctx.articles.find((article) => article.uid === e.value).date,
    });
  };
  const _galeryValue = (id) => {
    if (!field.galery) return null;
    if (field.galery.length - 1 < id) return null;
    return field.galery[id];
  };
  const _setGaleryValue = (id, value) => {
    let galery = field.galery;

    if (!galery) galery = new Array(maxImages).fill(null);
    galery[id] = value;
    onChange("galery", galery);
  };

  const _setLegendValue = (id, value) => {
    let legends = field.legends;

    if (!legends) legends = new Array(maxImages).fill(null);
    legends[id] = value;
    onChange("legends", legends);
  };

  return (
    <>
      <FormGroup>
        <Select
          options={ctx.articles.map((i) => ({ label: i.title, value: i.uid }))}
          onChange={_onChangeArticle}
          label="Article"
          value={
            field.articleId &&
            ctx.articles.find((i) => i.uid === field.articleId)
              ? {
                value: ctx.articles.find((i) => i.uid === field.articleId).uid,
                label: ctx.articles.find((i) => i.uid === field.articleId).title,
              }
              : null
          }
        />
      </FormGroup>
      <FormGroup>
        <Input
          value={field.title}
          onChange={(e) => onChange("title", e.target.value)}
          placeholder="Titre..."
          label="Titre"
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="textarea"
          value={field.description}
          onChange={(e) => onChange("description", e.target.value)}
          placeholder="Contenu..."
          label="Contenu"
        />
      </FormGroup>
      <FormGroup style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 10}}>
        {new Array(maxImages).fill(null).map((_, index) => (
          <div key={index}>

            <ImagePickerCrop
              value={_galeryValue(index)}
              onChange={(value) => _setGaleryValue(index, value)}
              ratio={ratio}
            />
            <Input
              value={field.legend}
              onChange={(e) => _setLegendValue(index, e.target.value)}
              label="Légende"
            />
          </div>
          
        ))}
      </FormGroup>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
        }}
      >
        {/*<Button color="secondary" onClick={onCancel}>
          Annuler
        </Button>
        <Button color="primary" onClick={onSubmit}>
          Valider
      </Button>*/}
      </div>
    </>
  );
};

const ArticlePdf = (props) => {
  const { settings, format, title, description, galery, date, legends, ui } = props;

  let pdfStyle = StyleSheet.create({
    //-------------------------- full -------------------------------//
    fullContainer: {
      borderWidth: 3,
      borderColor: "lightgrey",
      height: "100%",
      padding: 10,
      borderRadius: 5,
      overflow: "hidden",
    },
    fullTextContainer: {
      height: "44%",
    },
    fullDate: {
      fontSize: settings?.fontSize3 ?? 15,
      fontFamily: settings?.fontFamily ?? "Roboto",
      color: "gray"
    },
    fullTitre: {
      // color: "#D26F47",
      // textTransform: "uppercase",
      marginTop: 5,
      fontFamily: settings?.fontFamily ?? "Roboto",
      fontWeight: "bold",
      fontSize: settings?.fontSize2 ?? 22,
    },
    fullContent: {
      marginTop: 15,
      textAlign: "justify",
      fontSize: settings?.fontSize3 ?? 15,
      fontFamily: settings?.fontFamily ?? "Roboto",
    },
    fullImageContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      height: "56%",
    },
    fullImage: {
      width: 175,
      height: legends && legends.some(_l => _l?.length > 0) ? 160 : 165,
      objectFit: "cover",
      margin: 5,
      borderRadius: 8,
    },
    //-------------------------- half -------------------------------//
    halfContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderWidth: 3,
      borderColor: "lightgrey",
      height: "100%",
      padding: 10,
      borderRadius: 5,
      overflow: "hidden",
    },
    halfTextContainer: {
      width: 420,
      marginRight: 20,
      justifyContent: "space-between",
    },
    halfDate: {
      fontSize: settings?.fontSize3 ?? 15,
      fontFamily: settings?.fontFamily ?? "Roboto",
      color: "gray"
    },
    halfTitre: {
      // color: "#D26F47",
      // textTransform: "uppercase",
      marginTop: 5,
      fontFamily: settings?.fontFamily ?? "Roboto",
      fontWeight: "bold",
      fontSize: settings?.fontSize2 ?? 22,
    },
    halfContent: {
      marginTop: 15,
      textAlign: "justify",
      fontSize: settings?.fontSize3 ?? 15,
      fontFamily: settings?.fontFamily ?? "Roboto",
    },
    halfImageContainer: {
      justifyContent: "center",
      alignItems: "center",
    },
    halfImage: {
      width: 140,
      height: 140,
      objectFit: "cover",
      margin: 5,
      borderRadius: 8,
    },
    //-------------------------- tiers -------------------------------//
    tiersContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderWidth: 3,
      borderColor: "lightgrey",
      height: "100%",
      padding: 10,
      borderRadius: 5,
      overflow: "hidden",
    },
    tiersTextContainer: {
      width: 420,
      marginRight: 20,
      justifyContent: "space-between",
    },
    tiersDate: {
      fontSize: settings?.fontSize3 ?? 15,
      fontFamily: settings?.fontFamily ?? "Roboto",
      color: "gray"
    },
    tiersTitre: {
      // color: "#D26F47",
      // textTransform: "uppercase",
      marginTop: 5,
      fontFamily: settings?.fontFamily ?? "Roboto",
      fontWeight: "bold",
      fontSize: settings?.fontSize2 ?? 22,
    },
    tiersContent: {
      marginTop: 15,
      textAlign: "justify",
      fontSize: settings?.fontSize3 ?? 15,
      fontFamily: settings?.fontFamily ?? "Roboto",
    },
    tiersImageContainer: {
      justifyContent: "center",
      alignItems: "center",
    },
    tiersImage: {
      width: 150,
      height: 150,
      objectFit: "cover",
      margin: 5,
      borderRadius: 8,
    },



    imageLegend: {
      fontSize: settings?.fontSize4 ?? 12,
      fontFamily: settings?.fontFamily ?? "Roboto",
      textAlign: "center",
      textDecoration: "underline",
      marginLeft: 2,
      marginRight: 2
    },
    imageContainer: {
      justifyContent: "center",
      alignItems: "center",
    }
  });

  if(ui?.groupement?.specific === "heurus") {
    pdfStyle = {
      ...pdfStyle,
      fullTitre: {
        ...pdfStyle.fullTitre,
        fontFamily: "Brandon",
        color: "#1c2140",
      },
      fullContent: {
        ...pdfStyle.fullContent,
        fontFamily: "Brandon",
        color: "#1c2140",
      },
      halfTitre: {
        ...pdfStyle.halfTitre,
        fontFamily: "Brandon",
        color: "#1c2140",
      },
      halfContent: {
        ...pdfStyle.halfContent,
        fontFamily: "Brandon",
        color: "#1c2140",
      },
      tiersTitre: {
        ...pdfStyle.tiersTitre,
        fontFamily: "Brandon",
        color: "#1c2140",
      },
      tiersContent: {
        ...pdfStyle.tiersContent,
        fontFamily: "Brandon",
        color: "#1c2140",
      },
      
      fullContainer: {
        ...pdfStyle.fullContainer,
        borderColor: "#ec871c"
      },
      halfContainer: {
        ...pdfStyle.halfContainer,
        borderColor: "#ec871c"
      },
      tiersContainer: {
        ...pdfStyle.tiersContainer,
        borderColor: "#ec871c"
      },
      imageLegend: {
        fontFamily: "Brandon",
        color: "#1c2140",
      }
    };
  }

  if (format === "full")
    return (
      <View style={pdfStyle.fullContainer}>
        <View style={pdfStyle.fullTextContainer}>
          <Text style={pdfStyle.fullTitre}>{title}</Text>
          <Text style={pdfStyle.fullContent}>{description}</Text>
          
        </View>
        <View style={pdfStyle.fullImageContainer}>
          {galery ? galery.filter(i => i).map((image, index) => (
            <View key={image} style={pdfStyle.imageContainer}>
              <Image key={image} src={image} style={pdfStyle.fullImage} />
              {legends ? legends[index] && legends[index].length > 0 ? <Text style={pdfStyle.imageLegend}>{legends[index]}</Text> : null : null}
            </View>
          )) : null}
        </View>
        {/* <Text style={pdfStyle.fullDate}>
            {date ? "créé le " + moment(date).format("DD/MM/YYYY") : null}
        </Text> */}
      </View>
    );
  if (format === "half")
    return (
      <View style={pdfStyle.halfContainer}>
        <View style={pdfStyle.halfTextContainer}>
          <View>
            <Text style={pdfStyle.halfTitre}>{title}</Text>
            <Text style={pdfStyle.halfContent}>{description}</Text>
          </View>
          {/* <Text style={pdfStyle.halfDate}>
            {date ? "créé le " + moment(date).format("DD/MM/YYYY") : null}
          </Text> */}
        </View>
        <View style={pdfStyle.halfImageContainer}>
          {galery ? galery.filter(i => i).map((image, index) => (
            <View key={image} style={pdfStyle.imageContainer}>
              <Image key={image} src={image} style={pdfStyle.halfImage} />
              {legends ? legends[index] && legends[index].length > 0 ? <Text style={pdfStyle.imageLegend}>{legends[index]}</Text> : null : null}
            </View>
          )) : null}
        </View>
      </View>
    );
  if (format === "tiers")
    return (
      <View style={pdfStyle.tiersContainer}>
        <View style={pdfStyle.tiersTextContainer}>
          <View>
            <Text style={pdfStyle.tiersTitre}>{title}</Text>
            <Text style={pdfStyle.tiersContent}>{description}</Text>
          </View>
          {/* <Text style={pdfStyle.tiersDate}>
            {date ? "créé le " + moment(date).format("DD/MM/YYYY") : null}
          </Text> */}
        </View>
        <View style={pdfStyle.tiersImageContainer}>
          {galery ? galery.filter(i => i).map((image, index) => (
            <View key={image} style={pdfStyle.imageContainer}>
              <Image src={image} style={pdfStyle.tiersImage} />
              {legends ? legends[index] && legends[index].length > 0 ? <Text style={pdfStyle.imageLegend}>{legends[index]}</Text> : null : null}
            </View>
          )) : null}
        </View>
      </View>
    );
  return <></>;
};



//TODO Definir les proptypes 
Article.propTypes = {
  format : PropTypes.any,
  title : PropTypes.any,
  description : PropTypes.any,
  galery : PropTypes.any,
};

ArticleEditor.propTypes = {
  onSet : PropTypes.any,
  onChange : PropTypes.any,
  onSubmit : PropTypes.any,
  onCancel : PropTypes.any,
  field : PropTypes.any,
};

ArticlePdf.propTypes = {
  settings : PropTypes.any,
  format : PropTypes.any,
  title : PropTypes.any,
  description : PropTypes.any,
  galery : PropTypes.any,
  date : PropTypes.any,
  legends : PropTypes.any,
  ui: PropTypes.any,
};
export { Article, ArticleEditor, ArticlePdf };
