import  { gunzip } from "react-zlib-js";
import { Buffer } from 'buffer';

const decompressString = async (compressedBuffer) => {
  return new Promise((resolve, reject) => {
    const buffer = Buffer.from(compressedBuffer);
    gunzip(buffer, (err, decompressedBuffer) => {
      if (err) {
        reject(err);
      } else {
        const decoder = new TextDecoder();
        const decompressedString = decoder.decode(decompressedBuffer);
        resolve(decompressedString);
      }
    });
  });
};
const unConcat = (str) => {
  const path = str.split("|")[0].split("&").map(e => e.split("/")).map(e => e.map(e => e.length > 0 ? e.split(",") : []));
    const strokes = str.split("|")[1].split("_");
    return  [path, strokes];
}

const generateSVGFromPaths = (paths) => {
  return `<svg xmlns="http://www.w3.org/2000/svg">${paths.map(path => `<path d="${path[1]}" style="stroke:#000;stroke-width:${path[0]};fill:none;stroke-linecap:round"/>`)}</svg>`;
};

const generatePathsFromSiplifiedSVGPaths = (simplifiedSVGPaths) => {
  return simplifiedSVGPaths[0].map((path, pathIndex) => 
    [simplifiedSVGPaths[1][pathIndex],`M ${path[0].length > 0 ? path[0].join(",") : simplifiedSVGPaths[0][pathIndex - 1][3].join(",")} C${path.slice(1).join(" ")}`])
};

export { decompressString, unConcat, generateSVGFromPaths, generatePathsFromSiplifiedSVGPaths }