import useUI from "@/hooks/ui.hook";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Input } from "@/lib/components";
import TreeSelect from "@/lib/components/Form/TreeSelect";
import { updateGroupementSupplementsCategories } from "@/services/ressources/supplement_restaurant";
import "rc-tree/assets/index.css"
import React, { useState, useEffect, useCallback } from "react";
import { Plus, Trash } from "react-feather";



const CategorySupplementModalEditor = (props) => {
  const {
    isModalOpen,
    onClose,
    supplementsCategories = [],
  } = props;
  const [ui] = useUI();

  const [selectedNode, setSelectedNode] = useState(null);
  const [localTreeData, setLocalTreeData] = useState([]);
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [newTitle, setNewTitle] = useState("");


  useEffect(() => {
    setLocalTreeData(deepSortTree(supplementsCategories));
    setSelectedNode(null);
  }, [supplementsCategories, isModalOpen])

  const deepSortTree = (treeData) => {
    treeData.sort((a, b) => a.title.localeCompare(b.title));
    treeData.forEach((node) => {
      if (node.children) {
        deepSortTree(node.children);
      }
    });
    return treeData;
  };

  const deleteKeys = (treeData) => {
    return treeData.map((node) => {
      if (node.children) {
        node.children = deleteKeys(node.children);
      }

      delete node.key;
      return node;
    })
  }

  const onSubmit = () => {
    (async () => {
      await updateGroupementSupplementsCategories({ groupementId: ui.user.groupement, supplementsCategories: deleteKeys(localTreeData) }, () => { }, (e) => console.log(e));
    })();
    setSelectedNode(null);
    onClose();
  };

  const _onSelect = (selectedKeys, e) => {
    if (selectedNode && selectedNode.pos == e.node.pos) {
      setSelectedNode(null)
    } else {
      setSelectedNode(e.node);
    }

  };

  const _addTree = (parentNode, value) => {
    if (!parentNode) {
      setLocalTreeData([
        ...localTreeData,
        { key: `0-${localTreeData.length}`, title: value ?? "", children: [] },
      ]);
      return;
    }

    const newNode = {
      key: `${parentNode.key}-${parentNode.children.length}`,
      title: value ?? "",
      children: [],
    };

    const addNode = (nodes, key) => {
      return nodes.map(node => {
        if (node.key === key) {
          return {
            ...node,
            children: [...node.children, newNode],
          };
        }
        return {
          ...node,
          children: node.children ? addNode(node.children, key) : [],
        };
      })
    }

    const newLocalTree = addNode(localTreeData, parentNode.key);
    setLocalTreeData(newLocalTree);
    setSelectedNode({ ...selectedNode, children: selectedNode.children.concat(newNode) });

  };



  const _removeTree = (nodeToRemove) => {
    const removeNode = (nodes, key) => {
      if (nodes.find(node => node.key === key)) {

        return nodes.filter(node => node.key !== key).map((node, index) => ({
          ...node,
          key: `${key.substring(0, key.length - 1)}${index}`,
          children: node.children ? removeNode(node.children, key) : [],
        }));
      }
      return nodes
        .filter(node => node.key !== key)
        .map(node => ({
          ...node,
          children: node.children ? removeNode(node.children, key) : [],
        }));
    };

    const newLocalTree = removeNode(localTreeData, nodeToRemove.key);
    setLocalTreeData(newLocalTree);
    setSelectedNode({
      ...selectedNode, children: selectedNode ?
        selectedNode.children
          .filter(node => node.key !== nodeToRemove.key)
          .map((node, index) => ({ ...node, key: `${node.key.substring(0, node.key.length - 1)}${index}` })) :
        localTreeData
          .filter(node => node.key !== nodeToRemove.key)
          .map((node, index) => ({ ...node, key: `${node.key.substring(0, node.key.length - 1)}${index}` }))
    });
  };

  const _updateTree = (node, newValue) => {
    if (!selectedNode) {
      setLocalTreeData([...localTreeData.filter(_node => _node.pos != node.pos), { ...node, title: newValue }]);
    } else {

      const indices = node.pos.split('-').splice(1).map(index => parseInt(index, 10));
      const updatedTreeData = [...localTreeData];

      let currentNode = updatedTreeData;

      indices.forEach((index, i) => {
        if (i === indices.length - 1) {
          currentNode[index] = {
            ...currentNode[index],
            title: newValue,
          };
        } else {
          currentNode = currentNode[index].children;
        }
      });

      setLocalTreeData(updatedTreeData);
      setSelectedNode({ ...selectedNode, title: newValue });
      // setSelectedNode({ ...selectedNode, children: selectedNode.children.map(_node => _node.key === node.key ? { ..._node, title: newValue } : _node) });
    }
  };

  const _renderSubTree = useCallback((subTree) => {

    return subTree.map((node, index) => (
      <div key={`${node.key}-${index}`} style={{ display: "flex", flexDirection: "row", gap: 10, alignItems: "center", justifyContent: "flex-stard", padding: 5 }}>
        <Input disabled={node.isFromGroupement ?? false} value={node.title} onChange={e => _updateTree(node, e.target.value)} />
        {!node.isFromGroupement && <Trash color="red" onClick={() => _removeTree(node)} style={{ cursor: "pointer" }} />}
      </div>
    ))
  }, [localTreeData, selectedNode]);

  const onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          callback(item, index, arr);
          return;
        }
        if (item.children) {
          loop(item.children, key, callback);
        }
      });
    };
    const data = [...localTreeData];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (dropPosition === 0) {
      // Drop on the content
      loop(data, dropKey, item => {
        // eslint-disable-next-line no-param-reassign
        item.children = item.children || [];
        // where to insert 示例添加到尾部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else {
      // Drop on the gap (insert before or insert after)
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setLocalTreeData(data);
  };

  // console.log(supplementsCategories);
  return (
    <>

      <Modal isOpen={isModalOpen} toggle={() => { setSelectedNode(null); onClose(); }} size="xl">
        <ModalHeader>
          <h4>Edition des catégories</h4>
        </ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", gap: 10, width: "100%", justifyContent: "space-between", height: 300 }}>
            <div style={{ flex: 1, backgroundColor: "#f5f5f5", height: "100%", overflow: "auto", padding: 5 }}>
              <TreeSelect
                treeData={localTreeData}
                onSelect={_onSelect}
                draggable={true}
                onDrop={onDrop}
              />

            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1, maxHeight: 300 }}>
              <h4>Selection: {selectedNode?.title ?? "Aucune Séléction"}</h4>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", overflow: "auto", gap: 10 }}>
                {selectedNode?.title && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 10 }}>
                  <Input disabled={selectedNode?.isFromGroupement ?? false} value={selectedNode?.title} onChange={e => _updateTree(selectedNode, e.target.value)} />
                  {!selectedNode?.isFromGroupement && <Trash color="red" onClick={() => { _removeTree(selectedNode); setSelectedNode(null); }} style={{ cursor: "pointer" }} />}
                </div>}

                {/* <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                {_renderSubTree(selectedNode?.children ?? localTreeData)}
              </div> */}

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 10 }}>
                  <Button onClick={() => setIsModalAddOpen(true)}><Plus style={{ marginRight: 4 }} /> Ajouter une catégorie {selectedNode?.title ? `dans : ${selectedNode?.title}` : ""}</Button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>Annuler</Button>
          <Button onClick={() => onSubmit()}>Enregistrer</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isModalAddOpen} toggle={() => { setNewTitle(""); setIsModalAddOpen(false); }}>
        <ModalHeader>
          <h6>{`Ajout d'une catégorie ${selectedNode?.title ? `dans : ${selectedNode?.title}` : ""}`}</h6>
        </ModalHeader>
        <ModalBody>
          <Input value={newTitle} onChange={e => setNewTitle(e.target.value)} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => { setNewTitle(""); setIsModalAddOpen(false); }}>Annuler</Button>
          <Button onClick={() => { _addTree(selectedNode, newTitle); setNewTitle(""); setIsModalAddOpen(false); }}>Valider</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CategorySupplementModalEditor; 