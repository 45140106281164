import { Edit3, Plus, Trash } from "react-feather";
import PropTypes from "prop-types";
import {
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";

import React from "react";
import contentTypes from "../contentTypes";
import styles from "assets/scss/pages/Gazette/pageTypes/summary.module.scss";
import useGazette from "../context";
import useUI from "hooks/ui.hook";

import Roboto from "../../../../fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "../../../../fonts/roboto/Roboto-BoldCondensed.ttf";

Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});

const SummaryMiniature = (props) => {
  const { onClick } = props;

  return (
    <div onClick={onClick} className={styles.miniature}>
      Page de type &quot;Sommaire&quot;
    </div>
  );
};

const Summary = (props) => {
  const {
    page,
    onEditPage,
    onRemove,
    onAddContent,
    onRemoveContent,
    onEditContent,
  } = props;

  const [ctx] = useGazette();
  const [ui] = useUI();

  return (
    <>
      <div className={styles.head}></div>
      <div className={styles.page}>
        <div className={styles.pageHeader} style={ctx.color ? {backgroundColor: ctx.color} : null}>
          <div className={styles.left}>
            <div style={{ flex: 1 }}></div>
            <span className={styles.title}>{ctx.title}</span>
            <div className={styles.dateContainer}>
              <span className={styles.date}>
                {ctx.editionDate ? moment(ctx.editionDate).format("MMMM YYYY") : moment().format("MMMM YYYY")}
              </span>
            </div>
          </div>
          <div className={styles.right}>
            {ui.establishment.profileImage &&(<img src={ui.establishment.profileImage} />)}
          </div>
        </div>
        <div className={styles.body}>
          {page["1"] &&
          contentTypes.find(
            (type) => type.slug === JSON.parse(page["1"]).contentType
          ) ? (
              <div className={`${styles.editoField}`}>
                <Trash
                  color="red"
                  size={40}
                  className={styles.trash}
                  onClick={() => onRemoveContent("1")}
                />
                <Edit3
                  color="blue"
                  size={40}
                  className={styles.edit}
                  onClick={() => onEditContent({ format: "tiers", key: "1" })}
                />
                {contentTypes
                  .find((type) => type.slug === JSON.parse(page["1"]).contentType)
                  .component({ ...JSON.parse(page["1"]) })}
              </div>
            ) : (
              <div
                className={`${styles.editoField} ${styles.add}`}
                onClick={() => onAddContent({ format: "tiers", key: "1" })}
              >
                <Plus size={120} />
              </div>
            )}
          <div className={styles.summary}>
            <span className={styles.summaryTitle} style={ctx.color ? {color: ctx.color} : null}>Sommaire</span>
            <div className={styles.pageListContainer}>
              {ctx.pages
                .filter((page) => page.title)
                .map((page, index) => (
                  <div key={page.uid} className={styles.pageListItem}>
                    <span className={styles.pageTitle}>{page.title}</span>
                    <span className={styles.pageDots}>
                      {new Array(500).fill(".").join("")}
                    </span>
                    <span className={styles.pageNumber}>{index + 1}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <Trash
          color="red"
          size={40}
          className={styles.bottomTrashIcon}
          onClick={onRemove}
        />
      </div>
    </>
  );
};

const SummaryPdf = (props) => {
  const { page, ctx, index, ui } = props;

  const pdfStyle = StyleSheet.create({
    container: {},
    container2: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 20,
      paddingBottom: 20
    },
    header: {
      height: 160,
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: 0,
      marginTop: 0,
      marginBottom: 10,
      backgroundColor: ctx.color ?? "#D26F47",
    },
    headerLeft: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 7,
    },
    headerTitle: {
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: 32,
      color: "white",
      top: -15,
    },
    headerDate: {
      color: "white",
      fontFamily: "Roboto",
    },
    headerRight: {
      width: 180,
      height: 160,
      backgroundColor: "white",
      borderTopLeftRadius: 400,
      borderBottomLeftRadius: 400,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    headerImage: {
      objectFit: "contain",
      width: 150,
      height: 100,
    },
    field: {
      height: 240,
      width: "100%",
      marginBottom: 7,
      overflow: "hidden",
      padding: 15,
    },
    summary: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: 20,
      paddingRight: 20,
    },
    summaryTitle: {
      color: ctx.color ?? "#D26F47",
      fontSize: 22,
      fontFamily: "Roboto",
    },
    pageListItem: {
      display: "flex",
      flexDirection: "row",
      marginTop: 5,
    },
    pageTitle: {
      textTransform: "uppercase",
    },
    pageDots: {
      flex: 1,
      overflow: "hidden",
      marginLeft: 5,
      marginRight: 5,
    },
    pageNumber: {
      color: ctx.color ?? "#D26F47",
    },
  });

  return (
    <>
      <Page size="A4" orientation="portrait" key={index}>
        <View style={pdfStyle.container}>
          <View style={pdfStyle.header}>
            <View style={pdfStyle.headerLeft}>
              <Text> </Text>
              <Text style={pdfStyle.headerTitle}>{ctx.title}</Text>
              <Text style={pdfStyle.headerDate}>
                {ctx.editionDate ? moment(ctx.editionDate).format("MMMM YYYY").toLocaleUpperCase() : moment().format("MMMM YYYY").toLocaleUpperCase()}
              </Text>
            </View>
            <View style={pdfStyle.headerRight}>
            {ui.establishment.profileImage &&(<Image
                src={ui.establishment.profileImage}
                style={pdfStyle.headerImage}
              />)}
            </View>
          </View>
          {page["1"] &&
          contentTypes.find(
            (type) => type.slug === JSON.parse(page["1"]).contentType
          ) ? (
              <View style={pdfStyle.field}>
                {contentTypes
                  .find((type) => type.slug === JSON.parse(page["1"]).contentType)
                  .pdf({ ...JSON.parse(page["1"]) })}
              </View>
            ) : null}
          <View style={pdfStyle.summary}>
            <Text style={pdfStyle.summaryTitle}>Sommaire</Text>
            {ctx.pages
              .filter((page) => page.title)
              .slice(0, page["1"] &&  contentTypes.find((type) => type.slug === JSON.parse(page["1"]).contentType) ? 15 : 25)
              .map((page, index) => (
                <View key={page.uid} style={pdfStyle.pageListItem}>
                  <Text style={pdfStyle.pageTitle}>{page.title}</Text>
                  <Text style={pdfStyle.pageDots}>
                    {new Array(500).fill(".").join("")}
                  </Text>
                  <Text style={pdfStyle.pageNumber}>{parseInt(page.uid) + 1}</Text>
                </View>
              ))}
          </View>
        </View>
      </Page>
      {ctx.pages.filter(p => p.title).length > (page["1"] &&  contentTypes.find((type) => type.slug === JSON.parse(page["1"]).contentType) ? 15 : 25) ? 
        <Page size="A4" orientation="portrait">
          <View style={pdfStyle.container2}>
            <View style={pdfStyle.summary}>
              <Text style={pdfStyle.summaryTitle}>Sommaire</Text>
              {ctx.pages
                .filter((page) => page.title)
                .slice(page["1"] &&  contentTypes.find((type) => type.slug === JSON.parse(page["1"]).contentType) ? 15 : 25, 99999)
                .map((page, index) => (
                  <View key={page.uid} style={pdfStyle.pageListItem}>
                    <Text style={pdfStyle.pageTitle}>{page.title}</Text>
                    <Text style={pdfStyle.pageDots}>
                      {new Array(500).fill(".").join("")}
                    </Text>
                    <Text style={pdfStyle.pageNumber}>{parseInt(page.uid) + 1}</Text>
                  </View>
                ))}
            </View>
          </View>
        </Page>
        : null}
    </>
  );
};



//TODO Definir les proptypes 

Summary.propTypes = {
  page : PropTypes.any,
  onEditPage : PropTypes.any,
  onRemove : PropTypes.any,
  onAddContent : PropTypes.any,
  onRemoveContent : PropTypes.any,
  onEditContent : PropTypes.any,
};

SummaryMiniature.propTypes = {
  onClick : PropTypes.any,
};

SummaryPdf.propTypes = {
  page : PropTypes.any,
  ctx : PropTypes.any,
  index : PropTypes.any,
  ui : PropTypes.any,
};

export { Summary, SummaryMiniature, SummaryPdf };
