import moment from "moment";

export const findSubscriptionForDate = (array, date) => {
    if (!array || !date) return null;

    let foundSubscription = null;
    const wantedDate = moment(date);
    array.forEach(element => {
      if (wantedDate.isBetween(moment(element.start), moment(element.end), "day", "[]")) {
        foundSubscription = element;
      }
    });
    return foundSubscription;
  };