import React, {useState, useEffect} from "react";
import useCtx from "./ctx";
import {Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Separator} from "lib/components";
import {BookOpen, Edit, Plus, Trash} from "react-feather";

// import styles from "./Repas.module.scss";

const SubscriptionComponent = () => {
  const [ctx, dispatch] = useCtx();

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
      setTitle("");
  }, [isOpen]);

  const _handleSubmit = () => {
    if(title !== ""){
      let _templateSubscription = {...ctx.templateSubscription2};

      // if (itemOpen) {
      //   _templateSubscription[title] = {
      //     ..._templateSubscription[itemOpen.title],
      //   };
      //   if (itemOpen.title !== title)
      //     delete _templateSubscription[itemOpen.title];
      // } 
  
      _templateSubscription[title] = {
        repas: {},
      };
  
      dispatch({type: "setValue", key: "templateSubscription2", value: {..._templateSubscription}});
      setIsOpen(false);
    }
  };
  const _handleDelete = (key) => {
    let _templateSubscription = {...ctx.templateSubscription2};
    delete _templateSubscription[key];
    dispatch({type: "setValue", key: "templateSubscription2", value: {..._templateSubscription}});
    setIsOpen(false);
  };

  const importV1 = () => {
     const _templateSub = {...ctx.templateSubscription};
     Object.entries(_templateSub).forEach(([_subName, value]) => {
        const _repasList = value?.repas;
        Object.keys(_repasList).forEach((_repas) => {
          _templateSub[_subName].repas[_repas] = {formules: []};
        });
     });

    dispatch({type: "setValue", key: "templateSubscription2", value: _templateSub});
  };

  return (
    <div>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <h3>Abonnement</h3>
        <Button onClick={importV1}> Importer les abonnements V1 </Button>
        <Button onClick={() => {setIsOpen(true);}}><Plus /></Button>
      </div>
      <Table>
        <thead>
          <tr>
            <th>Titre</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(ctx.templateSubscription2).map(key => (
            <tr key={key}>
              <td>{key}</td>
              <td style={{display: "flex", gap: 10}}>
                <Edit color="#4a0956" style={{cursor: "pointer"}} onClick={() => dispatch({type: "setState", state: {...ctx, step: 1, subscription: key}})} />
                <Trash color="#e16f7e" style={{cursor: "pointer"}} onClick={() => _handleDelete(key)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal isOpen={isOpen} toggle={() => {setIsOpen(false);}} size="large">
        <ModalHeader>
          <h3 style={{marginBottom: 0}}>Ajouter un abonnement</h3>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input label="Titre" value={title} onChange={e => setTitle(e.target.value)} />
          </FormGroup>
        </ModalBody>
        <ModalFooter style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Button color="secondary" onClick={() => {setIsOpen(false);}}>Annuler</Button>
          <Button color="primary" onClick={_handleSubmit} style={{marginRight: 0}}>Valider</Button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

const Page = () => (
  <div>
    <SubscriptionComponent />
  </div>
);

export default Page;