import {useState, useEffect} from "react";
import { Select } from "@/lib/components";
import {DatePicker} from "@/lib/components";

import PropTypes from "prop-types";

import { getAllEtablishments } from "@/services/ressources/establishment";
import { getAllUsers } from "@/services/ressources/user";


export default function Filter({onChange, value}) {
  const [establishments, setEstablishments] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllEtablishments().then(e => setEstablishments(e.map (e => ({value: e.uid, label: e.name}))));
    getAllUsers().then(e => setUsers(e.map (u => ({value: u.uid, label: `${u.surname} ${u.name}`}))));
  }, []);

  return (
    <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", gap: 10}}>
      <Select
        label="Etablissement"
        value={establishments.find(e => e.value === value?.establishment)}
        onChange={e => onChange({...value, establishment: e ? e.value : null})}
        options={establishments}
        clearable
      />
      <Select
        label="Utilisateur"
        value={users.find(u => u.value === value?.user)}
        onChange={e => onChange({...value, user: e ? e.value : null})}
        options={users}
        clearable
      />
      <DatePicker
        label="Date de début"
        value={value.start}
        onChange={e => onChange({...value, start: e})}
        theme="light"
        style={{width: 200}}
        placeholder="Date de début"
      />
      <DatePicker
        label="Date de fin"
        value={value.end}
        onChange={e => onChange({...value, end: e})}
        theme="light"
        style={{width: 200}}
        placeholder="Date de fin"
      />
    </div>
  )
}

Filter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};