import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useHistory } from "react-router-dom";

import styles from "../../../../assets/scss/pages/news.module.scss";
import { Button } from "../../../../lib/components";
import { AlertCircle } from "react-feather";


const NewsCard = (props) => {
  const {
    item,
    onClick
  } = props;
  const history = useHistory();

  return (
    <div className={`${styles.newsCard} ${item.isPublished === false ? styles.draftNews : ""}`}>
      <div className={styles.cardHeader}>
        <span className={styles.title} style={{ opacity: item.isPublished === false ? 0.7 : 1 }}>{item.title}</span>
        <div className={styles.tag} style={{ opacity: item.isPublished === false ? 0.7 : 1, backgroundColor: item.type === "national" ? "#E49F9F" : item.type != undefined ? "#F9DEC9" : "#99B2DD" }}>
          {item.type === "national" ? "Actualité Nationale" : item.type != undefined ? "Actualité Régionale" : "Actualité Locale"}</div>
      </div>
      <div className={styles.imageContainer}>
        {item.image ?
          <img className={styles.image} src={item.image} />
          :
          <span className={styles.description}>{item.description}</span>}
      </div>


      <div className={styles.controlsContainer}>
      {item.isProgrammed?
      <div style={{cursor:"pointer"}} onClick={() => history.push("/dashboard/waitingList")}>
         <AlertCircle color="gray" size={20} />
        <span className={styles.date}>Actualité programmée</span>
      </div>
      
      : item.isPublished === false?
      <div className={styles.date}>Conservé en brouillon</div>
      :
      <span className={styles.date}>{moment(item.date.toDate()).format("L")}</span>}
       
        <Button onClick={() => onClick(item)}>{item.type != undefined ? "Voir l'actualité" : "Editer"}</Button>
      </div>
    </div>
  );
};

NewsCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default NewsCard;