import { Table } from "lib/components";
import React, {useState, useEffect, useMemo} from "react";
import { Plus, Trash, Edit, ArrowLeft, ArrowRight, ArrowLeftCircle, Menu } from "react-feather";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Select, Tab} from "lib/components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useCtx from "./ctx";
import deepClone from "helpers/deepClone";

const typeOptions = [
  {value: "daily", label: "Journalier"},
  {value: "weekly", label: "Hebdomadaire"}
];

const Repas = () => {
  const [ctx, dispatch] = useCtx();
  const [repas, setRepas] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [itemOpen, setItemOpen] = useState(null);

  const [title, setTitle] = useState("");
  const [type, setType] = useState("daily");
  const [categories, setCategories] = useState([]);

  const [categoryTitle, setCategoryTitle] = useState("");
  
  useEffect(() => {
    if (itemOpen) {
      setTitle(itemOpen.title);
      setType(itemOpen.type);
      setCategories(itemOpen.categories);
    } else {
      setTitle("");
      setType("daily");
      setCategories([]);
    }
  }, [isOpen, itemOpen]);

  useEffect(() => {
    if (!ctx.repas) return setRepas(null);
    setRepas({...ctx.template[ctx.repas]});
  }, [ctx]);

  const _recomputeOrder = (menus) => {
    if (!menus || Object.keys(menus).length <= 1) return menus;
    let _menus = Object.keys(menus).map((title) => ({...menus[title], title}));
    let _newMenus = {};

    _menus.sort((a, b) => a.weight - b.weight);
    _menus.forEach((menu, i) => menu.weight = i);
    _menus.forEach((menu) => _newMenus[menu.title] = menu);
    Object.keys(_newMenus).forEach((title) => delete _newMenus[title].title);
    return _newMenus;
  };

  const _handleSubmit = () => {
    let _template = {...ctx.template};

    if (itemOpen) {
      _template[ctx.repas].menus[title] = {
        ..._template[ctx.repas].menus[itemOpen.title],
        type,
        categories
      };
      if (itemOpen.title !== title)
        delete _template[ctx.repas].menus[itemOpen.title];
    } else {
      _template[ctx.repas].menus[title] = {
        type,
        weight: Object.keys(_template[ctx.repas].menus).length,
        categories,
        formules: [],
      };
    }
    dispatch({type: "setValue", key: "template", value: {..._template}});
    setIsOpen(false);
    setItemOpen(null);
  };

  const _handleDelete = (key) => {
    let _template = {...ctx.template};
    delete _template[ctx.repas].menus[key];
    _template[ctx.repas].menus = _recomputeOrder(_template[ctx.repas].menus);
    dispatch({type: "setValue", key: "template", value: {..._template}});
    setIsOpen(false);
    setItemOpen(null);
  };

  const _handleOrder = (params) => {
    if (Object.keys(ctx.template[ctx.repas].menus).length <= 1) return;
    let _menus = Object.keys(ctx.template[ctx.repas].menus).map((title) => ({...ctx.template[ctx.repas].menus[title], title}));
    let _newMenus = {};
    _menus.sort((a, b) => a.weight - b.weight);
    _menus.forEach((menu, i) => menu.weight = i);

    let _source = _menus.splice(params.source.index, 1)[0];
    _menus.splice(params.destination.index, 0, _source);
    _menus.forEach((menu, i) => menu.weight = i);

    _menus.forEach((menu) => _newMenus[menu.title] = menu);
    Object.keys(_newMenus).forEach((title) => delete _newMenus[title].title);

    dispatch({
      type: "setValue",
      key: "template",
      value: {
        ...ctx.template,
        [ctx.repas]: {
          ...ctx.template[ctx.repas],
          menus: {
            ..._newMenus,
          }
        }
      }
    });
  };

  const _handleOrderCategories = (params) => {
    let _categories = [...categories];
    let _source = _categories.splice(params.source.index, 1)[0];
    _categories.splice(params.destination.index, 0, _source);
    setCategories(_categories);
  };

  if (repas === null) return <></>;
  return (
    <div>
      <ArrowLeftCircle style={{cursor: "pointer"}} size={28} onClick={() => dispatch({type: "setState", state: {...ctx, step: 0, repas: null}})} />
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 20, gap: 15}}>
        <h3 >Repas: {ctx.repas}</h3>
      </div>
      <div style={{display: "flex", gap: 25}}>
        <div style={{flex: 1}}>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 5}}>
            <h4>Menus:</h4>
            <em style={{color: "#888"}}>Le premier menu dans la liste est le menu attribué par défaut</em>
            <Button onClick={() => {setIsOpen(true); setItemOpen(null);}}><Plus /></Button>
          </div>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Titre</th>
                <th>Réccurrence</th>
                <th>Catégories</th>
                <th>Actions</th>
              </tr>
            </thead>
            <DragDropContext onDragEnd={_handleOrder}>
              <Droppable droppableId="droppable" direction="vertical">
                {(provided) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {Object.keys(ctx.template[ctx.repas].menus).sort((a, b) => ctx.template[ctx.repas].menus[a].weight - ctx.template[ctx.repas].menus[b].weight).map((key) => (
                      <Draggable key={key} draggableId={key} index={ctx.template[ctx.repas].menus[key].weight}>
                        {(provided) => (
                          <tr  style={{cursor: "grab", userSelect: "none"}} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <td><Menu color="#CCC" /></td>
                            <td>{key}</td>
                            <td>{ctx.template[ctx.repas].menus[key].type}</td>
                            <td>{ctx.template[ctx.repas].menus[key].categories.join(", ")}</td>
                            <td>
                              <Trash color="#e16f7e" style={{cursor: "pointer"}} onClick={() => _handleDelete(key)} />
                              <Edit color="#4a0956" style={{cursor: "pointer"}} onClick={() => {setIsOpen(true); setItemOpen({...ctx.template[ctx.repas].menus[key], title: key});}} />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </div>
      </div>
      <FormuleManager/>
      <PlateLinker />
      <Modal isOpen={isOpen} toggle={() => {setIsOpen(false); setItemOpen(null);}} size="lg">
        <ModalHeader>
          <h3 style={{marginBottom: 0}}>{itemOpen ? "Modifier un menu" : "Ajouter un menu"}</h3>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input label="Titre" value={title} onChange={e => setTitle(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Select label="Type" options={typeOptions} onChange={(e) => setType(e ? e.value : null)} value={typeOptions.find(e => e.value === type)} />
          </FormGroup>
          <FormGroup>
            <h4>Catégories:</h4>
            <Table>
              <thead>
                <tr>
                  <th>Titre</th>
                  <th>Action</th>
                </tr>
              </thead>
              <DragDropContext onDragEnd={_handleOrderCategories}>
                <Droppable droppableId="Categories" direction="vertical">
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {categories.map((category, i) => (
                        <Draggable key={category} draggableId={category} index={i}>
                          {(provided) => (
                            <tr style={{cursor: "grab", userSelect: "none"}} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <td>{category}</td>
                              <td>
                                <Trash color="#e16f7e" style={{cursor: "pointer"}} onClick={() => setCategories(categories.filter((_, id) => id !== i))} />
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      <tr>
                        <td><Input placeholder="Titre..." value={categoryTitle} onChange={e => setCategoryTitle(e.target.value)} /></td>
                        <td><Button onClick={() => {setCategories([...categories, categoryTitle]); setCategoryTitle("");}}><Plus /></Button></td>
                      </tr>
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </FormGroup>
        </ModalBody>
        <ModalFooter style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Button color="secondary" onClick={() => {setIsOpen(false); setItemOpen(null);}}>Annuler</Button>
          <Button color="primary" onClick={_handleSubmit}>Enregistrer</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const PlateLinker = () => {
  const [ctx, dispatch] = useCtx();

  const options = useMemo(() => {
    //compute x2 categories combinations
    let _categories = [];
    let _template = {...ctx.template};
    let _repas = ctx.repas;

    Object.keys(_template[_repas].menus).forEach((menu) => {
      _categories = [..._categories, ..._template[_repas].menus[menu].categories];
    });

    //remove duplicates
    _categories = [...new Set(_categories)];

    //compute all combinations, keep only unique ones
    //transform to options, {label: "cat1 -> cat2", value: "cat1,cat2"}
    let _options = [];
    _categories.forEach((cat1, i) => {
      _categories.forEach((cat2, j) => {
        if (i !== j && !_options.find(o => o.value === `${cat1},${cat2}`) && !_options.find(o => o.value === `${cat2},${cat1}`)) {
          let _label = `${cat1} <-> ${cat2}`;
          let _value = `${cat1},${cat2}`;
          _options.push({label: _label, value: _value});
        }
      });
    });
    return _options;
  }, [ctx?.template, ctx?.repas]);

  const _handleChange = (e) => {
    let _template = deepClone(ctx.template);

    _template[ctx.repas].links = e?.value;
    dispatch({type: "setValue", key: "template", value: _template});
  };

  return (
    <div>
      <h4>Linker</h4>
      <Select
        label="Linker"
        value={options?.find(e => e.value === ctx?.template[ctx?.repas]?.links) ?? null}
        onChange={_handleChange}
        options={options}
        clearable
      />
    </div>
  );
};

const RepasNavigator = () => {
  const [ctx, dispatch] = useCtx();

  return (
    <>
      <Tab 
        activeTab={Object.keys(ctx.template).indexOf(ctx.repas)}
        render={Object.keys(ctx.template).map(key => (
          {title: key, content: <></>, overrideOnClick: () => dispatch({type: "setState", state: {...ctx, repas: key}})}
        ))}
      />
      <Repas />
    </>
  );
};

const FormuleManager = () => {
  const [ctx, dispatch] = useCtx();

  const [formules, setFormules] = useState([]);
  const [categories, setCategories] = useState([]);

  const [isOpenFormule, setIsOpenFormule] = useState(false);
  const [itemOpenFormule, setItemOpenFormule] = useState(null);

  const [name, setName] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    if (ctx.repas) {
      const repasData = ctx.template[ctx.repas];
      setFormules(repasData?.formules ?? []);
      setCategories(repasData?.categories ?? []);
    }
  }, [ctx]);

  useEffect(() => {
    setName(itemOpenFormule?.name ?? "");
    setSelectedCategories(itemOpenFormule?.categories ?? []);
  }, [itemOpenFormule]);

  const handleClickCategory = (categ) => {
    if (selectedCategories.find((_find) => _find === categ)) {
      setSelectedCategories(selectedCategories.filter(_c => _c != categ));
    } else {
      setSelectedCategories([...selectedCategories, categ].sort((a, b) => categories.indexOf(a) - categories.indexOf(b)));
    }
  };

  const submit = () => {
    // si edit
    if (itemOpenFormule) {
      const index = formules.findIndex(_f => _f.name === itemOpenFormule.name && _f.categories === itemOpenFormule.categories);
      console.log(index);
      if (index !== -1) {
        const _formules = [...formules];
        _formules[index] = {
          name,
          categories: selectedCategories
        }

        dispatch({ type: "setValue", key: "template", value: { ...ctx.template, [ctx.repas]: { ...ctx.template[ctx.repas], formules: _formules } } });
      }
    } else {
      dispatch({
        type: "setValue", key: "template", value: {
          ...ctx.template, [ctx.repas]: {
            ...ctx.template[ctx.repas], formules: [...formules, {
              name,
              categories: selectedCategories
            }]
          }
        }
      });
    }

    setIsOpenFormule(false);
    setItemOpenFormule(null);
    setName("");
    setSelectedCategories([]);
  };

  const _handleDeleteFormule = (formule) => {
    setFormules(formules.filter(_f => _f.name !== formule.name && _f.categories !== formule.categories));
  };

  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between" }}>
        <h4>Formules :</h4>
        <Button onClick={() => { setIsOpenFormule(true); setItemOpenFormule(null); }}><Plus /></Button>
      </div>
      <div style={{ display: "flex", width: "100%" }}>

        <Table>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Catégories</th>
              <th>Actions</th>
            </tr>
          </thead>
          {formules.map((_f) => (
            <tr key={_f.name}>
              <td>{_f.name}</td>
              <td>{_f.categories.join(", ")}</td>
              <td>
                <Trash color="#e16f7e" style={{ cursor: "pointer" }} onClick={() => _handleDeleteFormule(_f)} />
                <Edit color="#4a0956" style={{ cursor: "pointer" }} onClick={() => { setIsOpenFormule(true); setItemOpenFormule(_f); }} />
              </td>
            </tr>
          ))}
        </Table>


      </div>
      <Modal isOpen={isOpenFormule} toggle={() => { setIsOpenFormule(false); setItemOpenFormule(null); }} size="lg">
        <ModalHeader>
          <h3 style={{ marginBottom: 0 }}>{isOpenFormule ? "Modifier la formule" : "Ajouter une fromule"}</h3>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input label="Nom" value={name} onChange={e => setName(e.target.value)} />

          </FormGroup>
          <FormGroup>
            <h4>Catégories:</h4>

            <ul>
              {categories.map(_categ => (
                <li key={_categ} onClick={() => handleClickCategory(_categ)} style={{
                  cursor: "pointer",
                  borderRadius: 8,
                  padding: 4,
                  ...(selectedCategories.includes(_categ) ? { backgroundColor: "#51075e", color: "white" } : {})
                }}>
                  {_categ}
                </li>
              ))}
            </ul>
          </FormGroup>
        </ModalBody>
        <ModalFooter style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button color="secondary" onClick={() => { setIsOpenFormule(false); setItemOpenFormule(null); }}>Annuler</Button>
          <Button color="primary" onClick={submit}>Enregistrer</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
};

export default RepasNavigator;