import React from "react";
import './index.less';
import './basic.less';
import Tree from 'rc-tree';

const TreeSelect = (props) => {
  const {
    treeData = [],
    onSelect = () => {console.log("select")},
    onCheck = () => console.log("check"),
    checkable = false,
    checkStrictly = false,
    checkedKeys = [],
    disabled = false,
    draggable = false,
    selectable = true,
    onDrop = () => console.log("dropped")
  } = props;
  return (
    <Tree
      defaultExpandAll
      checkable={checkable}
      selectable={selectable}
      checkStrictly={checkStrictly}
      showIcon={false}
      treeData={treeData}
      draggable={draggable}
      onCheck={onCheck}
      onSelect={onSelect}
      checkedKeys={checkedKeys}
      disabled={disabled}
      onDrop={onDrop}
    />
  );
};

export default TreeSelect;