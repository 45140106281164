import React, { useState } from "react";

import { LogOut, Settings } from "react-feather";
import Icon from "../lib/components/Icon";
import useUI from "../hooks/ui.hook";
import { useHistory } from "react-router-dom";
import { useFullscreen } from "rooks";
import Notification from "./_Notification";
import styles from "../assets/scss/config/SuperAdminMenu.module.scss";
import { Button, Modal } from "@/lib/components";

import Jerome from "assets/images/Jerome.png";

const Header = () => {
  const [ui, uiReducer] = useUI();
  const history = useHistory();
  const { exit, request, isFullscreen } = useFullscreen();
  const [isOpen, setIsOpen] = useState(false);


  const onLogout = () => {
    uiReducer({ type: "logout" });
  };
  const toggle = () => {
    if (isFullscreen) exit();
    else request();
  };

  return (
    <>
      {ui.user.superadmin ?
        <span className={styles.warning} style={{ marginRight: 10, cursor: "pointer" }}
          onClick={() => window.location.href = "/"}>
          SUPERADMIN MODE
        </span> : null}
      {ui.user.groupementAdmin ?
        <span className={styles.warning} style={{ marginRight: 10, cursor: "pointer" }}
          onClick={() => window.location.href = "/"}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Retour a l'administration du groupement
        </span>
        : null}
      {ui?.establishment?.demo === true && <Button className={styles.button} onClick={() => setIsOpen(true)}>
        Nous contacter
      </Button>}
      <Notification />
      <Icon color="primary" onClick={() => toggle()} size={36} className="m-r-20 p-r-10" />
      <Icon color="primary" onClick={() => history.push("/dashboard/profile")}
        size={36} className="m-r-20 p-r-10" icon={Settings} />
      <Icon color="primary" onClick={onLogout}
        size={36} className="p-r-10" align="right" icon={LogOut} />


      {ui?.establishment?.demo === true && <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <div className={styles.jcontainer}>
          <img src={Jerome} className={styles.jerome}></img>

          <div className={styles.text}>
            <b>Votre référent</b><br />
            &nbsp;<b>Jérôme Gourmel</b><br />
            &nbsp;06 95 89 99 16<br />
            &nbsp;jerome.gourmel@jabi.fr
          </div>

        </div>
      </Modal>}
    </>
  );
};

export default Header;