import { Checkbox, Table } from "lib/components";
import React, {useState, useEffect} from "react";
import { Plus, Trash, Edit, ArrowLeft, ArrowRight, ArrowLeftCircle, Menu } from "react-feather";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Select, Tab} from "lib/components";
import useCtx from "./ctx";
import { sortRepas } from "pages/Major/Menu/helpers/operations";
import styles from "./subscriptionDetails.module.scss";
import deepClone from "helpers/deepClone";


const SubscriptionDetails = () => {
  const [ctx, dispatch] = useCtx();

  const _handleCheckRepas = (_repas) => {
    let _templateSubscription = deepClone(ctx.templateSubscription2);

    // already defined
    if(_templateSubscription[ctx.subscription].repas[_repas]){
      delete _templateSubscription[ctx.subscription].repas[_repas];
    // not defined
    }else{
      _templateSubscription[ctx.subscription].repas[_repas] = {formules: []};
    }

    dispatch({type: "setState", state: {...ctx, templateSubscription2: _templateSubscription}});
   
  };

  const _handleCheckFormule = (_repas, _formule) => {
    let _templateSubscription = deepClone(ctx.templateSubscription2);

    // already defined
    if(_templateSubscription[ctx.subscription].repas[_repas]?.formules?.includes(_formule)){
      _templateSubscription[ctx.subscription].repas[_repas].formules = _templateSubscription[ctx.subscription].repas[_repas]?.formules?.filter(_c => _c !== _formule);
    // not defined
    }else{
      _templateSubscription[ctx.subscription].repas[_repas]?.formules?.push(_formule);
    }

    dispatch({type: "setState", state: {...ctx, templateSubscription2: _templateSubscription}});
   
  };

  const _handleChangeName = (newName) => {
    console.log(newName);
    if(newName !== ""){
      let _templateSubscription = deepClone(ctx.templateSubscription2);

      _templateSubscription[newName] = _templateSubscription[ctx.subscription];
      delete _templateSubscription[ctx.subscription];

      dispatch({type: "setState", state: {...ctx, templateSubscription2: _templateSubscription, subscription:newName}});
    }
   

  };
  
  const _repasList = sortRepas(ctx.template, Object.keys(ctx.template));

  if (ctx.subscription === null) return <></>;
  return (
    <div className={styles.container}>
      <ArrowLeftCircle style={{cursor: "pointer"}} size={28} onClick={() => dispatch({type: "setState", state: {...ctx, step: 0, subscription: null}})} />

      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Input value={ctx.subscription} onChange={(e) => _handleChangeName(e.target.value)}/>
        <h3>{ctx.subscription}</h3>
      </div>
      <div className={styles.table}>
      {_repasList.map(key => (
            <div key={key} className={styles.repas}>
                <div className={styles.repasHeader}>
                  <div className={styles.repasName}>
                    {key} 
                  </div>
                  <Checkbox className={styles.checkbox} checked={ctx.templateSubscription2[ctx.subscription].repas[key] ? true : false} onChange={() => _handleCheckRepas(key)}/>
                </div>
                {/* <div className={styles.categories}>
                  {ctx.templateSubscription2[ctx.subscription].repas[key] ? ctx.template[key].categories.map(_categ => (
                    <div key={_categ} className={styles.categ}>
                      <div className={styles.categName}>
                       {_categ}
                      </div>
                      <Checkbox className={styles.checkbox}  checked={ctx.templateSubscription2[ctx.subscription].repas[key].includes(_categ)} onChange={() => _handleCheckFormule(key, _categ)}/>
                    </div>
                  )) : null}
                </div> */}
                <div className={styles.categories}>
                  {ctx.templateSubscription2[ctx.subscription].repas[key] ? ctx.template[key]?.formules?.map(_formule => (
                    <div key={_formule} className={styles.categ}>
                      <div className={styles.categName}>
                       {`${_formule.name} - (${_formule.categories.join(", ")})`}
                      </div>
                      <Checkbox className={styles.checkbox}  checked={ctx.templateSubscription2[ctx.subscription].repas[key]?.formules?.includes(_formule.name)} onChange={() => _handleCheckFormule(key, _formule.name)}/>
                    </div>
                  )) : null}
                </div>
            </div>
          ))}
      </div>
    </div>
  );
};


export default SubscriptionDetails;