import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import moment from "moment";
import PropTypes from "prop-types";
import { useMenuReservation } from "../../router_context";
import styles from "../Assets/scss/modalInformations.module.scss";
import { Tag } from "lib/components";
import {findSubscriptionForDate} from "helpers/subscription"

const colors = ["#8c66dc", "#dc6681", "#00bfb2", "#DC965A", "#81171B"];
const colorUnite = "#123865";

const ModalInformations = ({ reservation, page }) => {
    const [ui] = useUI();
    const [ctx, dispatch] = useMenuReservation();

    // const dataDay = ctx?.data[ctx?.modalInfos?.date];
    const currentUser = ctx?.users[ctx?.modalInfos?.uid] ?? {};
    const template = ui.establishment.template;
    const templateSubscription = ui.establishment.templateSubscription;
    const subscription = findSubscriptionForDate(currentUser?.subscriptions, ctx?.modalInfos?.date);

    const supplements = useMemo(() => {
        const _supp = [];

        if (template && templateSubscription && subscription?.subscription && templateSubscription[subscription?.subscription].repas[ctx?.modalInfos?.repas]) {
            // s'il y a un abonnement pour ce repas.

            // maintenant on check tous les plats qui sont réservés alors qu'ils ne sont pas dans l'abonnement.
            template[ctx.modalInfos.repas].categories.forEach(_plat => {
                if (reservation[0][_plat] && !templateSubscription[subscription?.subscription].repas[ctx?.modalInfos?.repas].includes(_plat)) _supp.push(_plat);
            });
        }
        return _supp;
    }, [ctx, reservation]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.title}> {currentUser?.surname} {currentUser?.name} {page > 0 ? `(Invité ${page})` : ""}</div>
            </div>
            {page === 0 && templateSubscription ?
                <div className={styles.content}>
                    <div className={styles.title}> Abonnement : </div>
                    <Tag className={styles.tag} style={{ backgroundColor: subscription?.subscription ? colors[Object.keys(templateSubscription).indexOf(subscription?.subscription) % 5] : colorUnite }}><div>{subscription?.subscription ?? "Unité"}</div></Tag>
                </div>
                : null}

            <div className={styles.content}>
                <div className={styles.title}> Formule sélectionnée : </div>
                {
                    template[ctx.modalInfos.repas].categories.map((_categ, indx) => (
                        reservation[page] && reservation[page][_categ] && ((page === 0 && !supplements.includes(_categ)) || page > 0) ? <div key={indx}>{_categ} </div> : null
                    ))
                }
            </div>
            {page === 0 && subscription?.subscription ?
                <div className={styles.content}>
                    <div className={styles.title}> Suppléments sélectionnés : </div>
                    {
                        supplements.map((_categ, indx) => (
                            <div key={indx}>{_categ} </div>
                        ))
                    }
                </div>
                : null}

            {templateSubscription && subscription?.subscription ?
                <div className={styles.content}>
                    {subscription?.subscription && templateSubscription[subscription?.subscription].repas[ctx?.modalInfos?.repas] ?
                        <div className={styles.text}> Le {ctx?.modalInfos?.repas} est compris dans votre abonnement.</div>
                        :
                        <div className={styles.text}> Le {ctx?.modalInfos?.repas} n&apos;est pas compris dans votre abonnement. </div>
                    }
                </div>
                : <div></div>}
        </div>
    );
};


ModalInformations.propTypes = {
    reservation: PropTypes.array,
    page: PropTypes.number,
};

export default ModalInformations;
