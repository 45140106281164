import React, { useState, useEffect } from "react";
import useUI from "hooks/ui.hook";

import firebase from "firebase";

import styles from "../../../Basic/Assets/scss/menu.module.scss";

import moment from "moment";

import { Resume, ReservationTable } from "../../../router_components";
import { useMenuReservation } from "../../../router_context";

import { VideoTuto } from "lib/components/YoutubeModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Button } from "lib/components";
import DropDown from "lib/components/DropDown";
import { Download } from "react-feather";
import { filterRepas, sortRepas, sortCategories } from "pages/Major/Menu/helpers/operations";
import { ReservationTableGuest, ExportTable } from "../../../router_components";
import ExportDay from "../Components/ExportDay";
import { findSubscriptionForDate } from "helpers/subscription"

const sku = [
  {
    "id": "1/2FORFD",
    "name": "Forfait 1/2 pension DUO",
    "category": "Déjeuner"
  },
  {
    "id": "1/2FORFT",
    "name": "Forfait 1/2 pension TRIO",
    "category": "Déjeuner"
  },
  {
    "id": "DEJDUO",
    "name": "Déjeuner Duo",
    "category": "Déjeuner"
  },
  {
    "id": "DEJTRIO",
    "name": "Dejeuner Trio",
    "category": "Déjeuner"
  },
  {
    "id": "DEJBIEN",
    "name": "Déjeuner Bienvenue",
    "category": "Déjeuner"
  },
  {
    "id": "ARRIVAGE",
    "name": "Arrivage du mois",
    "category": "Déjeuner"
  },
  {
    "id": "DEJASTRE",
    "name": "Dejeuner astreinte",
    "category": "Déjeuner"
  },
  {
    "id": "DEJENFANT",
    "name": "Déjeuner enfant",
    "category": "Déjeuner"
  },
  {
    "id": "DEJSALAR",
    "name": "Déjeuner salarié",
    "category": "Déjeuner"
  },
  {
    "id": "INVSALAST",
    "name": "Invité salarié astreinte",
    "category": "Déjeuner"
  },
  {
    "id": "INVSALDEJ",
    "name": "Déjeuner invité salarié",
    "category": "Déjeuner"
  },
  {
    "id": "DUORES",
    "name": "Dîner duo",
    "category": "Diner"
  },
  {
    "id": "DINTHEME",
    "name": "Diner à thème",
    "category": "Diner"
  },
  {
    "id": "DINBIEN",
    "name": "Diner Bienvenue",
    "category": "Diner"
  },
  {
    "id": "DINASTRE",
    "name": "Diner Astreinte",
    "category": "Diner"
  },
  {
    "id": "DINENF",
    "name": "Diner Enfant",
    "category": "Diner"
  },
  {
    "id": "DINSAL",
    "name": "Diner salarié",
    "category": "Diner"
  },
  {
    "id": "SUPDESSERT",
    "name": "Supplément dessert",
    "category": "Supplément"
  },
  {
    "id": "SUPFROM",
    "name": "Supplément fromage",
    "category": "Supplément"
  },
];

const ReservationMenus = () => {
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();
  const [showTotal, setShowTotal] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [modalExportOpen2, setModalExportOpen2] = useState(false);
  const [modalExportOpen3, setModalExportOpen3] = useState(false);
  const template = ui?.establishment?.template;

  const sortedRepas = sortRepas(template, Object.keys(template));


  //const [ui] = useUI();
  //const [page, setPage] = useState(0);

  useEffect(() => {
    if (ctx.data && ctx.users) {
      selectDate();
    }
  }, [ctx.data, ctx.users]);

  useEffect(() => {
    if (ctx.data && ctx.users) {
      selectDate();
    }
  }, [ctx?.selectedWeek, ctx.daysToDisplay]);

  const selectDate = () => {

    if (!ctx?.selectedWeek) {
      dispatch({ type: "setProperty", property: "selectedWeek", value: moment().startOf("week") });
    } else {
      let _dateArray = [];

      for (let i = 0; i < 7; i++) {
        if (ctx.daysToDisplay.includes(i + 1)) {
          _dateArray.push(ctx?.selectedWeek?.clone().add(i, "days").format("YYYY-MM-DD"));
        }
      }

      dispatch({ type: "setProperty", property: "dateArray", value: _dateArray });
    }
  };

  const onCloseExport = () => {
    setModalExportOpen(false);
    setModalExportOpen2(false);
    setModalExportOpen3(false);
  };

  const changeWeek = (direction) => {
    // direction = -1 || 1
    if (!ctx?.selectedWeek) {
      dispatch({ type: "setProperty", property: "selectedWeek", value: moment().startOf("week") });
    } else {
      dispatch({ type: "setProperty", property: "selectedWeek", value: ctx?.selectedWeek?.clone().add(direction, "week") });
    }
  };

  const generateExportData = async (date) => {
    const start = moment(date).startOf("day").clone().toDate();
    const end = moment(date).clone().endOf("day").toDate();
    const periodData = await ctx.getData(start, end);

    const formated = moment(date).format("YYYY-MM-DD");

    const _obj = {};

    if (periodData.data[formated] && periodData.data[formated].reservation) {
      Object.entries(periodData.data[formated].reservation).forEach(([userId, values]) => {
        if (values) {
          Object.entries(values).forEach(([repas, reservations]) => {
            if (reservations && reservations[0] && reservations[0].status !== "canceled" ) {
              const _categories = [...template[repas].categories, "supplément"];
              if (!_obj[repas]) _obj[repas] = {};

              if (!_obj[repas][userId]) _obj[repas][userId] = [];

              _obj[repas][userId] = reservations.map(_r => {
                const _array = [];
                _categories.forEach(_categ => {
                  if (_r[_categ]) {
                    if (_categ === "garniture") {
                      Object.values(periodData.data[formated][repas][_categ])?.forEach((element, index) => {
                        _array.push(element.name);
                      });
                    } else {
                      if (_categ === "supplément") {
                        _array.push(periodData.data[formated][repas]["dessert"]?.[_r[_categ]]?.name ?? _r[_categ]);

                      } else {
                        _array.push(periodData.data[formated][repas][_categ]?.[_r[_categ]]?.name ?? _r[_categ]);

                      }

                    }
                  }
                });
                return _array;
              });

            }
          });
        }

      });
    }


    const data = {};

    // LES TOTAUX

    const totalHeaders = [{ v: "Total Réservations", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, border: { bottom: { style: "thin", color: "000000" } } } }];
    sortRepas(template, Object.keys(_obj)).forEach(_repas => {
      totalHeaders.push({ v: _repas, s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, border: { bottom: { style: "thin", color: "000000" } } } });
    });
    if (!data["Totaux"]) data["Totaux"] = [totalHeaders];

    const totalData = {
      Restaurant: {
        "Déjeuner": {
          "total": 0,
          "duo": 0,
          "trio": 0
        },
        "Dîner": {
          "total": 0,
          "duo": 0,
          "tri": 0,
        }
      },
      Portage: {
        "Déjeuner": {
          "total": 0,
          "duo": 0,
          "trio": 0
        },
        "Dîner": {
          "total": 0,
          "duo": 0,
          "trio": 0,
        }
      },
    };

    // fill with init data.
    Object.keys(totalData).forEach(element => {
      sortRepas(template, Object.keys(template)).forEach(repas => {
        totalData[element][repas] = {
          "total": 0,
          "duo": 0,
          "trio": 0,
        };
      });
    });


    sortRepas(template, Object.keys(_obj)).forEach(_repas => {
      Object.keys(periodData.data[formated].reservation).forEach(resa => {
        const _resa = periodData.data[formated].reservation[resa];
        if (_resa[_repas] && _resa[_repas].length > 0 && _resa[_repas][0].status !== "canceled") {
          _resa[_repas].forEach(_r => {
            let mode = null;

            if (_r.homeDelivery) {
              totalData["Portage"][_repas]["total"] += 1;
              mode = "Portage";

            } else {
              totalData["Restaurant"][_repas]["total"] += 1;
              mode = "Restaurant";
            }

            // Si réservation vide
            if (Object.keys(_r).filter(element =>
              template[_repas].categories.includes(element) && _r[element]).length === 0) {

              // alors on fait en fonction des abonnements : 
              const subscription = findSubscriptionForDate(ctx.users[resa]?.subscriptions, date);

              const _subscription = subscription?.subscription.split(" ")[1];

              if (_subscription && _subscription.includes("Duo")) totalData[mode][_repas]["duo"] += 1;
              if (_subscription && _subscription.includes("Trio")) totalData[mode][_repas]["trio"] += 1;

            } else {
              let res = Object.keys(_r).filter(value => ["entrée", "plat", "dessert"].includes(value) && _r[value]).length;
              if (res === 2) totalData[mode][_repas]["duo"] += 1;
              if (res === 3) totalData[mode][_repas]["trio"] += 1;
            }
          });
        }
      });
    });

    Object.keys(totalData).forEach(mode => {
      data["Totaux"].push(["", "", ""]);
      data["Totaux"].push([
        { v: `Total ${mode}`, s: { border: { bottom: { style: "thin", color: "000000" } } } },
        ...Object.keys(totalData[mode]).map(_repas => ({ v: totalData[mode][_repas]["total"], s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, border: { bottom: { style: "thin", color: "000000" } } } }))
      ]);

      data["Totaux"].push([
        "Duo",
        ...Object.keys(totalData[mode]).map(_repas => ({ v: totalData[mode][_repas]["duo"], s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } }))
      ]);

      data["Totaux"].push([
        "Trio",
        ...Object.keys(totalData[mode]).map(_repas => ({ v: totalData[mode][_repas]["trio"], s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } }))
      ]);

    });

    // DETAILS PORTAGE

    sortRepas(template, Object.keys(_obj)).forEach(_repas => {
      let _repasData = [];
      if (!data[_repas]) data[_repas] = [[
        { v: "Nom", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
        { v: "Prénom", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
        { v: "Appartement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
        { v: "Abonnement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
        { v: "Formule", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
        ...[...template[_repas].categories, "Suppléments"].map(_categ => {
          return { v: _categ, s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } };
        })
      ]];

      Object.entries(_obj[_repas]).forEach(([userId, _resas], idx) => {

        const _localData = [];
        _resas.forEach((_resa, _indx) => {

          const subscription = findSubscriptionForDate(ctx.users[userId]?.subscriptions, date);

          const _subscription = subscription?.subscription.split(" ")[1];

          let _line = [
            { v: ctx.users[userId].name ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            { v: ctx.users[userId].surname ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            { v: ctx.users[userId].room ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            { v: _subscription ?? "Unité", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            { v: "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          ];

          if (_indx > 0) {
            _line = [
              { v: "invité", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: "invité", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: "Unité", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            ];
          }
          if (_resa.length === 0) {
            // alors on fait en fonction des abonnements : 

            // Cas de réservation sans désigner des plats spécifiques = Plat du jour
            [...template[_repas].categories, "supplément"].forEach(_categ => {

              const subscription = findSubscriptionForDate(ctx.users[userId]?.subscriptions, date);
              const _subscription = subscription?.subscription.split(" ")[1] ?? "Unité";

              if (_categ === "dessert" && _subscription === "Duo") {
                _line.push({
                  v: "",
                  s: {
                    alignment: { vertical: "center", horizontal: "center", wrapText: true },
                  }
                }
                );
              } else {
                _line.push({
                  v: Object.values(periodData.data[formated][_repas][_categ] ?? {})
                    .find(_plate => _plate.origin.toLowerCase() === "menu du jour")?.name ?? "",
                  s: {
                    alignment: { vertical: "center", horizontal: "center", wrapText: true },
                  }
                }
                );
              }
            });

          } else {
            // Cas où on spécifie des plats
            [...template[_repas].categories, "supplément"].forEach(_categ => {
              let plate = Object.values(periodData.data[formated][_repas][_categ] ?? {})
                .find(_plate => _resa.includes(_plate?.name));
              if (plate && _categ !== "garniture") {
                _line.push({
                  v: plate.name,
                  s: {
                    alignment: { vertical: "center", horizontal: "center", wrapText: true },
                  }
                });
              }
              else {
                // Plusieurs cas possibles
                // Cas des garnitures multiples
                if (_categ === "garniture") {
                  let _v = [];
                  Object.values(periodData.data[formated][_repas][_categ]).forEach(_p => {
                    if (_resa.includes(_p.name))
                      _v.push(_p.name);
                  });
                  _line.push({
                    v: _v.join(", "),
                    s: {
                      alignment: { vertical: "center", horizontal: "center", wrapText: true },
                    }
                  });
                }
                // Cas pour le Dessert/supplément : Si deux desserts on affiche le premier en tant que dessert et le deuxième en tant que supplément
                else if (_categ === "dessert") {
                  plate = [...Object.values(periodData.data[formated][_repas]["dessert"]).map(_p => _p.name), "assiette de fromage", "faisselle"].find(_plate => _resa.includes(_plate));
                  if (plate) {
                    _line.push({
                      v: plate,
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    });
                  }
                } else if (_categ === "supplément") {
                  let plates = [...Object.values(periodData.data[formated][_repas]["dessert"]).map(_p => _p.name), "assiette de fromage", "faisselle"].filter(_plate => _resa.includes(_plate));
                  _line.push(
                    {
                      v: plates.length === 2 ? plates[1] : "",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                  );
                } else {
                  // Tous les autres cas
                  _line.push(
                    {
                      v: "",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                  );
                }
              }
            });
          }

          // Calcul de la formule choisie
          let formule = 0;
          _line.forEach((element, idx) => {
            if ([0, 1, 2, 3, 4, 7, 9].includes(idx)) return;
            if (element.v.length > 0) formule += 1;
          });

          _line[4].v = formule === 2 ? "Duo" : formule === 3 ? "Trio" : "";
          _localData.push(_line);
        });

        data[_repas] = [...data[_repas]];
        _repasData.push(_localData);
      });

      // Tri des lignes
      _repasData.sort((a, b) => {
        const vANom = a[0][0].v ?? "";
        const vAPrenom = a[0][1].v ?? "";
        const vBNom = b[0][0].v ?? "";
        const vBPrenom = b[0][1].v ?? "";

        return `${vANom} ${vAPrenom}`.localeCompare(`${vBNom} ${vBPrenom}`);
      });

      // Coloriser une ligne sur deux
      _repasData.forEach((lines, linesIndex) => {
        lines.forEach((line, lineIndex) => {
          line.forEach((cell, cellIndex) => {
            if (linesIndex % 2 === 0) {
              _repasData[linesIndex][lineIndex][cellIndex] = {
                ...cell,
                s: {
                  ...cell.s,
                  fill: {
                    fgColor: {
                      rgb: "e6e6e6"
                    }
                  },
                }
              };
            }
          });
        });
        return lines;
      });
      data[_repas] = [...data[_repas], ..._repasData.flat()];
    });

    return data;
  };

  const generateResumeRepas = (data, repas) => {

    let retour = "";

    if (data[repas]) {
      const _categories = sortCategories(ui.establishment.template, repas, Object.keys(data[repas]));

      _categories.forEach(_categ => {
        const dishes = Object.keys(data[repas][_categ]);
        if (dishes.length > 0) {

          dishes.forEach(_dishId => {
            let count = 0;

            Object.keys(ctx.users).forEach(_uid => {
              if (data.reservation != undefined) {
                if (data.reservation[_uid] && data.reservation[_uid][repas] && data.reservation[_uid][repas].length > 0) {
                  data.reservation[_uid][repas].forEach(element => {
                    if (element[_categ] === _dishId) {
                      count++;
                    }
                  });
                }
              }
            });

            retour += data[repas][_categ][_dishId].name + " : " + count + "\n";

          });

        }
      });
    }


    return retour;
  };

  const isRepasUnite = (userId, _repas, date) => {
    const _user = ctx.users[userId];
    const template = ui.establishment.template;
    const templateSubscription = ui.establishment.templateSubscription;
    const subscription = findSubscriptionForDate(_user?.subscriptions, date);
    const _subscription = subscription?.subscription;

    let retour = true;

    if (template && templateSubscription) {
      if (_subscription) {
        // L'utilisateur a un abonnement pour ce mois.

        // si jamais l'abonnement prend en compte le repas, alors ce n'est pas un repas à l'unité.
        if (templateSubscription[_subscription].repas[_repas]) {
          retour = false;
        }
      }
    }

    return retour;
  };

  const isSupplement = (userId, data, _repas, date) => {
    const _user = ctx.users[userId];
    const template = ui.establishment.template;
    const templateSubscription = ui.establishment.templateSubscription;
    const subscription = findSubscriptionForDate(_user?.subscriptions, date);
    const _subscription = subscription?.subscription;

    let retour = true;

    if (template && templateSubscription) {
      if (_subscription) {
        // L'utilisateur a un abonnement pour ce mois.

        // si le repas est pris en compte dans l'inscription
        if (templateSubscription[_subscription].repas[_repas]) {

          // On considère qu'il y a un supplément dans le cas où il y a plus de catégories réservés que de catégories
          // dans l'abonnement
          // ON ne prend PAS en compte les invitations. 
          let countResa = 0;

          template[_repas].categories.forEach((_categ) => {
            if (data[_repas][0][_categ] != null) {
              countResa++;
            }
          });

          if (countResa > templateSubscription[_subscription].repas[_repas].length) {
            retour = true;
          } else {
            retour = false;
          }

        } else {
          retour = false;
        }

      } else {
        retour = false;
      }
    } else {
      retour = false;
    }


    return retour;
  };


  const isHomeDelivery = (repas) => {
    return repas[0].homeDelivery;
  };


  const generateExportDataOvelia = async (period, date, identifiedBySurname, identifiedByName, identifiedByNumero, total, signature) => {
    const start = date.clone().toDate();
    const end = date.clone().add(1, period).toDate();
    const data = await ctx.getData(start, end);

    const sortedRepas = sortRepas(ui.establishment.template, Object.keys(ui.establishment.template));

    let generatedData = null;

    let header = {};

    if (identifiedByNumero) {
      header["Appartement"] = [];
    }
    if (identifiedBySurname) {
      header["Prénom"] = [];
    }
    if (identifiedByName) {
      header["Nom"] = [];
    }

    if (total) {
      header["Total"] = [{ "Total": [] }];
      sortedRepas.forEach(_repas => {
        header["Total"].push({ [_repas]: [] });
      });
      header["Portage"] = [];
      header["Invitations"] = [];
      header["Supplément"] = [];

    }



    let _users = {};
    let sumUsers = {};

    Object.entries(ctx.users).forEach(([key, value]) => {
      _users[key] = { surname: value.surname, name: value.name, room: value.room, table: [] };
      if (!sumUsers[key]) {
        sumUsers[key] = {
          sumPortage: 0,
          sumInvitations: 0,
          sumSupplement: 0,
        };
        sortedRepas.forEach(_repas => {
          sumUsers[key]["sum" + _repas] = 0;
        });
      }

    });


    let totalList = [];
    let portageList = [];
    let invitationList = [];
    let customStyles = [];



    let localDate = date.clone();
    let numDays = - localDate.diff(localDate.clone().add(1, period), "days");
    for (let i = 1; i <= numDays; i++) {
      let formated = localDate.format("YYYY-MM-DD");
      header[localDate.format("ddd DD/MM")] = [];
      if (data[formated] != undefined) {
        const dataDay = data[formated];
        sortRepas(ui.establishment.template, filterRepas(ui.establishment.template, Object.keys(data[formated]))).forEach(_repas => {
          let obj = {};
          let obj_content = {};
          //let content = generateResumeRepas(dataDay, _repas);

          //obj_content[content] = [];
          obj[_repas] = [];//[obj_content];
          header[localDate.format("ddd DD/MM")].push(obj);

          let tot = 0;
          let totPortage = 0;
          let totInvitation = 0;

          Object.entries(_users).forEach(([key, value], _indx) => {

            if (dataDay.reservation != undefined) {
              if (dataDay.reservation[key] && dataDay.reservation[key][_repas] && dataDay.reservation[key][_repas].length > 0) {
                value.table.push(dataDay.reservation[key][_repas].length);
                tot += dataDay.reservation[key][_repas].length;

                sumUsers[key]["sum" + _repas] += dataDay.reservation[key][_repas].length;

                let _customStyle = {};

                if (dataDay.reservation[key][_repas].length > 1) {
                  totInvitation += dataDay.reservation[key][_repas].length - 1;
                  sumUsers[key].sumInvitations += dataDay.reservation[key][_repas].length - 1;
                }

                if (dataDay.reservation[key][_repas][0].homeDelivery === true) {
                  totPortage++;
                  _customStyle = { fill: { fgColor: { rgb: "f2ea00" } } };
                  sumUsers[key].sumPortage++;

                  if (isSupplement(key, dataDay.reservation[key], _repas, localDate)) {
                    _customStyle = Object.assign(_customStyle, {
                      font: {
                        color: { rgb: "be00f2" },
                        bold: true,
                        sz: "13"
                      },
                      fill: { fgColor: { rgb: "f2ce00" } }
                    });
                    sumUsers[key].sumSupplement++;
                  }
                }
                // }else{
                //   // if(isSupplement(key, dataDay.reservation[key], _repas, localDate)){
                //   //   _customStyle =  Object.assign(_customStyle, {fill: {fgColor: {rgb: "e33119"}}});
                //   // }
                // }

                if (isRepasUnite(key, _repas, localDate)) {
                  _customStyle = Object.assign(_customStyle, {
                    font: {
                      color: { rgb: "e33119" },
                      bold: true,
                      sz: "13"
                    }
                  });
                }



                if (Object.keys(_customStyle).length > 0) {
                  customStyles.push({
                    x: value.table.length - 1,
                    y: _indx,
                    style: _customStyle
                  });
                }
              }
              else value.table.push("");
            } else value.table.push("");

          });


          if (total) {
            if (tot > 0) {
              totalList.push(tot);
            } else totalList.push("");

            if (totPortage > 0) {
              portageList.push(totPortage);
            } else portageList.push("");

            if (totInvitation > 0) {
              invitationList.push(totInvitation);
            } else invitationList.push("");
          }

        });
      } else { // aucun événement ce jour
        Object.entries(_users).forEach(([key, value]) => {
          value.table.push("");
        });
        if (total) {
          totalList.push("");
          portageList.push("");
          invitationList.push("");
        }
      }
      localDate = moment(localDate).add(1, "day");
    }


    if (signature) {
      header["Signature"] = [];
    }

    let _data = [];
    Object.entries(_users).forEach(([key, value], _indx) => {
      let table = value.table;
      let sum = 0;


      if (total) {
        table.forEach(element => {
          if (element != "" && Number.isInteger(parseInt(element))) sum += parseInt(element);
        });

      }
      if (total) {
        table = [sumUsers[key].sumSupplement, ...table];
        table = [sumUsers[key].sumInvitations, ...table];
        table = [sumUsers[key].sumPortage, ...table];
        sortedRepas.reverse().forEach(_repas => {
          table = [sumUsers[key]["sum" + _repas], ...table];
        });
        table = [sum, ...table];

      }

      if (identifiedByName) {
        table = [value.name, ...table];
      }
      if (identifiedBySurname) {
        table = [value.surname, ...table];
      }
      if (identifiedByNumero) {
        table = [value.room, ...table];
      }

      if (signature) {
        table = [...table, ""];
      }
      _data.push(table);
    });
    let colStart = 0 + identifiedBySurname + identifiedByName + identifiedByNumero; // true == 1, false == 0;
    total ? colStart += (4 + sortedRepas.length) : null;
    let colEnd = 0 + signature;

    if (total) {
      let total_total = 0;
      totalList.forEach(element => {
        let num = parseInt(element);
        if (!isNaN(num)) total_total += num;
      });

      // totalList = ["Total",...totalList];


      // totalList = [...totalList,total_total.toString()];






      portageList = ["Portage", ...portageList];

      invitationList = ["Invitations", ...invitationList];


      const _categReverseOrder = ["Supplément", "Invitations", "Portage"];
      sortedRepas.reverse().forEach(_repas => {
        _categReverseOrder.push(_repas);
      });
      _categReverseOrder.push("Total");

      for (let i = 0; i < colStart - 1; i++) {

        if (_categReverseOrder[i]) {
          if (_categReverseOrder[i] === "Supplément") {
            let _sum = 0;
            Object.values(sumUsers).forEach(_obj => {
              _sum += _obj.sumSupplement;
            });
            totalList = [_sum, ...totalList];

          } else if (_categReverseOrder[i] === "Invitations") {
            let _sum = 0;
            Object.values(sumUsers).forEach(_obj => {
              _sum += _obj.sumInvitations;
            });
            totalList = [_sum, ...totalList];

          } else if (_categReverseOrder[i] === "Portage") {
            let _sum = 0;
            Object.values(sumUsers).forEach(_obj => {
              _sum += _obj.sumPortage;
            });
            totalList = [_sum, ...totalList];

          } else if (_categReverseOrder[i] === "Total") {
            totalList = [total_total, ...totalList];

          } else {
            if (sortedRepas.includes(_categReverseOrder[i])) {
              let _sum = 0;
              Object.values(sumUsers).forEach(_obj => {
                _sum += _obj["sum" + _categReverseOrder[i]];
              });
              totalList = [_sum, ...totalList];
            } else {
              totalList = ["", ...totalList];
            }
          }
        } else {
          totalList = ["", ...totalList];
        }

        portageList = ["", ...portageList];
        invitationList = ["", ...invitationList];

      }
      totalList = ["Total", ...totalList];

      _data.push(totalList);
      _data.push(portageList);
      _data.push(invitationList);

    }

    generatedData = {
      header: header,
      data: _data,
      depth: 2,
      bottomLength: 3,
      colStart: colStart,
      colEnd: colEnd,
      styles: customStyles
    };

    return generatedData;
  };


  const getFormula = (_repas, _reservation) => {
    if (_repas === "Déjeuner") {
      let count = 0;
      if (_reservation) {

        if (_reservation["entrée"]) count++;
        if (_reservation["plat"]) count++;
        if (_reservation["dessert"]) count++;
      }

      if (count <= 2) return "DUO";
      if (count >= 3) return "TRIO";

      return null;
    }
    if (_repas === "Dîner") {
      let count = 0;
      if (_reservation) {

        if (_reservation["entrée"]) count++;
        if (_reservation["plat"]) count++;
        if (_reservation["dessert"]) count++;
      }

      if (count <= 2) return "DUO";
      else return "TRIO";
      // return null;
    }
    return null;
  };

  const getSupplements = (_array, _userId, _repas) => {
    if (_array && _array[_userId] && _array[_userId][_repas] && _array[_userId][_repas].length > 0) {
      return _array[_userId][_repas];
    } else {
      return [];
    }
  };

  const getSKURepas = (repas, formula, userId) => {

    const _role = userId && ctx.users && ctx.users[userId] && ctx.users[userId].role ? ["senior", "seniorTotem"].includes(ctx.users[userId].role) ? "senior" : ["employee", "owner"].includes(ctx.users[userId].role) ? "employee" : "senior" : "senior";
    if (sortedRepas.indexOf(repas) === 0) {
      if (_role === "senior") {
        if (formula === "DUO") {
          return sku.find((_c) => _c.id === "DEJDUO");
        }
        if (formula === "TRIO") {
          return sku.find((_c) => _c.id === "DEJTRIO");
        }
      } else {
        return sku.find((_c) => _c.id === "DEJSALAR");
      }
    } else {
      if (_role === "senior") {
        return sku.find((_c) => _c.id === "DUORES");
      } else {
        return sku.find((_c) => _c.id === "DINSAL");
      }
    }

  };

  const generateExportDataRestaurant = async (date) => {
    const start = moment(date).startOf("day").clone().toDate();
    const end = moment(date).clone().endOf("day").toDate();
    const periodData = await ctx.getData(start, end);

    const formated = moment(date).format("YYYY-MM-DD");

    const _obj = {};


    // on remplit l'objet avec les données nécessaires pour remplir le tableau :

    //réservations classiques (portage)
    if (periodData?.data[formated]?.reservation) {
      Object.entries(periodData?.data[formated]?.reservation).forEach(([_userId, _content]) => {
        Object.entries(_content).forEach(([_r, _list]) => {
          if (_list.length > 0 && _list[0].homeDelivery === true) {

            const subscription = findSubscriptionForDate(ctx.users[_userId]?.subscriptions, formated);
            const _subscription = subscription?.subscription;

            // ajout de la résa du résident
            if (!_obj[_userId])
              _obj[_userId] = {
                name: ctx.users[_userId].name,
                surname: ctx.users[_userId].surname,
                room: ctx.users[_userId].room,
                subscription: _subscription ?? "",
                articles: [],
              }

            _obj[_userId].articles.push({
              homeDelivery: "oui",
              id: getSKURepas(_r, getFormula(_r, _list[0]), _userId).id,
              quantity: 1,
            });


            // ajout de ses invités
            if (_list.length > 1) {
              for (let i = 1; i < _list.length; i++) {
                if (!_obj[_userId + i])
                  _obj[_userId + i] = {
                    name: `Invité ${i} de ` + ctx.users[_userId].name,
                    surname: ctx.users[_userId].surname,
                    room: ctx.users[_userId].room,
                    subscription: "",
                    articles: [],
                  }

                _obj[_userId + i].articles.push({
                  homeDelivery: "oui",
                  id: getSKURepas(_r, getFormula(_r, _list[0]), _userId + i).id,
                  quantity: 1,
                });
              }
            }
          }
        });
      });
    }

    //commandes en restaurant
    if (periodData?.dataOrders[formated]) {
      Object.entries(periodData?.dataOrders[formated]).forEach(([_userId, _content]) => {
        Object.entries(_content).forEach(([_r, _list]) => {

          const subscription = findSubscriptionForDate(ctx.users[_userId]?.subscriptions, formated);
          const _subscription = subscription?.subscription;

          if (_list.length > 0) {
            if (!_obj[_userId])
              _obj[_userId] = {
                name: ctx.users[_userId].name,
                surname: ctx.users[_userId].surname,
                room: ctx.users[_userId].room,
                subscription: _subscription ?? "",
                articles: [],
              }

            _obj[_userId].articles.push({
              homeDelivery: "non",
              id: getSKURepas(_r, getFormula(_r, _list[0]?.plates ? _list[0].plates : _list[0]), _userId).id,
              quantity: 1,
            });

            const _supplements = getSupplements(periodData?.dataOrdersSupplements[formated], _userId, _r);

            _supplements.forEach(_sup => {
              const _foudSup = _obj[_userId].articles.find(_s => _s.id === _sup.id);
              if (_foudSup) {
                _foudSup.quantity += _sup.number;
              } else {
                _obj[_userId].articles.push({
                  id: _sup.id,
                  quantity: _sup.number,
                  name: _sup.name
                })
              }

            });
          }
        });
      });
    }

    //commandes en restaurant INVITE
    if (periodData?.dataGuestOrders[formated]) {
      Object.entries(periodData?.dataGuestOrders[formated]).forEach(([_userId, _content]) => {
        Object.entries(_content).forEach(([_r, _list]) => {
          if (_list.length > 0) {
            let _guest = periodData.guests.find(_f => _f.guestId === _userId);

            if (!_obj[_userId])
              _obj[_userId] = {
                name: _guest?.name ?? "",
                surname: "",
                room: _guest?.linkedTo ? ctx.users[_guest.linkedTo].room : "",
                subscription: "",
                articles: [],
              }

            _obj[_userId].articles.push({
              homeDelivery: "non",
              id: getSKURepas(_r, getFormula(_r, _list[0].plates ? _list[0].plates : _list[0]), _userId).id,
              quantity: 1,
            });

            const _supplements = getSupplements(periodData?.dataGuestOrdersSupplements[formated], _userId, _r);

            _supplements.forEach(_sup => {
              const _foudSup = _obj[_userId].articles.find(_s => _s.id === _sup.id);
              if (_foudSup) {
                _foudSup.quantity += _sup.number;
              } else {
                _obj[_userId].articles.push({
                  id: _sup.id,
                  quantity: _sup.number,
                  name: _sup.name
                })
              }

            });
          }
        });
      });
    }

    const data = [[
      { v: "Nom résident", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
      { v: "Code article", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
      { v: "Quantité", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
      { v: "Portage", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
      // { v: "Prénom", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
      // { v: "Appartement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
      // { v: "Portage", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
      // { v: "Abonnement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
      // { v: "Commande du jour", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
      // { v: "Suppléments", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
    ]];

    Object.entries(_obj).sort(([_, a], [__, b]) => a.name - b.name).sort(([_, a], [__, b]) => a.room - b.room).forEach(([userId, _data]) => {

      _data.articles.forEach((element, _indx) => {
        data.push([
          { v: _indx === 0 ? `${_data.name ?? ""} ${_data.surname ?? ""}` : "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: element.id ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: element.quantity ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: element.homeDelivery ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
        ]);
      });

    });

    // Coloriser une ligne sur deux
    data.forEach((line, linesIndex) => {
      line.forEach((cell, cellIndex) => {
        if (linesIndex % 2 === 0) {
          cell.s = {
            ...cell.s,
            fill: {
              fgColor: {
                rgb: "e6e6e6"
              }
            },
          };
        }
      });
    });

    return { "Export journalier": data };
  };


  const generateExportDataDateToDate = async (period, date) => {
    const start = date.clone().toDate();
    const end = date.clone().add(1, period).toDate();
    const periodData = await ctx.getData(start, end);

    const baseBorder = { top: { style: "thin", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thin", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };

    const contentStyle = { border: baseBorder, font: { sz: 11 } };

    const _data = [
      [{ v: "DATE", s: { font: { sz: 14, bold: true } }, t: "s" }, { v: `du ${moment(start).format("DD/MM/YY")} au ${moment(end).add(-1,"day").format("DD/MM/YY")}`, s: { font: { sz: 11, bold: true } }, t: "s" }],
      [{ v: "code_article", s: contentStyle, t: "s" }, { v: "libelle", s: contentStyle, t: "s" }, { v: "Catégorie", s: contentStyle, t: "s" }, { v: "Quantités", s: contentStyle, t: "s" }],
    ];

    let numDays = moment(end).diff(moment(start), "days");

    const _obj = {};


    for (let i = 1; i <= numDays; i++) {
      let formated = moment(start).add(i, "days").format("YYYY-MM-DD");
      console.log("aytyt");
      // on remplit l'objet avec les données nécessaires pour remplir le tableau :

      //réservations classiques (portage)
      if (periodData?.data[formated]?.reservation) {
        Object.entries(periodData?.data[formated]?.reservation).forEach(([_userId, _content]) => {
          Object.entries(_content).forEach(([_r, _list]) => {
            if (_list.length > 0 && _list[0].homeDelivery === true) {
              // ajout de la résa du résident
              const _sku = getSKURepas(_r, getFormula(_r, _list[0]), _userId);

              if (!_obj[_sku.id]) _obj[_sku.id] = 0;

              _obj[_sku.id] += 1;

              // ajout de ses invités
              if (_list.length > 1) {
                for (let i = 1; i < _list.length; i++) {
                  const _sku = getSKURepas(_r, getFormula(_r, _list[0]), _userId + i);

                  if (!_obj[_sku.id]) _obj[_sku.id] = 0;

                  _obj[_sku.id] += 1;
                }
              }
            }
          });
        });
      }

      //commandes en restaurant
      if (periodData?.dataOrders[formated]) {
        Object.entries(periodData?.dataOrders[formated]).forEach(([_userId, _content]) => {
          Object.entries(_content).forEach(([_r, _list]) => {
            if (_list.length > 0) {
              const _sku = getSKURepas(_r, getFormula(_r, _list[0].plates ? _list[0].plates : _list[0]), _userId);

              if (!_obj[_sku.id]) _obj[_sku.id] = 0;

              _obj[_sku.id] += 1;

              const _supplements = getSupplements(periodData?.dataOrdersSupplements[formated], _userId, _r);

              _supplements.forEach(_sup => {
                console.log("here", _sup)
                if (!_obj[_sup.id]) _obj[_sup.id] = {
                  name: _sup.name,
                  number: 0,
                  category: "",
                };

                _obj[_sup.id].number += _sup.number;

              });
            }
          });
        });
      }

      //commandes en restaurant INVITE
      if (periodData?.dataGuestOrders[formated]) {
        Object.entries(periodData?.dataGuestOrders[formated]).forEach(([_userId, _content]) => {
          Object.entries(_content).forEach(([_r, _list]) => {
            if (_list.length > 0) {
              const _sku = getSKURepas(_r, getFormula(_r, _list[0] ?  _list[0].plates : _list[0]), _userId);

              if (!_obj[_sku.id]) _obj[_sku.id] = 0;

              _obj[_sku.id] += 1;

              const _supplements = getSupplements(periodData?.dataGuestOrdersSupplements[formated], _userId, _r);

              _supplements.forEach(_sup => {

                if (!_obj[_sup.id]) _obj[_sup.id] = {
                  name: _sup.name,
                  category: _sup.category ?? "",
                  number: 0
                };

                _obj[_sup.id].number += _sup.number;

              });
            }
          });
        });
      }
    }

    ["Déjeuner", "Diner", "Supplément"].forEach((_categ, _indx) => {

      _data.push([{ v: _categ, s: { font: { sz: 14, bold: true } } }]);

      sku.filter(_s => _s.category === _categ).forEach(element => {
        _data.push([
          { v: element.id, s: contentStyle, t: "s" },
          { v: element.name, s: contentStyle, t: "s" },
          { v: element.category, s: contentStyle, t: "s" },
          { v: _obj[element.id] ?? 0, s: contentStyle, t: "n" },
        ]);
      });

    });

    // on ajoute les autres suppléments (Boissons / Glaces) :

    _data.push([{ v: "Boissons / Glaces", s: { font: { sz: 14, bold: true } } }]);


    // On rajoute les suppléments customs :

    const others = Object.keys(_obj).filter(_s => !sku.map(_sku => _sku.id).includes(_s));
    console.log(others)
    if (others.length > 0) {

      others.forEach(element => {
        _data.push([
          { v: element, s: contentStyle, t: "s" },
          { v: _obj[element].name, s: contentStyle, t: "s" },
          { v: _obj[element].category?.split("/").map(c => c.trim()).join("|/|") ?? "", s: contentStyle, t: "s" },
          { v: _obj[element].number ?? 0, s: contentStyle, t: "n" }
        ]);
      });
    }


    let generatedData = {
      columnSize: [25, 25, 18, 10],
      data: _data,
    };

    return generatedData;
  };

  if (!ctx || !ctx.users || !ctx.data) return (<div></div>);
  return (
    <>
      <VideoTuto url="https://youtu.be/1P61Re4pRx4" />
      <div className={styles.menu}>
        <div className={styles.weekSelector}>
          <FontAwesomeIcon
            className={styles.icon}
            icon={faArrowLeft}
            onClick={() => {
              changeWeek(-1);
            }}
          />
          <div className={styles.weekText}>
            {ctx?.selectedWeek?.clone().format("DD MMMM YYYY")} - {ctx?.selectedWeek?.clone().endOf("week").format("DD MMMM YYYY")}
          </div>
          <FontAwesomeIcon
            className={styles.icon}
            icon={faArrowRight}
            onClick={() => {
              changeWeek(1);
            }}
          />
        </div>

        <div className={styles.buttonContainer} style={{ marginBottom: -52, marginLeft: 210 }}>
          <Button onClick={() => setIsDropDownOpen(!isDropDownOpen)} style={{ position: "relative", display: "flex", gap: 10 }}>
            Exporter
            <FontAwesomeIcon
              className={styles.angle}
              icon={isDropDownOpen ? faAngleDown : faAngleRight}
            />
            <DropDown isOpen={isDropDownOpen} style={{ top: 38, paddingLeft: 30, paddingRight: 30 }} toggle={() => setIsDropDownOpen(false)}>

              <Button className={styles.exportButton} color="primary" onClick={() => setModalExportOpen3(true)}>
                <span>
                  Export journalier
                </span>
                <Download
                  color="white"
                  size={30}
                />
              </Button>

              <Button className={styles.exportButton} style={{ marginTop: 10 }} color="primary" onClick={() => setModalExportOpen(true)}>
                <span>
                  Export des réservations
                </span>
                <Download
                  color="white"
                  size={30}
                />
              </Button>

              <Button className={styles.exportButton} style={{ marginTop: 10 }} color="primary" onClick={() => setModalExportOpen2(true)}>
                <span>
                  Export restauration
                </span>
                <Download
                  color="white"
                  size={30}
                />
              </Button>


            </DropDown>
          </Button>

          <div className={styles.filterContainer}>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "senior" })} className={`${styles.filterElement} ${ctx?.filter?.role === "senior" ? styles.active : ""}`}>
              Résidents
            </div>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "employee" })} className={`${styles.filterElement} ${ctx?.filter?.role === "employee" ? styles.active : ""}`}>
              Collaborateurs
            </div>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "guest" })} className={`${styles.filterElement} ${ctx?.filter?.role === "guest" ? styles.active : ""}`}>
              Invités
            </div>


          </div>


          <Button style={{ zIndex: 600 }} onClick={() => setShowTotal(!showTotal)}>{!showTotal ? "Afficher les totaux" : "Cacher les totaux"}</Button>
        </div>

        {ctx.filter?.role === "guest" ? <ReservationTableGuest showTotal={showTotal} /> : <ReservationTable showTotal={showTotal} />}

        {/* <ReservationTable showTotal={showTotal} /> */}

        {modalExportOpen ? (
          <ExportDay onClose={onCloseExport} dataGeneration={generateExportData} infos={{ title: "Export des réservations", type: "portage", date: ctx?.selectedWeek }} />
        ) : null}
        {modalExportOpen3 ? (
          <ExportDay onClose={onCloseExport} dataGeneration={generateExportDataRestaurant} infos={{ title: "Export restaurant journalier", type: "portage", date: ctx?.selectedWeek }} />
        ) : null}
        {modalExportOpen2 ? (
          <ExportTable onClose={onCloseExport} dataGeneration={generateExportDataDateToDate} infos={{ title: "Export restaurant", type: "fullControl", date: ctx?.selectedDate }} />
        ) : null}
        {/* {modalExportOpen2 ? (
          <ExportTable onClose={onCloseExport} dataGeneration={generateExportData2} infos={{ title: "Export des Menus", type: "menuDishes", date: ctx?.selectedDate }} />
        ) : null} */}
        {/*<Tab render={render} activeTab={page} onClick={pressHeaderHandler}/>*/}
        {/* <Details data={dataSelected} users={etabUsers} allData={dataMenus} updateData={updateData} selectedDate={selectedWeek}/> */}
      </div>
    </>

  );
};
export default ReservationMenus;
