import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";

import { Tab, Spinner } from "lib/components";

import DateSelector from "lib/Stats/components/dateSelector";

import { Global, Users, Animations, Restauration, Actualites, Gazette } from "lib/Stats/pages";
import EstablishmentSelector from "lib/Stats/components/establishmentSelector";
import useStats, { Provider } from "lib/Stats/statsContext";

const GroupementStatsComponent = () => {
  const { id } = useParams();
  const history = useHistory();
  const [ctx, dispatch, hasFeature] = useStats();
  const [page, setPage] = useState(0);

  if (!ctx.dates) return <></>;

  let tab = [
    { title: "Vue Globale", content: <Global /> },
    { title: "Utilisateurs", content: <Users /> }];

  if (hasFeature("statistics")) {
    if (hasFeature("animationPlanning")) tab.push({ title: "Animations", content: <Animations /> });
    if (hasFeature("menuPlanning")) tab.push({ title: "Restauration", content: <Restauration /> });
    if (hasFeature("news")) tab.push({ title: "Actualités", content: <Actualites /> });
    if (hasFeature("gazette")) tab.push({ title: "Gazettes", content: <Gazette /> });
  }


  return (
    <div style={{ width: "calc(100% + 30px)", height: "calc(100vh - 80px)", margin: -15, padding: 15, backgroundColor: "#F2EBF2", overflow: "auto", position: "relative" }}>

      {ctx.loading &&
        <div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, backgroundColor: "rgba(0,0,0,0.4)", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 2500 }}>
          <Spinner style={{borderColor: "#300438", width: 100, height: 100, borderWidth: 7}}/>
        </div>
      }


      <Tab activeTab={2} onClick={console.log} render={[
        { title: "Informations", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/infos`) },
        { title: "Etablissements", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/establishments`) },
        // {title: "Abonnements", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/abonnements`)},
        {
          title: "Statistiques", content: <>
            <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>

              <DateSelector />
              <EstablishmentSelector />

              {ctx.data1 && ctx.data2 ?
                hasFeature("statistics") ?
                  <Tab activeTab={page}
                    onClick={(_page) => setPage(_page)}
                    render={tab}
                  />
                  :
                  <Global />
                : null}

            </div>
          </>
        },
        { title: "Menu", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/menu`) },
        { title: "Abonnement", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/subscription`) },
        { title: "Abonnement V2", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/subscription2`) },
      ]} />

    </div>
  );
};

const GroupementStats = () => {
  const { id } = useParams();

  return (
    <Provider targetType="groupement" target={id}>
      <GroupementStatsComponent />
    </Provider>
  );
};


export default GroupementStats;
