import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  FormGroup, Button, Select, Container, DatePicker, Row, TimePicker, Checkbox, Input, Card,
  Icon
} from "lib/components";
import { ArrowLeft, ArrowRight, ChevronDown, ChevronRight } from "react-feather";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import { useServiceReservation } from "../../routerContext";
import "react-tippy/dist/tippy.css";
import { toast } from "react-toastify";
import { ToInformChangesModal, LittlePrestataireCard } from "../../routerComponents";
import styles from "../../Basic/Scss/service.module.scss";
import useUI from "hooks/ui.hook";
import DatePickerMultiple from "react-multi-date-picker";

import DatePanel from "react-multi-date-picker/plugins/date_panel"

import { getUsersByEtab } from "services/ressources/user";
import useMicroService from "helpers/microService";

const PlanningModal = (props) => {
  const {
    isOpen,
    eventOpen,
    toggle,
  } = props;
  const [ctx, { addAvailabiliy, updateAvailabiliy, removeAvailabiliy, updatePage }] = useServiceReservation();
  const [ui] = useUI();
  const ms = useMicroService();
  const [filteredPrestatairesList, setFilteredPrestatairesList] = useState([]);
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [place, setPlace] = useState("");
  const [currentPrestataire, setCurrentPrestataire] = useState("");
  const [currentPrestataireID, setCurrentPrestataireId] = useState("");
  const [personnelList, setPersonnelList] = useState([]);
  const [completePersonnelList, setCompletePersonnelList] = useState([]);
  const [listPrestations, setListPrestation] = useState([]);
  const [listPrestationComplete, setListPrestationComplete] = useState([]);
  const [error, setError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resaToHandle, setResaToHandle] = useState(null);
  const [acceptNewDispo, setAcceptNewDispo] = useState(false);
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [isOpen1, setIsOpen1] = useState(true);
  const [multipleDates,setMultipleDates]= useState([]);
  const [week, setWeek] = useState(moment().startOf("week").format("YYYY") + "-W" + moment().format("WW"));
  const recurrenceChoices = ["Jamais", "Tous les jours", "Toutes les semaines", "Toutes les 2 semaines", "Tous les mois","Personnalisé"];
  const [selectedRecurrence, setSelectedRecurrence] = useState("Jamais");
  const [endRecu, setEndRecu] = useState(new Date().toLocaleDateString("fr-CA"));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const formattedWeekOfYear = moment(date).startOf("week").format("YYYY-[W]WW");
    setWeek(formattedWeekOfYear);
  }, [date]);
  //filtrer les recherches
  useEffect(() => {
    const filtered = ctx.prestataires.filter((d) => {
      const matchesSearch = d.name.toLowerCase().includes(search.toLowerCase());
      const matchesCategory = category ? d.serviceRef === category.label : true;
      return matchesSearch && matchesCategory;
    });

    setFilteredPrestatairesList(filtered);
  }, [ctx.prestataires, search, category]);

  const handleWeekChange = (e) => {
    let selectDate = e.target.value;
    let currentDate = new Date(date);
    let selectedEndDate = new Date(selectDate);
    selectedEndDate.setHours(23, 59, 0, 0);
    if (currentDate <= selectedEndDate) {
      const newDate = selectedEndDate.toLocaleDateString("fr-CA");
      //  setWeek(selectDate);
      setEndRecu(newDate);
    }
    else {
      toast.warning(
        "La date de fin de récurrence doit être supérieure à la date actuelle"
      );
    }
  };


  const handleRecurrenceChange = (e) => {
    if (e === "Jamais") {
      setIsRecurrent(false);
      setSelectedRecurrence(e);
    }
    else {
      setIsRecurrent(true);
      setSelectedRecurrence(e);
    }
  };

  const updateListPersonnels = (prestataireChosen) => {
    const selectedPrestataire = ctx.prestataires.find((item) => item.name === prestataireChosen);
    if (selectedPrestataire) {
      if (!eventOpen) setPersonnelList(selectedPrestataire.personnel || []);
      if (eventOpen && eventOpen.title != currentPrestataire) setPersonnelList(selectedPrestataire.personnel || []);
      if (eventOpen && eventOpen.title == currentPrestataire) setPersonnelList(eventOpen.others.personnel ?? []);
      setCompletePersonnelList(selectedPrestataire.personnel || []);
    } else {
      setPersonnelList([]);
      setCompletePersonnelList([]);
    }
  };
  const updateListPrestations = (prestataireChosen) => {
    const selectedPrestataire = ctx.prestataires.find((item) => item.name === prestataireChosen);
    if (selectedPrestataire) {
      const prestationsObj = selectedPrestataire.prestations || {};
      const prestasId = Object.keys(prestationsObj);
      const prestationsNames = prestasId.map((key) => prestationsObj[key].title);
    
      if (!eventOpen) setListPrestation(prestationsNames || []);
      if (eventOpen && eventOpen.title != currentPrestataire) setListPrestation(prestationsNames || []);
      if (eventOpen && eventOpen.title == currentPrestataire) {  
        const prestasChosenId = eventOpen.others.prestationsAvailable;
        const listPrestationsNames = Object.entries(prestationsObj).filter(([id,_p]) => prestasChosenId.includes(id)).map(([id,_p]) => _p.title);
        setListPrestation(listPrestationsNames);}
      setListPrestationComplete(prestationsNames || []);
    } else {
      setListPrestation([]);
      setListPrestationComplete([]);
    }
  };

  useEffect(() => {
    const categories = ctx.availableServices;
    setCategories(categories);
  }, [category, ctx.availableServices]);

  useEffect(() => {
    if (acceptNewDispo) {
      let start = new Date(date.getTime());
      let end = new Date(date.getTime());
      start.setHours(startTime.split("h")[0]);
      start.setMinutes(startTime.split("h")[1]);
      start.setSeconds(0);
      end.setHours(endTime.split("h")[0]);
      end.setMinutes(endTime.split("h")[1]);
      end.setSeconds(0);
      const isComplet = eventOpen.isComplet !== undefined ? eventOpen.isComplet : false;
      updateAvailabiliy(eventOpen.dispoUid, currentPrestataireID, start, end, personnelList, listPrestations, place, isComplet);
      toggle();
      setAcceptNewDispo(false);
    }
  }, [acceptNewDispo]);

  useEffect(() => {
    if (!eventOpen && isOpen) { // si t'as rien préselectionner et que c ouvert
      setIsOpen1(true);
      setCurrentPrestataire("");
      setError({});
      setCurrentPrestataireId("");
      setPersonnelList([]);
      setCompletePersonnelList([]);
      setListPrestation([]);
      setListPrestationComplete([]);
      setPlace("");
      setDate(new Date());
      setStartTime("");
      setEndTime("");
      setIsRecurrent(false);
      setSelectedRecurrence("Jamais");
      setEndRecu(new Date().toLocaleDateString("fr-CA"));
      return;
    } else if (eventOpen && isOpen) {
      setIsOpen1(false);
      setError({});
      setCurrentPrestataireId(eventOpen.others.prestataireId);
      setPlace(eventOpen.others.place ?? "");
      setDate(eventOpen.start);
      const currentpresta = ctx.prestataires.find((item) => item.name === eventOpen.title).name;
      setCurrentPrestataire(currentpresta);
      setPersonnelList(eventOpen.others.personnel ?? []);
      const selectedPrestataire = ctx.prestataires.find((item) => item.name === currentpresta);
      const prestationsObj = selectedPrestataire.prestations || {};
      const prestasId = eventOpen.others.prestationsAvailable;
      const listPrestationsNames = Object.entries(prestationsObj)
                                      .filter(([id,_p]) => prestasId.includes(id))
                                      .map(([id,_p]) => _p.title);
      setListPrestation(listPrestationsNames);
      let eventStartHour = eventOpen.start.getHours();
      let eventStartMin = eventOpen.start.getMinutes();
      let eventEndHour = eventOpen.end.getHours();
      let eventEndMin = eventOpen.end.getMinutes();
      setStartTime(
        `${eventStartHour <= 9 ? "0" : ""}${eventStartHour}h${eventStartMin <= 9 ? "0" : ""
        }${eventStartMin}`);
      setEndTime(
        `${eventEndHour <= 9 ? "0" : ""}${eventEndHour}h${eventEndMin <= 9 ? "0" : ""
        }${eventEndMin}`
      );
    }
  }, [isOpen]);


  useEffect(() => {
    if (currentPrestataire) {
      updateListPersonnels(currentPrestataire);
      updateListPrestations(currentPrestataire);
      setCurrentPrestataireId(ctx.prestataires.find((item) => item.name === currentPrestataire).uid);
    }
  }, [currentPrestataire]);



  const hasPersonnelListChanged = () => {
    const listModify = personnelList.slice().sort();
    const liste2Event = eventOpen.others.personnel.slice().sort();
    const elementSupprimer = liste2Event.filter(element => !listModify.includes(element));
    return elementSupprimer;
  };

  const hasPrestationsListChanged = () => {
    const selectedPrestataire = ctx.prestataires.find((item) => item.name === currentPrestataire);
    const prestationsObj = selectedPrestataire.prestations || {};
    const prestasId = eventOpen.others.prestationsAvailable;
    const listPrestationsNames = Object.entries(prestationsObj)
                                    .filter(([id,_p]) => prestasId.includes(id))
                                    .map(([id,_p]) => _p.title);
    const liste2Event = listPrestationsNames.slice().sort();
    const listModify = listPrestations.slice().sort();
    const elementSupprimer = liste2Event.filter(element => !listModify.includes(element));
    return elementSupprimer;
  };


  const resaToInform = (start, end) => {

    const personnelSuppr = hasPersonnelListChanged();
    const prestationsSuppr = hasPrestationsListChanged();
    let horaireStart;
    let horaireEnd;
    if (start.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit", hour12: false }) > eventOpen.start.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit", hour12: false })) horaireStart = start;
    if (end.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit", hour12: false }) < eventOpen.end.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit", hour12: false })) horaireEnd = end;
    const resaToInformList = eventOpen.reservation.filter((resa) => {

      const prestationsTitles = ctx.prestataires.flatMap(item => {
        return resa.prestationId.map(id => item.prestations[id]?.title).filter(Boolean);
      }); // la liste des prestation d'un resa

      const reasons = {};
      if (horaireStart && horaireStart.getTime() > resa.start.getTime()) reasons.start = horaireStart;
      if (horaireEnd && horaireEnd.getTime() < resa.end.getTime()) reasons.end = horaireEnd;
      if (resa.personnelByDefault != "unknown" && personnelSuppr.includes(resa.personnelByDefault)) reasons.personnel = resa.personnelByDefault;
      if (prestationsTitles && prestationsTitles.some(personne => prestationsSuppr.includes(personne))) {
        const filteredPrestations = prestationsTitles.filter(prestation => prestationsSuppr.includes(prestation));
        reasons.prestations = filteredPrestations;
      }
      if (Object.keys(reasons).length > 0) {
        resa.reasons = reasons;
        return true;
      }
      return false;
    });
    return resaToInformList;
  };

  const _onSubmit = async () => {
    setLoading(true);
    let _error = {};
    let split1 = week.split("-W");
    let _date = moment().year(split1[0]).week(parseInt(split1[1])).endOf("week");
    let startRecu = new Date(date);
    let selectedEndDate = new Date(endRecu);
    selectedEndDate.setHours(23, 59, 59, 0);
    if (!eventOpen && isRecurrent && startRecu >= selectedEndDate) { _error.recurrent = "La date de récurrence doit être supérieure à la date sélectionnée"; }
    if (!date) { _error.date = "Ce champ est obligatoire"; }
    if (!startTime) { _error.heure = "Ce champ est obligatoire"; }
    if (!endTime) { _error.heure = "Ce champ est obligatoire"; }
    if (listPrestations.length == 0) { _error.listprestations = "Veuillez selectionner au moins une prestation"; }
    if (personnelList.length <= 0 && completePersonnelList.length > 0) { _error.listpersonnel = "Veuillez selectionner au moins un membre du personnel présent"; }
    if (!currentPrestataire) { _error.prestataire = "Ce champ est obligatoire"; }
    try {
      setError(_error);
      if (Object.keys(_error).length > 0) {
        toast.warning("Inscription incomplète. N'a pas abouti");
        setLoading(false);
        return;
      }
      //mettre la listPrestations et personnel avec les id.
      const selectedPrestataire = ctx.prestataires.find((item) => item.name === currentPrestataire);
      const prestationsObj = selectedPrestataire.prestations || {};
      const prestasId = Object.keys(prestationsObj);
      const listPrestationsId = Object.entries(prestationsObj)
                                      .filter(([id, prestation]) => listPrestations.includes(prestation.title))
                                      .map(([id]) => id);

      let start = new Date(date.getTime());
      let end = new Date(date.getTime());
      start.setHours(startTime.split("h")[0]);
      start.setMinutes(startTime.split("h")[1]);
      start.setSeconds(0);
      end.setHours(endTime.split("h")[0]);
      end.setMinutes(endTime.split("h")[1]);
      end.setSeconds(0);
      if (start.getTime() >= end.getTime()) return toast.warning("L'heure de fin doit être supérieure à l'heure de début");
      if (!eventOpen) {
        if (isRecurrent && endRecu) {
          if (selectedRecurrence === "Tous les jours") {
            let nbEvent = 0;
            if (moment(date).isSame(moment(endRecu), "day")) {
              nbEvent = 0;
            }
            else nbEvent = moment(endRecu).diff(moment(date), "days") + 1;
            let _date = moment(date);
            for (let i = 0; i <= nbEvent; i++) {
              _date = moment(date).add(i, "days");
              const dateToAdd = _date.toDate();
              const newStartDate = new Date(dateToAdd.getFullYear(), dateToAdd.getMonth(), dateToAdd.getDate(), start.getHours(), start.getMinutes());
              const newEndDate = new Date(dateToAdd.getFullYear(), dateToAdd.getMonth(), dateToAdd.getDate(), end.getHours(), end.getMinutes());
              addAvailabiliy(currentPrestataireID, newStartDate, newEndDate, personnelList, listPrestationsId, place, false);
            }
          }
          else if (selectedRecurrence === "Toutes les semaines") {
            let nbEvent = moment(endRecu).diff(moment(date), "weeks");
            if (moment(date).format("dddd") === moment(endRecu).format("dddd") && moment(endRecu).isAfter(moment(date))) nbEvent = nbEvent + 1;
            for (let i = 0; i <= nbEvent; i++) {
              let _date = moment(date).add(i, "weeks");
              const dateToAdd = _date.toDate();
              const newStartDate = new Date(dateToAdd.getFullYear(), dateToAdd.getMonth(), dateToAdd.getDate(), start.getHours(), start.getMinutes());
              const newEndDate = new Date(dateToAdd.getFullYear(), dateToAdd.getMonth(), dateToAdd.getDate(), end.getHours(), end.getMinutes());
              addAvailabiliy(currentPrestataireID, newStartDate, newEndDate, personnelList, listPrestationsId, place, false);
            }
          }
          else if (selectedRecurrence === "Toutes les 2 semaines") {
            let nbEvent = moment(endRecu).diff(moment(date), "weeks");
            if (moment(date).format("dddd") === moment(endRecu).format("dddd") && moment(endRecu).isAfter(moment(date))) nbEvent = nbEvent + 1;
            for (let i = 0; i <= nbEvent; i += 2) {
              let _date = moment(date).add(i, "weeks");
              const dateToAdd = _date.toDate();
              const newStartDate = new Date(dateToAdd.getFullYear(), dateToAdd.getMonth(), dateToAdd.getDate(), start.getHours(), start.getMinutes());
              const newEndDate = new Date(dateToAdd.getFullYear(), dateToAdd.getMonth(), dateToAdd.getDate(), end.getHours(), end.getMinutes());
              addAvailabiliy(currentPrestataireID, newStartDate, newEndDate, personnelList, listPrestationsId, place, false);
            }
          }
          else if (selectedRecurrence === "Tous les mois") {
            let nbEvent = moment(endRecu).diff(moment(date), "months");
            if (moment(date).date() === moment(endRecu).date() && moment(endRecu).isAfter(moment(date))) { nbEvent = nbEvent + 1; }
            for (let i = 0; i <= nbEvent; i++) {
              let _date = moment(date).add(i, "months");
              const dateToAdd = _date.toDate();
              const newStartDate = new Date(dateToAdd.getFullYear(), dateToAdd.getMonth(), dateToAdd.getDate(), start.getHours(), start.getMinutes());
              const newEndDate = new Date(dateToAdd.getFullYear(), dateToAdd.getMonth(), dateToAdd.getDate(), end.getHours(), end.getMinutes());
              addAvailabiliy(currentPrestataireID, newStartDate, newEndDate, personnelList, listPrestationsId, place, false);
            }
          }else if(selectedRecurrence === "Personnalisé"){
            for (let index = 0; index < multipleDates.length; index++) {
              const dateToAdd = multipleDates[index].toDate();
              const newStartDate = new Date(dateToAdd.getFullYear(), dateToAdd.getMonth(), dateToAdd.getDate(), start.getHours(), start.getMinutes());
              const newEndDate = new Date(dateToAdd.getFullYear(), dateToAdd.getMonth(), dateToAdd.getDate(), end.getHours(), end.getMinutes());
              addAvailabiliy(currentPrestataireID, newStartDate, newEndDate, personnelList, listPrestationsId, place, false);
            }
          }    
        } else {
          addAvailabiliy(currentPrestataireID, start, end, personnelList, listPrestationsId, place, false);
        }

        toggle();
        setLoading(false);

      } else {
        const isComplet = eventOpen.isComplet !== undefined ? eventOpen.isComplet : false;
        //si le prestataire ou le jour a été modifié alors on supprime et on récrée une nouvelle dispo
        if (eventOpen.title != currentPrestataire || eventOpen.start.toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit" }) != date.toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit" })) {
          if (eventOpen.title != currentPrestataire) {
            if (!window.confirm("Vous avez modifié le prestataire pour cette disponibilité, Toutes les réservations déjà effectués vont être supprimés")) return;
          } else if (eventOpen.start.toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit" }) != date.toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit" })) {
            if (!window.confirm("Vous avez modifié le jour pour cette disponibilité, Toutes les réservations déjà effectués vont être supprimés")) return;
          }
          removeAvailabiliy(eventOpen.dispoUid);
          addAvailabiliy(currentPrestataireID, start, end, personnelList, listPrestationsId, place, false);
        }

        else if (eventOpen.others.place != place) {
          updateAvailabiliy(eventOpen.dispoUid, currentPrestataireID, start, end, personnelList, listPrestationsId, place, isComplet);
        }
        else {
          //on récupère les réservations qui vont être impacté de ces changements
          const listResaASuppr = resaToInform(start, end);
          if (listResaASuppr.length > 0) { setIsModalVisible(true); setResaToHandle(listResaASuppr); }
          if (!listResaASuppr.length > 0) {
            updateAvailabiliy(eventOpen.dispoUid, currentPrestataireID, start, end, personnelList, listPrestationsId, place, isComplet);
            toggle();
          }
        }
      }
      // await maybeSendNotif(start, end);
      setLoading(false);
      
    } catch (e) {
      console.error(e);
      setLoading(false);
      toast.error("Une erreur est survenue");
    }
  };

  // const maybeSendNotif = async (start, end) => {
  //   //notif pour l'ajout
  //   if (!eventOpen) {
  //     let users = await getUsersByEtab({ etabId: ui.user.establishment }, () => { throw new Error("Une erreur est survenue"); });
  //     users = users.filter(u => u.role === "senior");
  //     users = users.map(u => u.uid);
  //     await ms("sendPushToUserById", {
  //       userId: users,
  //       title: "Nouveau créneau de service disponible, Rendez-vous à l'accueil",
  //       body: `${currentPrestataire}. Le ${start.toLocaleDateString("fr-FR")} de ${start.toLocaleTimeString("fr-FR", { hour: "numeric", minute: "numeric" })} à ${end.toLocaleTimeString("fr-FR", { hour: "numeric", minute: "numeric" })}.`,
        // type:"service",
        // etabId:ui.user.establishment,
  //     });
  //   }
  //   //notif pour la modification
  //   else {
  //     //le prestataire à changé => on envoie la notif
  //     if (eventOpen.title != currentPrestataire) {
  //       let users = await getUsersByEtab({ etabId: ui.user.establishment }, () => { throw new Error("Une erreur est survenue"); });
  //       users = users.filter(u => u.role === "senior");
  //       users = users.map(u => u.uid);
  //       await ms("sendPushToUserById", {
  //         userId: users,
  //         title: `Modification d'un créneau pour ${currentPrestataire}, Rendez-vous à l'accueil`,
  //         body: `Le prestataire présent sur le créneau a changé, maintenant c'est ${currentPrestataire}.`,
        // type:"service",
        // etabId:ui.user.establishment,
  //       });
  //       return;
  //     }

  //     let compteurModif = 0;
  //     let bodyDisplay = "";
  //     //le jour à changé
  //     if (eventOpen.start.toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit" }) != date.toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit" })) {
  //       compteurModif = compteurModif + 1;
  //       bodyDisplay = `Le jour est passé du ${eventOpen.start.toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit" })} au ${date.toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit" })}`;
  //     }

  //     //les prestations disponible ont changé

  //     if (eventOpen.others.prestationsAvailable.length !== listPrestations.length) {
  //       compteurModif = compteurModif + 1;
  //       if (compteurModif > 1) {
  //         bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
  //       } else {
  //         bodyDisplay = "La liste des prestations disponibles a changé";
  //       }
  //     }
  //     else {
    // const selectedPrestataire = ctx.prestataires.find((item) => item.name === currentPrestataire);
    //   const prestationsObj = selectedPrestataire.prestations || {};
    //   const prestasId = eventOpen.others.prestationsAvailable;
    //   const listPrestationsNames = Object.entries(prestationsObj)
    //                                   .filter(([id,_p]) => prestasId.includes(id))
    //                                   .map(([id,_p]) => _p.title);
  
  //       for (let i = 0; i < listPrestationsNames.length; i++) {
  //         if (!listPrestations.includes(listPrestationsNames[i]) || !listPrestationsNames.includes(listPrestations[i])) {
  //           compteurModif = compteurModif + 1;
  //           if (compteurModif > 1) {
  //             bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
  //           } else {
  //             bodyDisplay = "La liste des prestations disponibles a changé";
  //           }
  //           break;
  //         }
  //       }
  //     }

  //     //la liste du personnel présent à changé
  //     if (eventOpen.others.personnel.length !== personnelList.length) {
  //       compteurModif = compteurModif + 1;
  //       if (compteurModif > 1) {
  //         bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
  //       }
  //       else {
  //         bodyDisplay = "La liste du personnel présent à changé";
  //       }
  //     }
  //     else {
  //       for (let i = 0; i < eventOpen.others.personnel.length; i++) {
  //         if (!personnelList.includes(eventOpen.others.personnel[i]) || !eventOpen.others.personnel.includes(personnelList[i])) {
  //           compteurModif = compteurModif + 1;
  //           if (compteurModif > 1) {
  //             bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
  //           }
  //           else {
  //             bodyDisplay = "La liste du personnel présent à changé";
  //           }
  //           break;
  //         }
  //       }
  //     }
  //     //l'horaire de début/fin
  //     let start = new Date(date.getTime());
  //     let end = new Date(date.getTime());
  //     start.setHours(startTime.split("h")[0]);
  //     start.setMinutes(startTime.split("h")[1]);
  //     start.setSeconds(0);
  //     end.setHours(endTime.split("h")[0]);
  //     end.setMinutes(endTime.split("h")[1]);
  //     end.setSeconds(0);
  //     if (eventOpen.others.start.getTime() !== start.getTime() || eventOpen.others.end.getTime() !== end.getTime()) {
  //       compteurModif = compteurModif + 1;
  //       if (compteurModif > 1) {
  //         bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
  //       }
  //       else {
  //         bodyDisplay = `le nouvel horaire est de ${start.toLocaleTimeString("fr-FR", { hour: "numeric", minute: "numeric" })} à ${end.toLocaleTimeString("fr-FR", { hour: "numeric", minute: "numeric" })}`;
  //       }

  //     }

  //     //envoi de la notif
  //     if (compteurModif > 0) {
  //       compteurModif=0;
  //       let users = await getUsersByEtab({ etabId: ui.user.establishment }, () => { throw new Error("Une erreur est survenue"); });
  //       users = users.filter(u => u.role === "senior");
  //       users = users.map(u => u.uid);
  //       await ms("sendPushToUserById", {
  //         userId: users,
  //         title: `Modification d'un créneau pour ${currentPrestataire}, Rendez-vous à l'accueil`,
  //         body: `${bodyDisplay}.`,
        // type:"service",
        // etabId:ui.user.establishment,
  //       });
  //     }

  //   }


  // };



  const _onDelete = async () => {
    try {
      if (!window.confirm("Etes-vous sur de vouloir supprimer cette disponibilité? Cela entrainera la suppression de toutes les réservations qui lui sont liées")) return;
      removeAvailabiliy(eventOpen.others.uid);
        let users = await getUsersByEtab({ etabId: ui.user.establishment }, () => { throw new Error("Une erreur est survenue"); });
        users = users.filter(u => u.role === "senior");
        users = users.map(u => u.uid);
        await ms("sendPushToUserById", {
          userId: users,
          title: `Suppression d'un créneau pour ${eventOpen.title}, Rendez-vous à l'accueil`,
          body: `de ${startTime} à ${endTime}.`,
          type:"service",
          etabId:ui.user.establishment,
        });
      toggle();
    } catch (e) {
      toggle();
    }
  };

  const _returnModal = () => {
    setIsModalVisible(false);
    setResaToHandle(null);
  };


  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle} >
      <ModalHeader>{eventOpen ? "Modification du créneau" : "Ajout d'un nouveau créneau"}</ModalHeader>
      <ModalBody style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <Container>
          <div style={{ fontSize: "20px", color: "purple", fontWeight: "bold" }}>1. Choix du Prestataire</div>

          <Card style={{ padding: "0px" }}>
            <div onClick={() => setIsOpen1(!isOpen1)} style={{ display: "flex", borderBottom: isOpen1 ? "1px solid #A0A0A0" : "", padding: "0px" }} >
              <Row style={{ margin: "10px", display: "flex", width: "100%", justifyContent: "space-between" }}>
                {!currentPrestataire ? <div style={{ fontSize: "14px", color: "#505050" }}>
                  {"Pas de prestataire choisi"}
                </div> : <span style={{ fontSize: "18px", color: "#707070" }}>{currentPrestataire}</span>}
                {isOpen1 ?
                  <ChevronDown style={{ cursor: "pointer", color: "#707070" }} /> : <ChevronRight style={{ cursor: "pointer", color: "#707070" }} />
                }
              </Row>
            </div>
            {isOpen1 && ctx.prestataires.length > 0 ?
              <>
                <div className={styles.rechercheContainer} style={{ marginTop: "10px" }}>
                  <div className={styles.recherche} style={{ fontSize: "13px" }}>
                    Recherche : <Input value={search} style={{ fontSize: "13px" }} placeholder="Nom du prestataire..." onChange={(e) => setSearch(e.target.value)} />
                  </div>
                  <div style={{ fontSize: "13px" }}  >
                    <Select
                      style={{ fontSize: "13px" }}
                      placeholder="Catégorie..."
                      clearable
                      options={categories.map((category) => ({ label: category.name, value: category.uid }))}
                      value={category}
                      onChange={(e) => setCategory(e ?? null)}
                    />
                  </div>
                </div>
                <div className={styles.bodyContainer} style={{ marginTop: "15px", maxHeight: "300px", overflowY: "auto", padding: "10px", paddignTop: "0px" }}>
                  {filteredPrestatairesList.map((_data, index) => (
                    <LittlePrestataireCard data={_data} key={index}
                      onClickResa={() => { setCurrentPrestataire(_data.name); setIsOpen1(false); }}
                    />
                  ))}
                </div>
              </>
              : ctx.prestataires.length <= 0 ?
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 10 }}>
                  <div style={{ fontStyle: "italic", textAlign: "center", fontSize: "20px", color: "#505050" }}>
                    {"Vous n'avez créé aucun prestataire. Veuillez en créer un avant d'ajouter des disponibilités."}
                  </div>
                  <Button style={{ paddingRight: 10 }} onClick={() => { updatePage(1); toggle(); }}>
                    Aller créer un premier prestataire
                    <Icon icon={ArrowRight} color="light"></Icon>
                  </Button>
                </div>

                : null}


          </Card>
          {/* <FormGroup>
            <Select
              invalid={error.prestataire}
              label="Choix du prestataire"
              placeholder="Aucun"
              options={ctx.prestataires.map((presta) => ({ label: presta.name, value: presta.name }))}
              onChange={(e) => {setCurrentPrestataire(e.value ?? null);}}
              value={{label:currentPrestataire,value:currentPrestataire}}
            />
          </FormGroup>  */}
          <div style={{ fontSize: "20px", color: "purple", fontWeight: "bold" }}>2. Gestion du nouveau créneau</div>
          {listPrestationComplete.length > 0 ? <FormGroup>
            <label htmlFor="prestationsList" style={{ marginRight: "10px" }}>
              Gérer les prestations disponibles:
            </label>
            <CreatableSelect
              label="test"
              isMulti
              isValidNewOption={() => false}
              promptTextCreator={() => false}
              placeholder="Aucune prestations n'est sélectionné"
              value={listPrestations != [] ? listPrestations.map(name => ({ label: name, value: name })) : []}
              options={listPrestationComplete.map((person) => ({ label: person, value: person }))}
              onChange={(newValue, actionMeta) => {
                if (actionMeta.action === "remove-value") { // si on retire une personne de la liste
                  const removedPerson = actionMeta.removedValue.value;
                  setListPrestation((prev) => prev.filter((person) => person !== removedPerson));
                }
                else if (actionMeta.action === "select-option") {
                  newValue.forEach(selectedOption => {
                    if (!listPrestations.includes(selectedOption.value)) {
                      setListPrestation(prev => [...prev, selectedOption.value]);
                    }
                  });
                }
              }}
            />
            {error.listprestations ? <div style={{ color: "red" }}>Veuillez selectionner au moins une prestation</div> : null}
          </FormGroup> : null}
          {completePersonnelList.length > 0 ? <FormGroup>
            <label htmlFor="personnelList" style={{ marginRight: "10px" }}>
              Gérer le personnel présent:
            </label>
            <CreatableSelect
              label="test"
              isMulti
              isValidNewOption={() => false}
              promptTextCreator={() => false}
              placeholder="Aucun membre du personnel n'est présent"
              value={personnelList != [] ? personnelList.map(name => ({ label: name, value: name })) : []}
              options={completePersonnelList.map((person) => ({ label: person, value: person }))}
              onChange={(newValue, actionMeta) => {
                if (actionMeta.action === "remove-value") {
                  const removedPerson = actionMeta.removedValue.value;
                  setPersonnelList((prev) => prev.filter((person) => person !== removedPerson));
                }
                else if (actionMeta.action === "select-option") {
                  newValue.forEach(selectedOption => {
                    if (!personnelList.includes(selectedOption.value)) {
                      setPersonnelList(prev => [...prev, selectedOption.value]);
                    }
                  });
                }
              }}
            />
            {error.listpersonnel ? <div style={{ color: "red" }}>Veuillez selectionner au moins un membre du personnel</div> : null}

          </FormGroup> : null}
          <FormGroup>
            <Input
              theme="grey"
              label={"Lieu"}
              onChange={(e) => setPlace(e.target.value)}
              value={place}
            />
          </FormGroup>
          <FormGroup
            style={{
              display: "flex",
              justifyContent: "space-between",
              columnGap: 4,
            }}
          >
            <div style={{ flex: 1 }}>
              <DatePicker
                invalid={error.date}
                label={"Jour"}
                value={date}
                onChange={setDate}
                theme="grey"
              />
            </div>
            <div style={{ flex: 1 }}>
              <TimePicker
                invalid={error.heure}
                label={"Heure de début"}
                value={{ label: startTime, value: startTime }}
                onChange={(e) => setStartTime(e ? e.label : null)}
                theme="grey"
              />
            </div>
            <div style={{ flex: 1 }}>
              <TimePicker
                invalid={error.heure}
                label={"Heure de fin"}
                value={{ label: endTime, value: endTime }}
                onChange={(e) => setEndTime(e ? e.label : null)}
                theme="grey"
              />
            </div>
          </FormGroup>
          {!eventOpen ?
            <FormGroup>
              <div style={{ marginBottom: "10px" }}>
                <Select
                  label={"Définir une récurrence (à partir du " + moment(date).date() + " " + moment(date).format("MMMM") + ")"}
                  options={recurrenceChoices.map(choice => ({ label: choice, value: choice }))}
                  containerStyle={{ flex: 1 }}
                  value={{ value: selectedRecurrence, label: selectedRecurrence }}
                  onChange={(e) => handleRecurrenceChange(e.value)}
                  theme="grey"
                />
              </div>
              {isRecurrent ? (
                <>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  {selectedRecurrence === "Toutes les semaines" ?
                    <span>
                      Tous les {moment(date).format("dddd")}
                      {startTime && endTime
                        ? " de " + startTime + " à " + endTime
                        : ""}{" "}
                      jusqu&apos;au (inclus) :
                    </span>
                    : selectedRecurrence === "Tous les jours" ?
                      <span>
                        {startTime && endTime
                          ? " De " + startTime + " à " + endTime
                          : ""}{" "}
                        jusqu&apos;au (inclus) :
                      </span> : selectedRecurrence === "Toutes les 2 semaines" ?
                        <span>
                          Tous les 2 {moment(date).format("dddd")}
                          {startTime && endTime
                            ? " de " + startTime + " à " + endTime
                            : ""}{" "}
                          jusqu&apos;au (inclus) :
                        </span>
                        : selectedRecurrence === "Tous les mois"?
                        <span>
                          Tous les {moment(date).date()} du mois
                          {startTime && endTime
                            ? " de " + startTime + " à " + endTime
                            : ""}{" "}
                          jusqu&apos;au (inclus) :
                        </span>:
                          <div>Choisissez les jours ou vous souhaitez ajouter votre animation:</div>
                  }

                 {selectedRecurrence !=="Personnalisé"? <input
                    type="date"
                    value={endRecu}
                    className="form-control"
                    id="Week"
                    onChange={handleWeekChange}
                  />: 
                  <div style={{width: "100%" }}>
                    
                        <DatePickerMultiple
                          style={{ width: "30vw",height:"40px" }}
                          placeholder="choisissez vos dates..."
                          multiple
                          value={multipleDates}
                          onChange={setMultipleDates}
                          plugins={[<DatePanel />]}
                        />
                    
                    </div>
                  }
                  {error.recurrent ? <div style={{ fontSize: "15px", color: "red" }}>{error.recurrent}</div> : null}


                </>
              ) : null}
            </FormGroup>


            : null}



          {/* <FormGroup>
                      <div style={{ flex: 1 }}>
                            <Checkbox
                              theme="grey"
                              label={"Evenement récurrent"}
                              onChange={() => setIsRecurrent(!isRecurrent)}
                              checked={isRecurrent}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            {isRecurrent ? (
                              <>
                                <span>
                                  Tous les {moment(date).format("dddd")}
                                  {startTime && endTime
                                    ? " de " + startTime + " à " + endTime
                                    : ""}{" "}
                                  jusqu&apos;à :
                                </span>
                                <Input
                                  type="week"
                                  value={week}
                                  id="Week"
                                  onChange={handleWeekChange}
                                  invalid={error.recurrent}
                                />
                              </>
                            ) : null}
                          </div>
                      </FormGroup>:null} */}
        </Container>
      </ModalBody>

      <ModalFooter style={{ justifyContent: "center", gap: "5px" }}>
        <Button color='secondary' onClick={toggle}>Annuler</Button>
        {eventOpen ? <Button color='danger' disabled={loading} loading={loading} onClick={_onDelete} >Supprimer</Button> : null}
        <Button color='primary' disabled={loading} loading={loading} onClick={_onSubmit}>{eventOpen ? "Modifier" : "Valider"}</Button>
      </ModalFooter>
      <ToInformChangesModal isOpen={isModalVisible} data={resaToHandle} toggle={_returnModal} availabilityUid={eventOpen ? eventOpen.others.uid : null} isDone={(item) => setAcceptNewDispo(item)} />
    </Modal>
  );

};

PlanningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  eventOpen: PropTypes.object,
};

export default PlanningModal;