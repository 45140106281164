import React from "react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Card, Button, Spinner } from "../../../../../lib/components";
import sleep from "../../../../../helpers/asyncWait";
import useUI from "hooks/ui.hook";
import { ExportMonth } from "../../router_pdf";
import { getAllAnimationByEtab } from "services/ressources/animations";
import moment from "moment";

const PlanningMonthExport = () => {
  const [ui] = useUI();
  const history = useHistory();
  const location = useLocation();
  const params = location.state;
  const dlButton = useRef(null);
  const [counter, setCounter] = useState(5);
  const [redirect, setRedirect] = useState(false);
  const [events, setEvents] = useState(null);


  const getData = async (start, end) => {
    const _events = await getAllAnimationByEtab(
      {
        id: ui.establishment.uid,
        start,
        end
      }
    );

    setEvents(_events.map(_e => ({ ..._e, start: _e.start.toDate(), end: _e.end.toDate() })));
  };

  useEffect(() => {
    if (ui && params && params.monday) {
      const start = moment(params.monday).startOf("month").toDate();
      const end = moment(start).endOf("month").toDate();
      getData(start, end);
    }

  }, [params, ui]);

  useEffect(() => {
    if (events) {
      (async () => {
        await sleep(2000);
        if (dlButton.current) {
          dlButton.current.click();
          setRedirect(true);
        }
      })();
    }

  }, [events]);

  useEffect(() => {
    if (!redirect) return;

    let interval = setInterval(() => {
      setCounter(counter - 1);
      if (counter === 0) {
        history.push("/dashboard/animations");
        clearInterval(interval);
      }
    }, 1000);
  }, [redirect, counter]);

  if (!events) return <Spinner />;


  return (
    <Card style={{ width: "100%", minHeight: 800 }}>
      <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Button color="secondary" onClick={() => history.goBack()} style={{ marginRight: 5 }}>Retour</Button>
        <p style={{ marginTop: 10 }}>
          Votre fichier est en cours de téléchargement.
        </p>
        <p>
          <span>Si le téléchargement ne commence pas automatiquement </span>
          <PDFDownloadLink
            document={<ExportMonth
              ui={ui}
              firstDay={moment(params.monday).startOf("month").toDate()}
              events={events}
              logo={params.logo}
              additionnalData={JSON.parse(params.additionnalData)}
              categories={params.categories}
            />}
            fileName="planning.pdf">
            {({ /*blob, url,*/ loading/*, error */ }) => (loading ? "Loading document..." :
              <>
                <span ref={dlButton}>Cliquez ici</span>
              </>
            )}
          </PDFDownloadLink>
        </p>
      </div>
      {/* <ExportMonth 
            ui={ui}
            firstDay={moment(params.monday).startOf("month").toDate()} 
            events={events}
            logo={params.logo}
            additionnalData={JSON.parse(params.additionnalData)}
          /> */}
    </Card>
  );
};

export default PlanningMonthExport;