import React, { useState } from "react";
import useCtx, { Provider } from "./ctx";
import { Tab, Button, Modal, ModalHeader, ModalFooter, ModalBody, Select } from "lib/components";
import { useHistory, useParams } from "react-router-dom";
import firebase from "firebase";

import SubscriptionComponent from "./SubscriptionComponent";
import SubscriptionDetails from "./SubscriptionDetails";


const firestore = firebase.firestore;

const Subscription = () => {
  const history = useHistory();
  const { id } = useParams();
  const [ctx] = useCtx();

  const applyToAll = (e) => {

    if (confirm("Cette action va modifier le template d'abonnement de tous les établissements du groupement.")) {
      ctx.pushToAllEtab();
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Tab activeTab={4} render={[
        { title: "Informations", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/infos`) },
        { title: "Etablissements", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/establishments`) },
        // {title: "Abonnements", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/abonnements`)},
        { title: "Statistiques", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/statistics`) },
        { title: "Menu", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/menu`) },
        {
          title: "Abonnement", content: <div>
            {ctx.step === 0 ? <SubscriptionComponent />
              : ctx.step === 1 ? <SubscriptionDetails />
                : null}
          </div>, overrideOnClick: () => history.push(`/dashboard/establishments/${id}/subscription`)
        },
        { title: "Abonnement V2", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/subscription2`) },
      ]} />
      <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "50%", marginLeft: "25%", paddingBottom: 50 }}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <Button onClick={ctx.save}>Enregistrer</Button>
        <Button onClick={applyToAll}>Appliquer à tous les établissements</Button>
      </div>
    </div>
  );
};

const MenuHoc = () => {
  const { id } = useParams();

  return (
    <Provider establishmentId={id}>
      <Subscription />
    </Provider>
  );
};
export default MenuHoc;