import React from "react";
import {Provider} from "./router_context";
import { EditorInterface } from "./router_page";

const Menu = () => {
  return (
    <Provider>
      <EditorInterface />
    </Provider>
  );
};

export default Menu;
