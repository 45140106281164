import React, { useState } from "react";
import PropTypes from "prop-types";
import { NumberVariations, SimpleStatComponent } from "../components";
import Card from "lib/components/Stats/Card";
import { PieChart } from "../components";
import useStats from "lib/Stats/statsContext";
import { faUtensils, faPoll } from "@fortawesome/free-solid-svg-icons";
import SatisfactionCard from "../components/SatisfactionCard";
import { percent } from "../components/operations";
import StarRating from "../components/StarRating";
import SatisfactionPlot from "../components/StatisfactionPlot";
import NumberDisplay from "lib/components/Stats/NumberDisplay2";

const infos = {
  repas: {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre de repas renseignés sur la période sélectionnée. Par exemple si jamais il y a un Déjeuner et un Diner de renseigné, cela compte comme 2 repas renseignés. \n"}
      <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre de repas renseignés entre cette période et la période précédente. \n"}
    </div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total de repas renseignés sur la période sélectionnée. Par exemple si jamais il y a un Déjeuner et un Diner de renseigné, cela compte comme 2 repas renseignés. \n"}
      <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre de repas renseignés entre cette période et la période précédente. \n"}
      <b>{"3) Moyenne:"}</b> {" Indique le nombre moyen de repas renseignés par résidence. \n"}
      <b>{"4) Tableau:"}</b> {" Indique pour chaque résidence, le nombre de repas renseignés sur la période donnée, avec les variations entre cette période et la période précédente."}</div>
  },
  reservations: {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre de réservations de repas sur la période sélectionnée. Par exemple si jamais il y a un Déjeuner et un Diner de réservé, cela compte comme 2 réservations. \n"}
      <b>{"1) Pourcentage:"}</b> {" Indique les variations du nombre de réservations de repas entre cette période et la période précédente. \n"}</div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total de réservations de repas sur la période sélectionnée. Par exemple si jamais il y a un Déjeuner et un Diner de réservé, cela compte comme 2 réservations. \n"}
      <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre de réservations de repas entre cette période et la période précédente. \n"}
      <b>{"3) Moyenne:"}</b> {" Indique le nombre moyen de réservations de repas par résidence sur la période sélectionnée. \n"}
      <b>{"4) Tableau:"}</b> {" Indique pour chaque résidence, le nombre de réservations de repas sur la période donnée, avec les variations entre cette période et la période précédente."}</div>
  },
  portage: {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre de portage de repas sur la période sélectionnée. \n"}
      <b>{"1) Pourcentage:"}</b> {" Indique les variations du nombre de portages de repas entre cette période et la période précédente. \n"}</div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total de portage de repas sur la période sélectionnée. \n"}
      <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre de portage de repas entre cette période et la période précédente. \n"}
      <b>{"3) Moyenne:"}</b> {" Indique le nombre moyen de portage de repas par résidence sur la période sélectionnée. \n"}
      <b>{"4) Tableau:"}</b> {" Indique pour chaque résidence, le nombre de portage de repas sur la période donnée, avec les variations entre cette période et la période précédente."}</div>
  },
  invitations: {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre de repas supplémentaires réservés sur la période sélectionnée. Cela s'applique dans le cas ou une personnne effectue plusieurs réservations pour le même repas. \n"}
      <b>{"1) Pourcentage:"}</b> {" Indique les variations du nombre de repas supplémentaires réservés entre cette période et la période précédente. \n"}</div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total de de repas supplémentaires réservés sur la période sélectionnée. Cela s'applique dans le cas ou une personnne effectue plusieurs réservations pour le même repas. \n"}
      <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre de repas supplémentaires réservés entre cette période et la période précédente. \n"}
      <b>{"3) Moyenne:"}</b> {" Indique le nombre moyen de repas supplémentaires réservés par résidence sur la période sélectionnée. \n"}
      <b>{"4) Tableau:"}</b> {" Indique pour chaque résidence, le nombre de repas supplémentaires réservés sur la période donnée, avec les variations entre cette période et la période précédente."}</div>
  },
  reservationByRepas:
    <div> <b>{"1) Diagramme circulaire:"}</b> {" Représente graphiquement le nombre de réservations par repas pendant la période sélectionnée. \n"}</div>,
  reservationByMenu:
    <div> <b>{"1) Diagramme circulaire:"}</b> {" Représente graphiquement le nombre de réservations par menu du repas pendant la période sélectionnée. \n"}</div>,
  totalOrders: {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total de commandes en restaurant sur la période sélectionnée. \n"}
      <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre de commandes en restaurant entre cette période et la période précédente. \n"}
    </div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total de commandes en restaurant sur la période sélectionnée. \n"}
      <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre de commandes en restaurant entre cette période et la période précédente. \n"}
      <b>{"3) Moyenne:"}</b> {" Indique le nombre moyen de commandes en restaurant par résidence. \n"}
      <b>{"4) Tableau:"}</b> {" Indique pour chaque résidence, le nombre de commandes en restaurant sur la période donnée, avec les variations entre cette période et la période précédente."}</div>
  },
  participation: {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre de participations au sondage de satisfaction \n"}
      <b>{"1) Pourcentage:"}</b> {" Indique les variations du nombre de participations au sondage de satisfaction entre cette période et la période précédente. \n"}</div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total de participations au sondage de satisfaction sur la période sélectionnée. \n"}
      <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre de participations au sondage de satisfaction entre cette période et la période précédente. \n"}
      <b>{"3) Moyenne:"}</b> {" Indique le nombre moyen participations au sondage de satisfaction par résidence sur la période sélectionnée. \n"}
      <b>{"4) Tableau:"}</b> {" Indique pour chaque résidence, le nombre de participations au sondage de satisfaction sur la période donnée, avec les variations entre cette période et la période précédente."}</div>
  },
  restaurant: <div> <b>{"1) Nombre:"}</b> {" Indique le pourcentage d'évaluation dans chaque catégorie sur la période sélectionnée. \n"}
    <b>{"2) Evolution:"}</b> {" Indique les variations du pourcentage d'évaluation de chaque catégorie entre cette période et la période précédente. \n"}
  </div>,
  service: <div> <b>{"1) Nombre:"}</b> {" Indique le pourcentage d'évaluation dans chaque catégorie sur la période sélectionnée. \n"}
    <b>{"2) Evolution:"}</b> {" Indique les variations du pourcentage d'évaluation de chaque catégorie entre cette période et la période précédente. \n"}
  </div>
};

const Restauration = () => {
  const [ctx, dispatch, hasFeature] = useStats();

  const NumberComponent = hasFeature("statistics") ? NumberVariations : SimpleStatComponent;


  const satisfaction = ctx?.data1?.restaurant?.satisfaction;
  const satisfaction2 = ctx?.data2?.restaurant?.satisfaction;

  console.log(satisfaction);
  console.log(satisfaction2);



  const getData = (_data, cible) => {
    return _data.total > 0 ? _data[cible] * 100 / _data.total : 0;
  };

  return (
    <div style={{ marginTop: 30, display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: "wrap", gap: 40, paddingBottom: 30 }}>
      <NumberComponent path="menus/count" title="Repas renseignés" info={ctx?.targetType === "establishment" ? infos.repas.establishment : infos.repas.groupement} icon={faUtensils} iconBackgroundColor={"#F4987A"} />
      {hasFeature("reservationMenu") ?
        <>
          <NumberComponent path="menus/reservations" title="Réservations de repas" info={ctx?.targetType === "establishment" ? infos.reservations.establishment : infos.reservations.groupement} icon={faUtensils} iconBackgroundColor={"#F4987A"} />
        </> : null}
      <NumberComponent path="menus/portage" title="Portage de repas" info={ctx?.targetType === "establishment" ? infos.portage.establishment : infos.portage.groupement} icon={faUtensils} iconBackgroundColor={"#F4987A"} />
      <NumberComponent path="menus/invitations" title="Invitations" info={ctx?.targetType === "establishment" ? infos.invitations.establishment : infos.invitations.groupement} icon={faUtensils} iconBackgroundColor={"#F4987A"} />


      {hasFeature("statistics") && hasFeature("reservationMenu") ?
        <>
          <Card title="Réservations par Repas" info={infos.reservationByRepas} size="lg">
            <PieChart data1={ctx.data1} path="menus/reservationsByRepas" />
          </Card>
          {/* {Object.keys(resaByMenu).map(repas => (
              <Card title={"Réservations par Menus du "+repas} info={infos.reservationByMenu} size="lg" key={repas}>
                <PieChart data1={ctx.data1} path={"menus/reservationByMenu/"+repas}/>
              </Card>
            ))} */}
        </> : null}

      {hasFeature("restaurant") ?

        satisfaction && satisfaction2 ? <>
          <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", marginBottom: 20 }}>

            <NumberVariations path="restaurant/satisfaction/totalOrders" title="Présence en restaurant" info={ctx?.targetType === "establishment" ? infos.totalOrders.establishment : infos.totalOrders.groupement} icon={faUtensils} iconBackgroundColor={"#C99FF2"} />
            <NumberVariations path="restaurant/satisfaction/participation" title="Nombre d'évaluations" info={ctx?.targetType === "establishment" ? infos.participation.establishment : infos.participation.groupement} icon={faPoll} iconBackgroundColor={"#C99FF2"} />
          </div>

          <Card title={"Satisfaction restaurant"} info={infos.restaurant} size="xl">
            <div style={{ display: "flex", flexDirection: "row", width: "90%" }}>
              <div style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center", backgroundColor: "#f5f5f5", borderRadius: 8}}>
                <div style={{flex: 2, width: "80%"}}>
                  <NumberDisplay number={satisfaction.food.mean} variation={satisfaction2?.food?.mean ? percent(satisfaction.food.mean, satisfaction2.food.mean) : null} icon={faPoll} iconBackgroundColor={"#C99FF2"}/>
                </div>
                <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 6}}>
                  <StarRating value={satisfaction.food.mean} starNumber={5}/>
                  <p style={{ color: "grey" }}>Sur {satisfaction.food.total} evaluations</p>
                </div>
               

              </div>
              <div style={{flex: 2, display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                {/* <SatisfactionCard title="Evaluations positives" statColor={"green"} value={getData(satisfaction.food, "positive")} diff={percent(getData(satisfaction.food, "positive"), getData(satisfaction2.food, "positive"))} img={happy} />
                <SatisfactionCard title="Evaluations neutres" statColor={"orange"} value={getData(satisfaction.food, "neutral")} diff={percent(getData(satisfaction.food, "neutral"), getData(satisfaction2.food, "neutral"))} img={neutral} />
                <SatisfactionCard title="Evaluations négatives" statColor={"red"} value={getData(satisfaction.food, "negative")} diff={percent(getData(satisfaction.food, "negative"), getData(satisfaction2.food, "negative"))} img={angry} /> */}
                <SatisfactionPlot data1={satisfaction.food} data2={satisfaction2?.food ?? null}/>

              </div>
            </div>

          </Card>
          <Card title={"Satisfaction service"} info={infos.service} size="xl">
            <div style={{ display: "flex", flexDirection: "row", width: "90%" }}>
              <div style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center", backgroundColor: "#f5f5f5", borderRadius: 8}}>
                <div style={{flex: 2, width: "80%"}}>
                  <NumberDisplay number={satisfaction.service.mean} variation={satisfaction2?.service?.mean ? percent(satisfaction.service.mean, satisfaction2.service.mean) : null} icon={faPoll} iconBackgroundColor={"#C99FF2"}/>
                </div>
                <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 6}}>
                  <StarRating value={satisfaction.service.mean} starNumber={5}/>
                  <p style={{ color: "grey" }}>Sur {satisfaction.service.total} evaluations</p>
                </div>
               

              </div>
              <div style={{flex: 2, display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                {/* <SatisfactionCard title="Evaluations positives" statColor={"green"} value={getData(satisfaction.food, "positive")} diff={percent(getData(satisfaction.food, "positive"), getData(satisfaction2.food, "positive"))} img={happy} />
                <SatisfactionCard title="Evaluations neutres" statColor={"orange"} value={getData(satisfaction.food, "neutral")} diff={percent(getData(satisfaction.food, "neutral"), getData(satisfaction2.food, "neutral"))} img={neutral} />
                <SatisfactionCard title="Evaluations négatives" statColor={"red"} value={getData(satisfaction.food, "negative")} diff={percent(getData(satisfaction.food, "negative"), getData(satisfaction2.food, "negative"))} img={angry} /> */}
                <SatisfactionPlot data1={satisfaction.service} data2={satisfaction2?.service ?? null}/>

              </div>
            </div>

          </Card>
        </> : null


        : null}
    </div>
  );
};

export default Restauration;
