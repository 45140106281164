window.process = {};
process.env = {};

import "./services/firebase";

import React from "react";
import ReactDOM from "react-dom/client";
import ENV from "./env";
import App from "./App";

import firebase from "firebase";

import "react-toastify/dist/ReactToastify.css";
import 'react-json-view-lite/dist/index.css';
import "./lib/assets/scss/index.scss";
import "moment/min/moment-with-locales";
import "moment/locale/fr";


ReactDOM.createRoot(document.getElementById('root')).render(
    <App />,
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", function() {
    navigator.serviceWorker.register("service-worker.js").then(function() {
      //
    }, function(err) {
      console.error("Worker registration failed", err);
    }).catch(function(err) {
      console.error(err);
    });
  });
} else {
  console.warn("Service Worker is not supported by browser.");
}

try {
  firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
  });
  firebase.firestore().enablePersistence();
} catch (e) {
  console.error(e);
}