import React, { useMemo, useCallback, useState } from "react";
import {Spinner} from "../../../../../../../lib/components";
import useCtx from "../../../Context/ctx";
import SubscriptionComponent from "../../../Components/SubscriptionComponent";

const Subscription = () => {
  const { state, dispatch, save } = useCtx();
  const { user, establishment, isLoading } = (state ?? {});

  if (isLoading) return (<Spinner />);

  return (
    <div>
        <SubscriptionComponent user={user} establishment={establishment}/>
    </div>
  );
};

export default Subscription;