import React from "react";
import useUI from "@/hooks/ui.hook";

import Basic_SupplementsEditor from "./Basic/Pages/SupplementsEditor";

import Ovelia_EditorInterface from "./Specific/ovelia/Pages/index";
import Ovelia_Generique from "./Specific/ovelia/Pages/Generic";

import Heurus_EditorInterface from "./Specific/heurus/Pages/index";

const EditorInterface = (props) => {
  const [ui] = useUI();
  if (ui?.groupement?.specific === "ovelia") return <Ovelia_EditorInterface {...props} />
  if(ui?.groupement.specific === "heurus") return <Heurus_EditorInterface {...props} />
  return <></>
}

const Generic = (props) => {
  const [ui] = useUI();

  if (ui?.groupement?.specific === "ovelia") return <Ovelia_Generique {...props} />
  return <></>
}

const SupplementsEditor = (props) => {
  return <Basic_SupplementsEditor {...props} />
}
export { EditorInterface, Generic, SupplementsEditor};
