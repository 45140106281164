import React, {useState} from "react";
import PropTypes from "prop-types";
import firebase from "firebase";
import {toast} from "react-toastify";
import {Trash} from "react-feather";
import useUI from "../../../hooks/ui.hook";
import { v4 as uuidv4 } from "uuid";
import styles from "../../assets/scss/component/compactImagePicker.module.scss";
import { Spinner } from "..";
import { uploadFormFile } from "./imagePickerUtils";

const storage = firebase.storage;

const CompactImagePicker = (props) => {
  const {
    className,
    style,
    onChange,
    value,
    setImageLoaderForm,
  } = props;

  const [ui] = useUI();

  const [imageLoader, setImageLoader] = useState(false);

  const changeImageLoader = (bool) =>{
    setImageLoader(bool);
    if(setImageLoaderForm)
      setImageLoaderForm(bool);
  };

  const onAdd = async (e) => {
    changeImageLoader(true);

    e.preventDefault();
    try {
      onChange(await uploadFormFile(ui, e.target.files[0], 1200, 800, 0.7));
    } catch (e) {
      console.error(e.message);
      toast.error(e.message);
      changeImageLoader(false);
    }
    changeImageLoader(false);
  };

  const onRemove = async () => {
    onChange(null);
  };


  return (
    <>
      <div className={`${styles.container} ${value ? styles.filled : ""}`}>
        <label htmlFor="file">Choisir une image</label>
        {value && 
          <div className={styles.trashIcon} onClick={onRemove}>
            <Trash color="#ff5370" size={24} />
          </div>
        }
        {imageLoader ? <Spinner color="primary" size="md"/>: null}
      </div>
      {value ? 
        <div className={styles.preview}>
          <img src={value} />
        </div>
        : null}
      <input 
        id="file"
        type="file"
        placeholder="Sélectionner votre image"
        onChange={onAdd}
        style={{visibility: "hidden"}}
        accept="image/png, image/jpeg, image/jpg"
      />
    </>
  );
};

CompactImagePicker.propTypes = {
  className : PropTypes.string,
  style : PropTypes.any,
  onChange : PropTypes.func,
  value : PropTypes.any,
  setImageLoaderForm : PropTypes.func,
};

export default CompactImagePicker;