import React, { useState, useEffect, useMemo } from "react";
import { Edit, Clipboard } from "react-feather";
import {
  Container, Row, Col,
  Table, Icon, Tag, Button,
  Card, CardHeader, CardBody, Input, Modal, ModalBody, ModalHeader
} from "lib/components";
import firebase from "firebase";
import useUI from "hooks/ui.hook";
import { Plus } from "react-feather";
import { useFirebaseCollection } from "lib/hooks/firebase";
import roles from "enums/roles";
import { useHistory } from "react-router-dom";
import { AddUserModal, SubscriptionComponent } from "../../router_components";
import PropTypes from "prop-types";
import useSearch from "hooks/useSearch";
import { VideoTuto } from "lib/components/YoutubeModal";
import useFeature from "hooks/useFeature";
import styles from "assets/scss/pages/userList.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBirthdayCake } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import deepEqual from "deep-equal";
import {findSubscriptionForDate} from "helpers/subscription"


const firestore = firebase.firestore;

const parameters = ["name", "surname", "room"];

const UserList = () => {
  const history = useHistory();
  const [ui] = useUI();
  const [usersRef] = useState(firestore().collection("users").where("establishment", "==", ui.user.establishment));
  const { data: _users } = useFirebaseCollection(usersRef);
  const users = useMemo(() => _users.filter(user => !user.isDeleted), [_users]);
  const [userFilter, setUserFilter] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [value, setValue] = useState("");
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const { filtered } = useSearch(filteredUsers, value, parameters);

  const hasReservationMenu = useFeature("reservationMenu");
  const hasSubscription = ui?.establishment?.templateSubscription ? true : false;


  // bout de code permettant de simplifier la mise en place du templateSubscription. (avant que l'éditeur de template ne soit créé)

  // useEffect(()=> {
  //   firestore().collection("establishments").doc(ui.user.establishment).update({
  //     templateSubscription : {
  //       "demi-pension" : {
  //         repas : {
  //           "Déjeuner" : ["Entrée", "Plat", "Accompagnement", "Dessert"]
  //         }
  //       },
  //       "pension complète" : {
  //         repas : {
  //           "Déjeuner" : ["Entrée", "Plat", "Accompagnement", "Dessert"],
  //           "Diner" : ["Entrée", "Plat", "Accompagnement", "Dessert"]
  //         }
  //       },
  //     "pension complète allégée" : {
  //         repas : {
  //           "Déjeuner" : ["Entrée", "Plat", "Accompagnement", "Dessert"],
  //           "Diner" : ["Plat", "Dessert"]
  //         }
  //     }
  //     }
  //   });
  // },[]);

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const filterUsers = (user) => {
    if (userFilter.length == 0)
      return true;
    else
      return userFilter.indexOf(user.role) != -1 ? true : false;
  };


  useEffect(() => {
    const _users = ui.user.groupementAdmin || ui.user.superadmin ? users : users.filter(u => u.role !== "totem");
    setFilteredUsers(_users.sort((a, b) => a.name.localeCompare(b.name)).filter(filterUsers));
  }, [users, userFilter]);


  useEffect(() => {
    if (selectedUser) {
      const _user = users.find(_u => _u.uid === selectedUser.uid);

      if (!deepEqual(_user, selectedUser)) {
        setSelectedUser(_user);
      }
    }
  }, [users, selectedUser]);


  const isBirthday = (birthday) => {
    return birthday === moment().format("MM-DD");
  };



  return (
    <>
      <VideoTuto url="https://youtu.be/u8OsB67dubY" />
      <Container>
        <RenderFilter userFilter={userFilter} setUserFilter={setUserFilter} />
        {/* <Search array={filteredUsers} setArray={setFilteredUsers2} searchParameters={["name", "surname"]}/> */}
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", justifyContent: "space-between", gap: 60, alignItems: "center" }}>
                  <h5 style={{ marginBottom: 0, whiteSpace: "nowrap" }}>Liste des utilisateurs</h5>
                  <div style={{ width: "60%", display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                    <h6 style={{ marginBottom: 0, whiteSpace: "nowrap" }}>Recherche :</h6>
                    <Input value={value} onChange={(e) => setValue(e.target.value)} />
                  </div>
                </div>
                <Button color="primary" onClick={() => setAddModalOpen(true)}><Plus /></Button>
              </CardHeader>
              <CardBody>
                <Table loading={users === null}>
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Prénom</th>
                      <th>Appartement</th>
                      {hasReservationMenu && hasSubscription ? <th>Abonnement</th> : null}
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtered ? filtered.map(user => (
                      <tr key={user.uid}>
                        <td>{isBirthday(user.birthday) && <FontAwesomeIcon icon={faBirthdayCake} size={5} color={"#f2994a"} />} {user.name}</td>
                        <td>{user.surname}</td>
                        <td>{user.room}</td>
                        {hasReservationMenu && hasSubscription && ["senior", "seniorTotem"].includes(user.role) ?
                          <td>

                            <div className={styles.subscription}>
                              <div className={styles.subscriptionInfo}>
                                {findSubscriptionForDate(user?.subscriptions, moment()) ? <div className={styles.subscriptionSubComponent}>
                                  <b style={{fontSize: 18}}>{findSubscriptionForDate(user?.subscriptions, moment()).subscription}</b>
                                  <div>
                                    {` jusqu'au `} <b>{moment(findSubscriptionForDate(user?.subscriptions, moment()).end).format("DD/MM/YYYY")}</b>
                                  </div>
                                </div> : `Aucun abonnement`}
                              </div>
                              <Button className={styles.subscriptionButton} onClick={() => { setSelectedUser(user); setIsOpen(true); }}>⚙</Button>
                            </div>
                          </td> : null}

                        <td className={styles.tags}>
                          <Tag style={{ backgroundColor: (roles.find(r => r.value === user.role) ?? {}).color }}>
                            {(roles.find(r => r.value === user.role) ?? {}).label ?? user.role}
                          </Tag>
                        </td>
                        <td className={styles.actions}>
                          {user.uid !== ui.user.uid ?
                            ui.user.groupementAdmin || ui.user.superadmin ?
                              <>
                                {/*<Icon icon={Trash} color='danger' onClick={() => onDelete(user.uid)}/> &nbsp;*/}
                                <Icon icon={Edit} color='info' className={styles.icon} onClick={() => history.push(`/dashboard/users/${user.uid}`)} />
                              </>
                              :
                              user.role != "owner" ?
                                <>
                                  {/*<Icon icon={Trash} color='danger' onClick={() => onDelete(user.uid)} /> &nbsp;*/}
                                  <Icon size={30} icon={Edit} color='info' className={styles.icon} onClick={() => history.push(`/dashboard/users/${user.uid}`)} />
                                </>
                                : null
                            : null}
                        </td>
                      </tr>
                    )) : null}
                    <tr className={styles.hiddenPadding}></tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <AddUserModal isOpen={addModalOpen}
          toggle={closeAddModal} />
        <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="xl" className={styles.modalXXL}>
          <ModalHeader>
            {"Paramétrage de l'abonnement"}
          </ModalHeader>
          <ModalBody>
            <SubscriptionComponent establishment={ui.establishment} user={selectedUser} onClose={() => setIsOpen(false)} />
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
};


const RenderFilter = ({ userFilter, setUserFilter }) => {
  const [ui] = useUI();

  const handleFilter = (value) => {
    let _userFilter = [...userFilter];

    if (value == "all") {
      _userFilter = [];
    } else {
      if (_userFilter.indexOf(value) == -1) {
        _userFilter.push(value);
      } else {
        _userFilter.splice(_userFilter.indexOf(value), 1);
      }
    }
    setUserFilter(_userFilter);
  };
  let retour = [];
  retour.push(<div style={{ backgroundColor: "#51075e", color: "#FFFFFF", padding: 6, borderRadius: 10, cursor: "pointer", opacity: userFilter.length > 0 ? 0.35 : 1 }} onClick={() => handleFilter("all")}>Tous</div>);
  roles.forEach(element => {
    if (!["superadmin", "groupementAdmin", "regionAdmin", ui.user.groupementAdmin || ui.user.superadmin ? "" : "totem"].includes(element.value)) {
      retour.push(<div style={{ backgroundColor: element.color, color: "#FFFFFF", padding: 6, borderRadius: 10, cursor: "pointer", opacity: userFilter.indexOf(element.value) == -1 ? 0.35 : 1 }} onClick={() => handleFilter(element.value)}>{element.label}</div>);
    }
  });

  return <div style={{ display: "flex", flex: 1, flexWrap: "wrap", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
    {retour}
  </div>;
};

RenderFilter.propTypes = {
  userFilter: PropTypes.array,
  setUserFilter: PropTypes.func,
};


export default UserList;