import {
  Button,
  Checkbox,
  Container,
  DatePicker,
  FormGroup,
  ImagePickerService,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Select,
  TimePicker, Card,
  Icon
} from "../../../../../lib/components";
import React, { useEffect, useState, useMemo } from "react";
import { ChevronDown, ChevronRight, Info } from "react-feather";
import useUI from "../../../../../hooks/ui.hook";
import PropTypes from "prop-types";
import moment from "moment";
import { toast } from "react-toastify";
import useAnimationsEvents from "../../../../../resources/animations";
import useGoogleAnalytics from "../../../../../hooks/useGoogleAnalytics";
import useFeature from "hooks/useFeature";
import { getUsersByEtab } from "services/ressources/user";
import useMicroService from "helpers/microService";
import { CategorySelector } from "../../router_components";
import DatePickerMultiple from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import styles from "../../../../../lib/assets/scss/component/eventModal.module.scss";
import WaitingModal from "../../../WaitingList/WaitingModal";
import { createWaitingEvent, deleteWaitingEvent, getWaitingDataByEventId, getWaitingListById, updateWaitingEvent } from "../../../../../services/ressources/waitingList";

const maxCaracteresDescription = 280; // nombres max de caractères dans la description

const EventModal = (props) => {
  const {
    isOpen,
    onClose,
    eventOpen,
    preselectStart,
    preselectEnd,
    preselectDate,
  } = props;
  const [ui] = useUI();
  const ms = useMicroService();
  const ga = useGoogleAnalytics();
  const { create, update, remove } = useAnimationsEvents();
  const loading = false;
  const [expanded, setExpanded] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [place, setPlace] = useState("");
  const [file, setFile] = useState(null);
  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [image, setImage] = useState(null);
  const [maxParticipants, setMaxParticipants] = useState(0);
  const [category, setCategory] = useState(null);
  const [disableSubscribe, setDisableSubscribe] = useState(false);
  const [openSubscribe, setOpenSubscribe] = useState(7);
  const [closeSubscribe, setCloseSubscribe] = useState(0);
  const [isPublished, setIsPublished] = useState(false);
  const [error, setError] = useState({});
  const [imageLoaderForm, setImageLoaderForm] = useState(false);
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [isAtelier, setIsAtelier] = useState(false);
  const [loaderEventCreate, setLoaderEventCreate] = useState(false);
  const isSavePublish = useFeature("savePublishAnimations");
  const recurrenceChoices = ["Jamais", "Tous les jours", "Toutes les semaines", "Toutes les 2 semaines", "Tous les mois", "Personnalisé"];
  const [selectedRecurrence, setSelectedRecurrence] = useState("Jamais");
  const [endRecu, setEndRecu] = useState(new Date().toLocaleDateString("fr-CA"));
  const [modalNotification, setModalNotification] = useState(null);
  const [multipleDates, setMultipleDates] = useState([]);

  const [modalProgrammation, setModalProgrammation] = useState(false);
  const [dateProg, setDateProg] = useState(new Date());
  const [startTimeProg, setStartTimeProg] = useState({ value: "", label: "" });
  const [inWaiting, setInWaiting] = useState({});

  const [type, setType] = useState("info");//info, resa or recurrence

  const subscribeGuard = useMemo(() => {
    let _start = new Date(date.getTime());
    let updateGuard = true;

    if (disableSubscribe) return false;

    // Subscribtions closed
    if (
      moment(_start)
        .subtract(closeSubscribe, "days")
        .startOf("D")
        .isBefore(moment())
      && closeSubscribe != 0
    ) return false;

    // Subscribe not opened
    if (
      openSubscribe != 0 &&
      moment(_start)
        .subtract(openSubscribe, "days")
        .startOf("D")
        .isAfter(moment().startOf("D"))
    )
      return false;

    return updateGuard;
  }, [eventOpen, isOpen, preselectStart, preselectEnd, openSubscribe, closeSubscribe, date, disableSubscribe]);

  const fetchWaitingData = async () => {
    try {
      const inwaiting = await getWaitingListById({ ui: ui });
      setInWaiting(inwaiting ?? []);
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    fetchWaitingData();
    if (!eventOpen && isOpen) {
      // setIsSendNotif(false);
      setError({});
      setTitle("");
      setDescription("");
      setPlace("");
      setDate(new Date());
      if (preselectDate) {
        setDate(preselectDate);
        // setEndRecurrence(new Date());
        setEndRecu(new Date(preselectDate).toLocaleDateString("fr-CA"));
        // setWeek(new Date());
      } else {
        // setEndRecurrence(moment().endOf("week"));
        setEndRecu(new Date().toLocaleString("fr-CA"));
        // setWeek(
        //   moment().startOf("week").format("YYYY") + "-W" + moment().format("WW")
        // );
      }

      if (preselectStart && preselectEnd) {
        let _start = {
          label: moment(preselectStart).format("HH[h]mm"),
          value: moment(preselectStart).format("HH[h]mm"),
        };
        let _end = {
          label: moment(preselectEnd).format("HH[h]mm"),
          value: moment(preselectEnd).format("HH[h]mm"),
        };
        setStartTime(_start);
        setEndTime(_end);
      } else {
        setStartTime("");
        setEndTime("");
      }
      setImage(null);
      setMaxParticipants(0);
      setCategory(null);
      setIsRecurrent(false);
      setIsAtelier(false);
      setIsPublished(false);

      setLoaderEventCreate(false);
      return;
    } else if (eventOpen && isOpen) {
      setError({});
      // setIsSendNotif(false);
      setTitle(eventOpen.title);
      setDescription(eventOpen.description);
      setPlace(eventOpen.place);
      setDate(eventOpen.start.toDate());
      setImage(eventOpen.image);
      setFile(eventOpen.file);
      setDisableSubscribe(eventOpen.disableSubscribe);
      setOpenSubscribe(eventOpen.openSubscribe);
      setCloseSubscribe(eventOpen.closeSubscribe);
      eventOpen.maxParticipants
        ? setMaxParticipants(eventOpen.maxParticipants)
        : setMaxParticipants(0);

      let eventStartHour = eventOpen.start.toDate().getHours();
      let eventStartMin = eventOpen.start.toDate().getMinutes();
      let eventEndHour = eventOpen.end.toDate().getHours();
      let eventEndMin = eventOpen.end.toDate().getMinutes();
      setStartTime({
        label: `${eventStartHour <= 9 ? "0" : ""}${eventStartHour}h${eventStartMin <= 9 ? "0" : ""
          }${eventStartMin}`,
        value: `${eventStartHour <= 9 ? "0" : ""}${eventStartHour}h${eventStartMin <= 9 ? "0" : ""
          }${eventStartMin}`,
      });
      setEndTime({
        label: `${eventEndHour <= 9 ? "0" : ""}${eventEndHour}h${eventEndMin <= 9 ? "0" : ""
          }${eventEndMin}`,
        value: `${eventEndHour <= 9 ? "0" : ""}${eventEndHour}h${eventEndMin <= 9 ? "0" : ""
          }${eventEndMin}`,
      });
      setCategory(eventOpen.categoryId);
      if (eventOpen.eventType === "atelier") setIsAtelier(true);
      else setIsAtelier(false);
      setIsRecurrent(false);
      setIsPublished(eventOpen.isPublished ?? true);
    }
  }, []);

  useEffect(() => {
    if (!startTime) return;
    if (endTime) return;

    try {
      let value = parseInt(startTime.value.split("h")[0]) + 1;
      if (value === 24) return;
      value =
        value <= 9
          ? `0${value}h${startTime.value.split("h")[1]}`
          : `${value}h${startTime.value.split("h")[1]}`;
      setEndTime({ label: value, value });
    } catch (e) {
      //
    }
  }, [startTime]);

  const maybeSendNotif = async (oldEvent, newEvent, start, end) => {
    //tests pour le mode savePublish
    if (isSavePublish && !newEvent?.isPublished) { onClose(); setLoaderEventCreate(false); return; }// Si on publie pas, on envoie pas de notif
    //si on est en train de modifié une animation et qu'on va (ou qui est deja) publier
    if (isSavePublish && oldEvent?.isPublished || !isSavePublish && oldEvent) {
      let compteurModif = 0;
      let bodyDisplay = "";
      // Si le titre à changé
      if (oldEvent.title !== newEvent.title) {
        compteurModif++;
        bodyDisplay = `Le nouveau titre de l'évenement est ${newEvent.title}`;
      }
      //Si la description
      if (oldEvent.description !== newEvent.description) {
        compteurModif++;
        if (compteurModif > 1) bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
        else bodyDisplay = `La nouvelle description est ${newEvent.description}`;
      }
      //si le lieu a changé
      if (oldEvent.place !== newEvent.place) {
        compteurModif++;
        if (compteurModif > 1) bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
        else bodyDisplay = `Le nouvel endroit pour l'évènement est ${newEvent.place}`;
      }
      //Si le nombre max de participants a changé
      if (oldEvent.maxParticipants !== newEvent.maxParticipants) {
        compteurModif++;
        if (compteurModif > 1) bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
        else {
          if (newEvent.maxParticipants === 0) {
            bodyDisplay = "Le nouveau nombre de participants est sans restriction";
          } else {
            bodyDisplay = `Le nouveau nombre maximum de participants est de ${newEvent.maxParticipants}`;
          }
        }
      }
      //si l'ouverture des inscriptions a changé.
      if (oldEvent.openSubscribe !== newEvent.openSubscribe) {
        compteurModif++;
        if (compteurModif > 1) bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
        else {
          if (newEvent.openSubscribe === 0) {
            bodyDisplay = "Il n'y plus de limite de jours pour l'ouverture des inscriptions";
          } else {
            bodyDisplay = `Les inscriptions seront ouvertes ${newEvent.openSubscribe} jours avant`;
          }
        }
      }
      //si la fermeture des inscriptions a changé
      if (oldEvent.closeSubscribe !== newEvent.closeSubscribe) {
        compteurModif++;
        if (compteurModif > 1) bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
        else {
          if (newEvent.closeSubscribe === 0) {
            bodyDisplay = "Il n'y plus de limite de jours pour la fermeture des inscriptions";
          } else {
            bodyDisplay = `Les inscriptions seront fermées ${newEvent.closeSubscribe} jours avant`;
          }
        }
      }
      //Si l'horaire de début ou de fin a changé
      if (new Date(oldEvent.start.seconds * 1000).getTime() !== start.getTime() || new Date(oldEvent.end.seconds * 1000).getTime() !== end.getTime()) {
        compteurModif++;
        if (compteurModif > 1) bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
        else {
          bodyDisplay = `La nouvelle date pour cet évenement est le ${date.toLocaleDateString("fr-FR")} de ${start.toLocaleTimeString("fr-FR", { hour: "numeric", minute: "numeric" })} à ${end.toLocaleTimeString("fr-FR", { hour: "numeric", minute: "numeric" })} `;
        }
      }
      //------- notification pour une modification  ----------
      if (compteurModif > 0) {
        setModalNotification(bodyDisplay);
        setLoaderEventCreate(false);
        return;
        // let users = await getUsersByEtab({etabId: ui.user.establishment}, () => {throw new Error("Une erreur est survenue");});
        // users = users.filter(u => u.role === "senior");
        // users = users.map(u => u.uid);
        // await ms("sendPushToUserById", {
        //   userId: users,
        //   title: `Modification de l'évènement nommée ${newEvent.title}`,
        //   body: `${bodyDisplay}.`
        // });

      }
      onClose();
      setLoaderEventCreate(false);
      return;
    }


    //------- notification pour un nouvel évenement ----------
    try {
      let users = await getUsersByEtab({ etabId: ui.establishment.uid }, () => { throw new Error("Une erreur est survenue"); });
      users = users.filter(u => u.role === "senior");
      users = users.map(u => u.uid);

      await ms("sendPushToUserById", {
        userId: users,
        title: "Nouvel évènement disponible",
        body: `${newEvent.title}. Le ${date.toLocaleDateString("fr-FR")} de ${newEvent.startTime.value} à ${newEvent.endTime.value}.`,
        type: "animation",
        etabId: ui.establishment.uid,
        info: new Date(start)
      });
    } catch (e) {
      console.error(e);
      toast.warning("Echec de l'envoi de la notification");
      onClose();
    }

    setLoaderEventCreate(false);
    onClose();
  };

  const sendModifNotif = async (toSend) => {
    if (toSend) {
      try {
        setLoaderEventCreate(true);
        let users = await getUsersByEtab({ etabId: ui.establishment.uid }, () => { throw new Error("Une erreur est survenue"); });
        users = users.filter(u => u.role === "senior");
        users = users.map(u => u.uid);
        await ms("sendPushToUserById", {
          userId: users,
          title: `Modification de l'évènement nommée ${title}`,
          body: `${modalNotification}.`,
          type: "animation",
          etabId: ui.establishment.uid,
          info: start
        });
      } catch (e) {
        console.error(e);
        toast.warning("Echec de l'envoi de la notification");
        setLoaderEventCreate(false);
        onClose();
      }
    }
    setLoaderEventCreate(false);
    onClose();
  };


  const _onSubmit = async (_isPublished) => {
    setLoaderEventCreate(true);
    let _error = {};

    if (!date) { _error.date = "Ce champ est obligatoire"; }
    if (!startTime) { _error.startTime = "Ce champ est obligatoire"; }
    if (!endTime) { _error.endTime = "Ce champ est obligatoire"; }
    if (!title) { _error.title = "Ce champ est obligatoire"; }
    setError(_error);
    if (Object.keys(_error).length > 0) {
      toast.warning("Un ou plusieurs champs sont manquants");
      return setLoaderEventCreate(false);
    }

    let start = new Date(date.getTime());
    let end = new Date(date.getTime());
    start.setHours(startTime.value.split("h")[0]);
    start.setMinutes(startTime.value.split("h")[1]);
    start.setSeconds(0);
    end.setHours(endTime.value.split("h")[0]);
    end.setMinutes(endTime.value.split("h")[1]);
    end.setSeconds(0);

    if (start.getTime() >= end.getTime()) { setLoaderEventCreate(false); return toast.warning("L'heure de fin doit être supérieure à l'heure de début"); }

    if (closeSubscribe >= openSubscribe && closeSubscribe != 0 && openSubscribe != 0) { toast.warning("Champs d'ouverture/fermeture d'inscription invalides"); return setLoaderEventCreate(false); }



    let oldEvent = eventOpen ? { ...eventOpen } : null;
    let newEvent = {
      type: "custom",
      title,
      description,
      place,
      //date: calculée plus bas cause récurrence
      startTime,
      endTime,
      file,
      image,
      categoryId: category,
      maxParticipants,
      disableSubscribe,
      openSubscribe: parseInt(openSubscribe ?? "7"),
      closeSubscribe: parseInt(closeSubscribe ?? "0"),
      isPublished: isSavePublish ? _isPublished ?? false : null,
    };

    try {
      if (!eventOpen) {
        if (isRecurrent && endRecu) {
          if (selectedRecurrence === "Tous les jours") {
            let nbEvent = 0;
            if (moment(date).isSame(moment(endRecu), "day")) {
              nbEvent = 0;
            } else nbEvent = moment(endRecu).diff(moment(date), "days") + 1;
            let _date = moment(date);
            for (let i = 0; i <= nbEvent; i++) {
              _date = moment(date).add(i, "days");
              await create({ ...newEvent, date: _date.toDate() });
            }
          } else if (selectedRecurrence === "Toutes les semaines") {
            let nbEvent = moment(endRecu).diff(moment(date), "weeks");
            if (moment(date).format("dddd") === moment(endRecu).format("dddd") && moment(endRecu).isAfter(moment(date))) nbEvent = nbEvent + 1;
            for (let i = 0; i <= nbEvent; i++) {
              let _date = moment(date).add(i, "weeks");
              await create({ ...newEvent, date: _date.toDate() });
            }
          } else if (selectedRecurrence === "Toutes les 2 semaines") {
            let nbEvent = moment(endRecu).diff(moment(date), "weeks");
            if (moment(date).format("dddd") === moment(endRecu).format("dddd") && moment(endRecu).isAfter(moment(date))) nbEvent = nbEvent + 1;
            for (let i = 0; i <= nbEvent; i += 2) {
              let _date = moment(date).add(i, "weeks");
              await create({ ...newEvent, date: _date.toDate() });
            }
          } else if (selectedRecurrence === "Tous les mois") {
            let nbEvent = moment(endRecu).diff(moment(date), "months");
            if (moment(date).date() === moment(endRecu).date() && moment(endRecu).isAfter(moment(date))) { nbEvent = nbEvent + 1; }
            for (let i = 0; i <= nbEvent; i++) {
              let _date = moment(date).add(i, "months");
              await create({ ...newEvent, date: _date.toDate() });
            }
          } else if (selectedRecurrence === "Personnalisé") {
            for (let index = 0; index < multipleDates.length; index++) {
              await create({ ...newEvent, date: multipleDates[index].toDate() });
            }
          }
        } else {
          if (modalProgrammation) {
            try {
              const isProgrammed = true;
              const animationId = await create({ ...newEvent, date, isProgrammed });
              const [scheduleHours, scheduleMinutes] = startTimeProg?.value.split('h').map(Number);
              let newDate = new Date(dateProg);
              newDate.setHours(scheduleHours, scheduleMinutes);
              const newAnim = { title: title, categoryId: category, timing: newDate, date: date, end: end, eventLinkedId: animationId };
              await createWaitingEvent({ ui: ui, type: "Animation", data: newAnim });
              toast.success("L'animation sera publiée à l'heure");
              setModalProgrammation(false);
            } catch (e) {
              console.log(e);
              toast.error("Une erreur est survenue");
              return;
            }
          }
          else {
            await create({ ...newEvent, date });
          }
        }
      } else {
        if (eventOpen.isProgrammed) {
          const waitingEvent = await getWaitingDataByEventId({ eventLinkedId: eventOpen.uid });
          //si l'anim va etre publié on doit enlever de la liste programmation l'event
          if (_isPublished) {
            await deleteWaitingEvent({ eventId: waitingEvent.uid });
            await update(eventOpen.uid, { ...newEvent, date, isProgrammed: false });

          }
          //autrement on update l'animation dans la liste de programmation
          else {
            await update(eventOpen.uid, { ...newEvent, date, isProgrammed: true });
            const { uid, ...waitingEventWithoutUid } = waitingEvent;
            const newAnim = { ...waitingEventWithoutUid, title: title, categoryId: category, date: date, end: end };
            await updateWaitingEvent({ waitingEventId: waitingEvent.uid, data: newAnim });
          }

          fetchWaitingData();
        } else {
          await update(eventOpen.uid, { ...newEvent, date, isProgrammed: false });
        }
      }

      await maybeSendNotif(oldEvent, newEvent, start, end);
      // setLoaderEventCreate(false);
      ga("planning_edit");
      // onClose();
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
      onClose();
    }

  };

  const _onDelete = async () => {
    let start = new Date(date.getTime());
    let end = new Date(date.getTime());
    start.setHours(startTime.value.split("h")[0]);
    start.setMinutes(startTime.value.split("h")[1]);
    start.setSeconds(0);
    end.setHours(endTime.value.split("h")[0]);
    end.setMinutes(endTime.value.split("h")[1]);
    end.setSeconds(0);
    setLoaderEventCreate(true);
    try {
      await remove(eventOpen.uid);
      if (eventOpen.isProgrammed) {
        const waitingEvent = await getWaitingDataByEventId({ eventLinkedId: eventOpen.uid });
        await deleteWaitingEvent({ eventId: waitingEvent.uid });
        await fetchWaitingData();
      }
      if (eventOpen.isPublished) {
        let users = await getUsersByEtab({ etabId: ui.establishment.uid }, () => { throw new Error("Une erreur est survenue"); });
        users = users.filter(u => u.role === "senior");
        users = users.map(u => u.uid);
        await ms("sendPushToUserById", {
          userId: users,
          title: "Suppression d'un évènement",
          body: `${title}. Le ${date.toLocaleDateString("fr-FR")} de ${startTime.value} à ${endTime.value}.`,
          type: "animation",
          etabId: ui.establishment.uid,
          info: start
        });
      }

      onClose();

      setLoaderEventCreate(false);
    } catch (e) {
      onClose();

    }

  };

  const handleWeekChange = (e) => {
    let selectDate = e.target.value;
    let currentDate = new Date(date);
    let selectedEndDate = new Date(selectDate);
    selectedEndDate.setHours(23, 59, 0, 0);
    if (currentDate <= selectedEndDate) {
      const newDate = selectedEndDate.toLocaleDateString("fr-CA");
      //  setWeek(selectDate);
      setEndRecu(newDate);
    }
    else {
      toast.warning(
        "La date de fin de récurrence doit être supérieure à la date actuelle"
      );
    }
  };


  const handleRecurrenceChange = (e) => {
    if (e === "Jamais") {
      setIsRecurrent(false);
      setSelectedRecurrence(e);
    }
    else {
      setIsRecurrent(true);
      setSelectedRecurrence(e);
    }
  };

  const handleMulitpleDateChange = (newDate) => {
    setMultipleDates(newDate);
  };

  const checkFormValidity = () => {
    
    let _error = {};

    if (!date) { _error.date = "Ce champ est obligatoire"; }
    if (!startTime) { _error.startTime = "Ce champ est obligatoire"; }
    if (!endTime) { _error.endTime = "Ce champ est obligatoire"; }
    if (!title) { _error.title = "Ce champ est obligatoire"; }
    setError(_error);
    if (Object.keys(_error).length > 0) {
      toast.warning("Un ou plusieurs champs sont manquants");
      setLoaderEventCreate(false);
      return false;
    }else{
      return true;
    }
  };

  if (loading)
    return (
      <Modal isOpen={isOpen} size="lg" toggle={onClose}>
        <ModalHeader>
          {eventOpen ? "Détail de l'évènement" : "Ajouter un évènement"}
        </ModalHeader>
        <ModalBody>
          <Spinner />
        </ModalBody>
      </Modal>
    );

  return (
    <>
      <Modal isOpen={isOpen} size="xl" toggle={onClose}>
        {/* HEADER */}
        <ModalHeader>
          {eventOpen ? "Détail de l'évènement" : "Ajouter un évènement"}
          {eventOpen && eventOpen.type}
        </ModalHeader>

        {/* CONTENT */}
        <Container>
          <div className={styles.header}>
            <span style={{ display: "flex", alignItems: "center" }} className={`${styles.button} ${type === "info" ? styles.active : ""}`} onClick={() => setType("info")}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Informations de l'évènement
              {Object.keys(error).length !== 0 && <div className={styles.errorButton}></div>}
            </span>

            {useFeature("reservationPlanning") && <span className={`${styles.button} ${type === "resa" ? styles.active : ""}`} onClick={() => setType("resa")}>
              Gérer les réservations
            </span>}
            {!eventOpen && <span className={`${styles.button} ${type === "recurrence" ? styles.active : ""}`} onClick={() => setType("recurrence")}>
              Gérer la récurrence
            </span>}
          </div>

          {type === "info" ?
            <>
              <FormGroup>
                <Input
                  theme="grey"
                  label={"Titre"}
                  invalid={error.title}
                  onChange={(e) =>
                    setTitle(e.target.value.length > 60 ? title : e.target.value)
                  }
                  value={title}
                />
              </FormGroup>
              <FormGroup
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  columnGap: 4,
                }}
              >
                <div style={{ flex: 1 }}>
                  <DatePicker
                    label={"Jour"}
                    value={date}
                    invalid={error.date}
                    onChange={setDate}
                    theme="grey"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TimePicker
                    invalid={error.startTime}
                    label={"Heure de début"}
                    value={startTime}
                    onChange={setStartTime}
                    theme="grey"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TimePicker
                    invalid={error.endTime}
                    label={"Heure de fin"}
                    value={endTime}
                    onChange={setEndTime}
                    theme="grey"
                  />
                </div>
              </FormGroup>
              <CategorySelector value={category} onChange={setCategory} style={{ flex: 1 }} />
              <FormGroup>
                <Input
                  theme="grey"
                  label={"Lieu"}
                  onChange={(e) => setPlace(e.target.value)}
                  value={place}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  theme="grey"
                  label={"Description"}
                  onChange={(e) => { if (e.target.value.length <= maxCaracteresDescription) setDescription(e.target.value); }}
                  value={description}
                  type="textarea"
                />
                {maxCaracteresDescription != description.length ? (
                  <div style={{ color: "grey" }}>
                    Nombre de caractères restants : {maxCaracteresDescription - description.length}
                  </div>
                ) : (
                  <div style={{ color: "red" }}>
                    Nombre de caractères restants : {maxCaracteresDescription - description.length}
                  </div>
                )}
              </FormGroup>
              <FormGroup style={{ display: "flex", justifyContent: "center", paddingBottom: 20 }}>
                <ImagePickerService
                  max={1}
                  onChange={e => {
                    console.log(e);
                    if (typeof (e) === "string") {
                      setImage(e);
                    } else if (typeof (e) === "object" && e && e.urls && e.urls.raw) {
                      setImage(e.urls.raw);
                    }
                    else {
                      setImage("");
                    }
                  }}
                  value={image ?? null}
                  // value={image}
                  theme="grey"
                  setImageLoaderForm={setImageLoaderForm}
                />
              </FormGroup></> :
            type === "resa" ? <>

              <FormGroup>
                <Input
                  theme="grey"
                  label={"Nombre maximum de participants"}
                  min={0}
                  onChange={(e) => setMaxParticipants(parseInt(e.target.value))}
                  value={maxParticipants}
                  type="number"
                />
              </FormGroup>
              {!disableSubscribe ? (
                <>
                  {subscribeGuard ? (
                    <div style={{ color: "green" }}>
                      Les inscriptions sont actuellement ouvertes.
                    </div>
                  ) : (
                    <div style={{ color: "red" }}>
                      Les inscriptions sont actuellement fermées.
                    </div>
                  )}
                  <FormGroup
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      columnGap: 4,
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Input
                        theme="grey"
                        label={"Ouvrir les inscriptions X jours avant le début\n(0 = pas de limite)"}
                        min={0}
                        onChange={(e) => {
                          setOpenSubscribe(parseInt(e.target.value ?? "7"));
                        }}
                        value={openSubscribe}
                        type="number"
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <Input
                        theme="grey"
                        label={"Fermer les inscriptions X jours avant le début\n(0 = pas de limite)"}
                        min={0}
                        onChange={(e) => {
                          setCloseSubscribe(parseInt(e.target.value ?? "0"));
                        }}
                        value={closeSubscribe}
                        type="number"
                      />
                    </div>
                  </FormGroup>
                </>
              ) : (
                <div style={{ color: "red" }}>
                  Les inscriptions sont actuellement fermées
                </div>
              )}
              <FormGroup>
                <Checkbox
                  theme="grey"
                  label={"Désactiver les inscriptions"}
                  onChange={() => setDisableSubscribe(!disableSubscribe)}
                  checked={disableSubscribe}
                />
              </FormGroup></>
              :
              type === "recurrence" && !eventOpen ?
                <FormGroup>
                  <div style={{ color: "purple", fontSize: "15px", fontWeight: "bold", marginBottom: "20px" }}>
                    {"A partir du " + moment(date).date() + " " + moment(date).format("MMMM")}
                    {startTime.value && endTime.value
                      ? " (" + startTime.value + " à " + endTime.value + ")"
                      : ""}{" "}
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <Select
                      label="Définir une récurrence"
                      options={recurrenceChoices.map(choice => ({ label: choice, value: choice }))}
                      containerStyle={{ flex: 1 }}
                      value={{ value: selectedRecurrence, label: selectedRecurrence }}
                      onChange={(e) => handleRecurrenceChange(e.value)}
                      theme="grey"
                    />
                  </div>
                  {isRecurrent ? (
                    <>
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      {selectedRecurrence === "Toutes les semaines" ?
                        <span>
                          Tous les {moment(date).format("dddd")}
                          {startTime.value && endTime.value
                            ? " de " + startTime.value + " à " + endTime.value
                            : ""}{" "}
                          jusqu&apos;au (inclus) :
                        </span>
                        : selectedRecurrence === "Tous les jours" ?
                          <span>
                            {startTime.value && endTime.value
                              ? " De " + startTime.value + " à " + endTime.value
                              : ""}{" "}
                            jusqu&apos;au (inclus) :
                          </span>
                          : selectedRecurrence === "Toutes les 2 semaines" ?
                            <span>
                              Tous les 2 {moment(date).format("dddd")}
                              {startTime.value && endTime.value
                                ? " de " + startTime.value + " à " + endTime.value
                                : ""}{" "}
                              jusqu&apos;au (inclus) :
                            </span>
                            : selectedRecurrence === "Tous les mois" ?
                              <span>
                                Tous les {moment(date).date()} du mois
                                {startTime.value && endTime.value
                                  ? " de " + startTime.value + " à " + endTime.value
                                  : ""}{" "}
                                jusqu&apos;au (inclus) :
                              </span> :
                              <div>Choisissez les jours ou vous souhaitez ajouter votre animation:</div>
                      }

                      {selectedRecurrence !== "Personnalisé" ?
                        <input
                          type="date"
                          value={endRecu}
                          className="form-control"
                          id="Week"
                          onChange={handleWeekChange}
                        />
                        : <div style={{ width: "100%" }}>

                          <DatePickerMultiple
                            style={{ width: "30vw", height: "40px" }}
                            placeholder="choisissez vos dates..."
                            multiple
                            value={multipleDates}
                            onChange={(date) => handleMulitpleDateChange(date)}
                            plugins={[<DatePanel />]}
                          />

                        </div>}


                    </>
                  ) : null}
                </FormGroup> : null
          }
        </Container>
        {/* FOOTER */}
        {!loading && (
          <ModalFooter style={{ display: "flex", alignItems: "center", width: "100%" }}>
            {eventOpen ? (
              <div style={{ flexDirection: "column", width: "100%" }}>
                {eventOpen.isProgrammed ?
                  <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", color: "blue", alignItems: "center" }}>
                    <Icon icon={Info} size={15}></Icon>
                    Cette animation est programmée pour la publication
                    <dvi style={{ marginRight: "40px" }}></dvi>
                  </div>
                  : null}
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <Button color="secondary" onClick={onClose}>
                      Fermer
                    </Button>
                    <Button
                      color="danger"
                      onClick={_onDelete}
                      disabled={loaderEventCreate}
                      loading={loaderEventCreate}
                    >
                      Supprimer
                    </Button>

                  </div>
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    {isSavePublish ?
                      <Button
                        color="warning"
                        disabled={loaderEventCreate}
                        loading={loaderEventCreate}
                        onClick={() => { _onSubmit(!isPublished); }}
                      >
                        {isPublished ? "Dépublier" : "Publier"}
                      </Button>
                      : null
                    }
                    <Button
                      color="primary"
                      onClick={() => { _onSubmit(isPublished); }}
                      loading={loaderEventCreate}
                      disabled={imageLoaderForm || loaderEventCreate}
                    >
                      Modifier
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ justifyContent: "space-between", display: "flex", flexDirection: "row", width: "100%" }}>
                <Button color="secondary" onClick={onClose}>
                  Annuler
                </Button>
                <div style={{ flexDirection: "row", display: "flex", gap: "10px" }}>


                  <Button
                    color="secondary"
                    loading={loaderEventCreate}
                    onClick={() => { _onSubmit(isPublished); }}
                    disabled={imageLoaderForm || loaderEventCreate}
                  >
                    {isSavePublish ? "Enregistrer en tant que brouillon" : "Enregistrer"}
                  </Button>

                  {isSavePublish ? <Button
                    color="primary"
                    onClick={() => { checkFormValidity() ? setModalProgrammation(true) : null }}
                    disabled={imageLoaderForm || loaderEventCreate || isRecurrent}
                  >
                    Programmer l'envoi
                  </Button> : null}

                  {isSavePublish ?
                    <Button
                      color="warning"
                      disabled={loaderEventCreate}
                      loading={loaderEventCreate}
                      onClick={() => { _onSubmit(!isPublished); }}
                    >
                      Publier
                    </Button>
                    : null
                  }
                </div>
              </div>
            )}
          </ModalFooter>
        )}
      </Modal>
      <WaitingModal isOpen={modalProgrammation} toggle={() => { setModalProgrammation(false); }} setInfo={(startTime, date) => { setDateProg(date); setStartTimeProg(startTime); }} onValidate={() => _onSubmit(isPublished)}></WaitingModal>
      <Modal isOpen={modalNotification} size="md">
        <ModalHeader>
          Envoi de notifications
        </ModalHeader>
        <ModalBody>
          <p>{"Vous avez modifié certaines informations pour l'animation " + title + "."}</p>
          <p>{"Souhaitez-vous notifier les résidents de ces changements?"}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => sendModifNotif(false)} disabled={loaderEventCreate} loading={loaderEventCreate}>Non </Button>
          <Button onClick={() => sendModifNotif(true)} disabled={loaderEventCreate} loading={loaderEventCreate}> Oui</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

EventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventOpen: PropTypes.object.isRequired,
  preselectStart: PropTypes.any.isRequired,
  preselectEnd: PropTypes.any.isRequired,
  preselectDate: PropTypes.any.isRequired,
};

export default EventModal;
