import {useState} from "react";
import { Button } from "@/lib/components";
import firebase from "firebase";
import moment from "moment";

const firestore = firebase.firestore;

const RbmDoubleOrder = () => {
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);

  const test = async () => {
    setLoading(true);
    const docEtabs = await firestore()
      .collection("establishments")
      .get();

    for (let index = 0; index < docEtabs.docs.length; index++) {
      const _etab = docEtabs.docs[index];

      setReport(prev => [...prev, `<span style="color: blue;">----------------- Etab: ${_etab.id} ${_etab.data().name} ----------------------</span>`]);
      console.log("Nom : ", _etab.data().name);

      // on récupère les données restaurant pour le jour correspondant
      const docs = await firestore()
        .collection("establishments")
        .doc(_etab.id)
        // .doc("LyTy8TqS0k0SRWcZPJCn")//ZwWRMZuGc7xpSkgiWHsJ
        .collection("blocks")
        .doc("restaurant")
        .collection("orders")
        // .where("date", "==", "2024-08-21")
        // .where("service", "==", "Déjeuner")
        .get()

      if (docs?.docs?.length > 0) {
        const filteredDocs = [];

        docs.forEach(doc => {
          filteredDocs.push({ ...doc.data(), day: doc.data()?.day?.toDate(), uid: doc.id });
        });

        // on aggrège par identifiant (date du jour / service / userId)
        // à vérifier comment ça marcher avec les guest ? Car il ne faut pas les prendre en compte ? sauf si il y a des duplicatas sur les guest aussi ?
        const duplicata = filteredDocs.reduce((acc, obj) => {
          if (obj.userId && !obj.guest) {
            const key = `${obj.date}-${obj.service}-${obj.userId}`;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(obj);
          }
          return acc;
        }, {});

        // on veut garder uniquement un élément parmi ceux existants, le but est donc d'extraire la liste des uid à supprimer.

        const listToDelete = [];
        Object.keys(duplicata).forEach(_id => {

          const theOneWeKeep = duplicata[_id].reduce((max, current) => {
            const maxMetaLength = max.meta.length;
            const maxPlatesLength = max.plates.length;

            const currentMetaLength = current.meta.length;
            const currentPlatesLength = current.plates.length;

            // Comparer la longueur des listes `meta` et `orders`
            if (currentMetaLength > maxMetaLength ||
              (currentMetaLength === maxMetaLength && currentPlatesLength > maxPlatesLength)) {
              return current;
            }
            return max;
          });

          duplicata[_id].forEach(element => {
            if (element.uid !== theOneWeKeep.uid) {
              listToDelete.push(element);
              setReport(prev => [
                ...prev,
                `<span style="color: red;">Etab: ${_etab.id} Order ${element.uid} from date${element.date}(${moment(element.day).format("HH:mm:ss")}) service:${element.service} userId: ${element.userId} is a duplicate</span>`
              ])
            }
          });
        });


        console.log("number of docs", filteredDocs.length);
        console.log("number of duplicatas : ", listToDelete.length)
        console.log("infos : ", filteredDocs, duplicata, listToDelete);
        console.log("  ");

        setReport(prev => [...prev, `<span style="color: green;">number of docs: ${filteredDocs.length}</span>`]);
        setReport(prev => [...prev, `<span style="color: green;">number of duplicatas : ${listToDelete.length}</span>`]);
      } else {
        setReport(prev => [...prev, `<span style="color: green;">No orders</span>`]);
      }
    }
    setLoading(false);
  }

  return (
    <div style={{padding: 20}}>
      <Button onClick={test} loading={loading}>Get double order report</Button>
      {report.map((r, i) => (
        <p key={i} dangerouslySetInnerHTML={{__html: r}} />
      ))}
    </div>
  )
}

export default RbmDoubleOrder;