import {useState, useEffect} from "react";
import PropTypes from "prop-types";
import firebase from "firebase";
import useUI from "../../../hooks/ui.hook";
import useUUid from "@/lib/hooks/useUid";
import { Spinner } from "..";
import {Trash} from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

import styles from "../../assets/scss/component/imagePicker.module.scss";

const storage = firebase.storage;

function readableBytes(bytes) {
  const i = Math.floor(Math.log(bytes) / Math.log(1024)),
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}

const CompressorImagePicker = (props) => {
  const {
    label,
    value,
    loading,
    onChange,
    style
  } = props;

  const [ui] = useUI();
  const inputId = useUUid();

  const [file, setFile] = useState(null);
  const [localUrl, setLocalUrl] = useState(null);

  const _compress = async (blobUrl) => {
    const MAX_WIDTH = 800;
    const MAX_HEIGHT = 600;
    const MIME_TYPE = "image/jpeg";
    const QUALITY = 0.7;

    function calculateSize(img, maxWidth, maxHeight) {
      let width = img.width;
      let height = img.height;

      // calculate the width and height, constraining the proportions
      if (width > height) {
          if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
          }
      } else {
          if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
          }
      }
      return [width, height];
    }

    const blobURL = blobUrl
    const img = new Image();
    img.src = blobURL;
    img.onerror = function () {
        URL.revokeObjectURL(this.src);
        // Handle the failure properly
        console.log("Cannot load image");
    };
    img.onload = function () {
        URL.revokeObjectURL(this.src);
        const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        canvas.toBlob(
            (blob) => {
                // Handle the compressed image. es. upload or save in local state
                console.log("Size of compressed image:", readableBytes(blob.size));
            },
            MIME_TYPE,
            QUALITY
        );
    };
  }

  const onAddFile = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let extension = e.target.files[0].name.split(".").pop().toLowerCase();
      if(!["png", "jpg", "jpeg", "gif", "bmp", "webp", "ico", "svg"].includes(extension)) {toast.warning("Le format ''"+extension+"'' n'est pas autorisé. Seuls les fichiers PNG, JPG, JPEG, GIF, BMP, WEBP, ICO et SVG sont autorisés."); return;}  
      console.log("original size:", readableBytes(e.target.files[0].size));
      let _localUrl = URL.createObjectURL(e.target.files[0]);
      _compress(_localUrl);
      setLocalUrl(_localUrl);
      setFile(e.target.files[0]);
    }
  }
  const onRemove = () => {
    setLocalUrl(null);
    setFile(null);
  }

  return (
    <div className={styles.container} style={style}>
      <span className={styles.label}>{label}</span>
      <div className={styles.headerContainer}>
        <input type="file" placeholder="Sélectionner votre image" onChange={onAddFile} accept=".png,.jpg,.jpeg,.gif,.bmp,.webp,.ico,.svg"/>
        {loading ? <Spinner color="primary" size="md"/>: null}
      </div>
      <div className={`${styles.imageContainer} ${styles.vertical}`}>
        {localUrl && typeof(localUrl) === "string" ?
          <div className={styles.image}>
            <span className={styles.icon}>
              <Trash size={24} color="red" onClick={() => onRemove(null)} />
            </span>
            <img src={localUrl} />
          </div>
          :
          <></>
        }
      </div>
    </div>
  )
}

CompressorImagePicker.propTypes = {
  value : PropTypes.any,
  onChange : PropTypes.func,
  ratio : PropTypes.number,
};

export default CompressorImagePicker;