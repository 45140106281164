import React from "react";

import Basic_MenuIndex from "./Basic/Pages/index";
import Basic_EditorInterface from "./Basic/Pages/EditorInterface";
import Basic_SupplementsEditor from "./Basic/Pages/SupplementsEditor";

import Ovelia_EditorInterface from "./Specific/ovelia/Pages/EditorInterface";
import useUI from "hooks/ui.hook";

const MenuIndex = (props) => {
    return <Basic_MenuIndex {...props} />;
};

const SupplementsEditor = (props) => {
    return <Basic_SupplementsEditor {...props} />;
};

const EditorInterface = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_EditorInterface {...props} />;
    return <Basic_EditorInterface {...props} />;
};

export { MenuIndex, EditorInterface, SupplementsEditor };