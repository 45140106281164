import React, {useEffect, useState} from "react";
import firebase from "firebase";
import PropTypes from "prop-types";
import useGazette from "../context";
import {pdf, PDFDownloadLink, PDFViewer, Document} from "@react-pdf/renderer";
import { Button } from "lib/components";
import { toast } from "react-toastify";
import pageTypes from "../pageTypes";
import useUI from "hooks/ui.hook";
import { getEstablishmentById, updateEstablishmentById } from "services/ressources/establishment";
import contentTypes from "../contentTypes";
import { getUsersByEtab } from "services/ressources/user";
import useMicroService from "helpers/microService";
const storage = firebase.storage;
const firestore = firebase.firestore;


const PDF = (props) => {
  const {ctx, ui} = props;

  return (
    <Document>
      {ctx.pages.map((page, index) => (
        <>
          {pageTypes.find(type => type.slug === page.slug) ? 
            pageTypes.find(type => type.slug === page.slug).pdf({page, ctx, index, ui, contentTypes})
            : null}
        </>
      ))}
    </Document>
  );
};

const Previsualisation = () => {
  const [ctx, dispatch] = useGazette();
  const [ui] = useUI();
  const [loading, setLoading] = useState(false);

  const ms = useMicroService();
  const [isPublished, setIsPublished] = useState(false);

  useEffect(()=>{
    if(ctx.publication != null){
      setIsPublished(true);
    }else{
      setIsPublished(false);
    }
  },[ctx.publication]);

  const cancelShare = async () => {
    const _db = await getEstablishmentById({id: ui.user.establishment});
    const _lastGazette = _db.lastGazette;

    if(_lastGazette === ctx.publication){
      firestore().collection("establishments").doc(ui.user.establishment).update({lastGazette : firestore.FieldValue.delete()});
    }
    dispatch({type: "setProperty", property: "publication", value: null});

    toast.success("La publication de cette gazette a été annulé.");

  };

  const shareGazette = async () => {
    setLoading(true);
    
    //const blob = pdf(<PDF ctx={ctx} ui={ui}/>).toBlob(); // doesn't work
    const myPdf = await pdf(<PDF ctx={ctx} ui={ui}/>); // important, without an argument it blows up
    await myPdf.updateContainer(<PDF ctx={ctx} ui={ui}/>);
    const blob = await myPdf.toBlob(); // now works!
   
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = async function () {
      let base64PDF = reader.result;
      //let hash = uuidv4();
      /* eslint-disable-next-line no-useless-escape*/
      let gazette_name = ctx.title.replace(/[!*'();:@&=+$,/?%# \[\]]/g, "");
      await storage().ref(`/uploads/${ui.user.establishment}/gazette_${gazette_name}.pdf`).putString(base64PDF, "data_url");
      let downloadUrl = await storage().ref(`/uploads/${ui.user.establishment}`).child("gazette_"+gazette_name+".pdf").getDownloadURL();
      await updateEstablishmentById({id: ui.user.establishment, data: {
        lastGazette: downloadUrl,
      }});
      
      
      //list des users pour l'envoi de la notif
      let users = await getUsersByEtab({etabId: ui.user.establishment}, () => {throw new Error("Une erreur est survenue");});
        users = users.filter(u => u.role === "senior");
        users = users.map(u => u.uid);

        // envoi de la notif
        await ms("sendPushToUserById", {
          userId: users,
          title: "Nouvelle Gazette",
          body: "Rendez-vous sur la page Gazette pour la lire.",
          type:"gazette",
          etabId:ui.user.establishment,
        });
      dispatch({type: "setProperty", property: "publication", value: downloadUrl});
      toast.success("Cette gazette sera désormais disponible sur l'application mobile.");
      
      //onChange(downloadUrl); 
      setLoading(false);
    };
    
  };

  if (ctx.step !== 3) return <></>;
  return (
    <>
      <div style={{display: "flex", width: "50%", justifyContent: "space-around", marginTop: 5, marginBottom: 12}}>
        <Button onClick={shareGazette} loading={loading} disabled={loading}>Partager cette gazette</Button>
        <Button onClick={cancelShare} disabled={!isPublished}>Annuler la publication</Button>
        <PDFDownloadLink document={<PDF ctx={ctx} ui={ui}/>} fileName="gazette.pdf">
          {({/* blob, url, */loading/*, error */}) => (loading ? "Loading document..." : 
            <Button>Télécharger</Button>
          )}
        </PDFDownloadLink>
      </div>
      <PDFViewer style={{width: "100%", height: 1200}}>
        <PDF ctx={ctx} ui={ui} />
      </PDFViewer>
    </>
  );
};
//TODO Definir les proptypes 

PDF.propTypes = {
  ctx : PropTypes.any,
  ui : PropTypes.any,
};

export default Previsualisation;