import React, {useState} from "react";
import PropTypes from "prop-types";
import useUI from "hooks/ui.hook";
import {Generic} from "../../../router_page";
import { Provider } from "../../../router_context";

import Logo from "assets/images/jabiLogo/MAJOR_DETOUR.png";

import styles from "assets/scss/pages/Menu/editorIndex.module.scss";
import { Button } from "lib/components";
import { Settings } from "react-feather";
import {useHistory} from "react-router-dom";


const colors = ["#f1faf5", "#faf6ff", "#fcf1f2", "#fff7eb"];

const RenderRepas = ({setSelectedPresta}) => {
  const [ui] = useUI();

  if (!ui || !ui.groupement || !ui.groupement.template) return (
    <div className={styles.noTemplate}>
      <img src={Logo} />
      <h1>Menu introuvable</h1>
      <p>{"Votre fonctionnalité d'édition de menu n'est pas encore activée"}</p>
      <p>{"Rapprochez vous de l'équipe Jabi afin de paramétrer votre menu personnalisé"}</p>
      <Button style={{marginTop: 20}} onClick={() => window.location.href = "mailto:briac.perrin@jabi.fr"}>{"Prendre contact avec l'équipe Major"}</Button>
    </div>
  );


  
  return Object.keys(ui.groupement.presta).map((_presta, _id) => (
    <div className={styles.card} key={_id} onClick={() => setSelectedPresta(_presta)} style={{backgroundColor: colors[_id % colors.length]}}>
      <span className={styles.mealTitle}>{_presta}</span>
    </div>
  ));
};

const EditorInterface = () => {
  const [ui] = useUI();
  const [selectedPresta, setSelectedPresta] = useState("menu");
  const history = useHistory();

  return (
    <>
      {ui.groupement.presta && Object.keys(ui.groupement.presta).length > 0 && selectedPresta === "menu" ? 
        <div style={{display: "flex", flexDirection: "column", width: "100%", gap: 10, alignItems: "flex-end", justifyContent: "center", marginTop: 30 }}>
          <Button style={{display: "flex", alignItems: "center", gap: 5}} onClick={() => history.push("/dashboard/menus/supplements")}>
              <Settings />
              Editer les suppléments
            </Button>
          <div className={styles.container} style={{width: "100%"}}>
            <RenderRepas setSelectedPresta={setSelectedPresta}/>
          </div>
          
        </div>
        : 
          <Provider presta={selectedPresta}>
            <Generic/>
          </Provider>
      }
      
    </>);
};

RenderRepas.propTypes = {
  setSelectedPresta: PropTypes.func.isRequired,
};

export default EditorInterface;