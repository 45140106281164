import { useEffect, useState, useMemo } from 'react';
import {useParams} from 'react-router-dom';

import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';

import { getLogById } from '@/services/ressources/logger';
import { getUserById } from '@/services/ressources/user';
import { getEstablishmentById } from '@/services/ressources/establishment';

const LogguerDetails = () => {
  const {id} = useParams();

  const [log, setLog] = useState(null);
  const [user, setUser] = useState(null);
  const [etab, setEtab] = useState(null);

  const messages = useMemo(() => {
    let _message = log?.message;
    let messageDisplay = []; // [{type: "text", value: "hello", {type: json, value: {key: "value"}}, ...]

    while (_message) {
      if (_message.startsWith("{")) {
        let end = 0;
        let accCounter = 1;

        for (let i = 1; i < _message.length; i++) {
          if (_message[i] === "{") accCounter++;
          if (_message[i] === "}") accCounter--;

          if (accCounter === 0) {
            end = i;
            break;
          }
        }

        messageDisplay.push({type: "json", value: JSON.parse(_message.substring(0, end + 1))});
        _message = _message.substring(end + 1);
      } else {
        let end = _message.indexOf("{");
        if (end === -1) end = _message.length;

        messageDisplay.push({type: "text", value: _message.substring(0, end)});
        _message = _message.substring(end);
      }
    }
    console.log(_message);
    console.log(messageDisplay);
    return messageDisplay;
  }, [log]);

  useEffect(() => {
    (async () => {
      const log = await getLogById(id);
      setLog(log);

      if (log.userId) {
        const user = await getUserById({id: log.userId});
        setUser(user);
        console.log(user);
      }

      if (log.etabId) {
        const etab = await getEstablishmentById({id: log.etabId});
        setEtab(etab);
      }
    })()
  }, [id]);

  if (!log) return null;

  return (
    <div>
      <h2>Log details</h2>
      <div>
        <p>App: {log.app}</p>
        <p>Feature: {log.feature}</p>
        <p>Title: {log.title}</p>
        <div>
          {messages.map((m, i) => (
            <>
              {m.type === "text" && <p key={i}>{m.value}</p>}
              {m.type === "json" && <JsonView key={i} data={m.value} styles={defaultStyles} />}
            </>
          ))}
        </div>
        <p>Position: {log.position}</p>
        <p>Level: {log.level}</p>
        <p>Serial: {log.serial}</p>
        <p>Date: {log.createdAt.toDate().toLocaleString()}</p>
        {user && <p>User: {user.surname} {user.name} ({user.uid})</p>}
        {etab && <p>Establishment: {etab.name}</p>}
      </div>
    </div>
  )
}

export default LogguerDetails;