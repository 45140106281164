import React from "react";
import { PenTool } from "react-feather";
const SignatureIcon = (props) => {
  const { cell, orders } = props;
  return (
    <div style={{ position: "absolute", right: 0, bottom: 0, border: "1px solid #ccc", borderBottomLeftRadius: 5, padding: 3, }}>
      { orders && 
        orders[cell.column.date] && 
        orders[cell.column.date][cell.row.original.userId] && 
        orders[cell.column.date][cell.row.original.userId][cell.column.repas] && 
        orders[cell.column.date][cell.row.original.userId][cell.column.repas].every(order => order.hasSignature) &&
        <PenTool
          size={16}
        />
      }
    </div>
  );
}

export default SignatureIcon;