import React from "react";
import useUI from "@/hooks/ui.hook";

import Basic_LabelSelector from "./Basic/Components/_labelSelector";
import Basic_MenuCard from "./Basic/Components/_menuCard";
import Basic_MenuInput from "./Basic/Components/_menuInput";
import Basic_SupplementModalEditor from "./Basic/Components/SupplementModalEditor";
import Basic_CategorySupplementModalEditor from "./Basic/Components/CategorySupplementModalEditor";
import Basic_ValidationImportSupplementModal from "./Basic/Components/ValidationImportSupplementModal";


const LabelSelector = (props) => {

  return <Basic_LabelSelector {...props} />
}

const MenuCard = (props) => {
  return <Basic_MenuCard {...props} />
}

const MenuInput = (props) => {
  return <Basic_MenuInput {...props} />
};

const SupplementModalEditor = (props) => {
  return <Basic_SupplementModalEditor {...props} />
}

const CategorySupplementModalEditor = (props) => {
  return <Basic_CategorySupplementModalEditor {...props} />
}

const ValidationImportSupplementModal = (props) => {
  return <Basic_ValidationImportSupplementModal {...props} />
}

export { LabelSelector, MenuCard, MenuInput, SupplementModalEditor, CategorySupplementModalEditor, ValidationImportSupplementModal};


