import React from "react";
import PropTypes from "prop-types";
import Ratings from "react-ratings-declarative";


const StarRating = ({ value, starNumber }) => {

    return (
        <Ratings
            rating={value ?? 0}
            widgetRatedColors="#ffc30f"
            widgetDimensions="1.6vw"
            widgetSpacings="4px"
        >
            {Array.from(Array(starNumber).keys()).map(_i => 
                (
                    <Ratings.Widget key={_i}/>
                )
            )}
        </Ratings>
    )
}


StarRating.propTypes = {
    value: PropTypes.number,
    starNumber: PropTypes.number,
  };
  
  
  export default StarRating;