import React from "react";
import PropTypes from "prop-types";

import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import DashboardHeader from "config/DashboardHeader";
import GroupementMenu from "config/GroupementMenu";
import {DashboardLayout} from "lib/layouts";

import Home from "./home";
import Establishment from "./Establishments/Establishment";
import Establishments from "./Establishments/index";
import Users from "./Users";
import User from "./Users/User";
import Profile from "./Profile";
import HomeStat from "./Stats/index";
import NewsGroupement from "./News/index";
import CultureQuizzEditor from "../Major/CultureQuizz/QuizzEdit";
import CultureQuizzQuizzList from "../Major/CultureQuizz/QuizzList";
import CultureQuizzQuizzPlay from "../Major/CultureQuizz/QuizzPlay";
import CultureQuizzQuizzResults from "../Major/CultureQuizz/QuizzResults";
import CultureQuizzQuizzStart from "../Major/CultureQuizz/QuizzStart";
import Menu from "./Menus/Menu";
import useUI from "hooks/ui.hook";
import useFeature from "@/hooks/useFeature";
import { SupplementsEditor } from "./Menus/router_page";


const GroupementRouter = ({match}) => {
  const [ui] = useUI();
  return (
    <DashboardLayout menu={<GroupementMenu />} header={<DashboardHeader />}>
      <Switch>
        <Route exact path={`${match.url}/one`}><Home /></Route>
        <Route exact path={`${match.url}/establishments/:id`}><Establishment /></Route>
        <Route exact path={`${match.url}/establishments`}><Establishments /></Route>
        <Route exact path={`${match.url}/users/:id`}><User /></Route>
        <Route exact path={`${match.url}/users`}><Users /></Route>
        <Route exact path={`${match.url}/profile`}><Profile /></Route>
        <Route exact path={`${match.url}/news`}><NewsGroupement /></Route>
        <Route exact path={`${match.url}/news/:id`}><Profile /></Route>
        <Route exact path={`${match.url}/statistics`}><HomeStat /></Route>
        {["ovelia", "heurus"].includes(ui?.groupement?.specific) ? <Route exact path={`${match.url}/menus`}><Menu /></Route> : null}
        {["ovelia", "heurus"].includes(ui?.groupement?.specific) ? <Route exact path={`${match.url}/menus/supplements`}><SupplementsEditor /></Route> : null}
        <Route exact path={`${match.url}/cultureQuizz`}>
          <CultureQuizzQuizzList />
        </Route>
        <Route exact path={`${match.url}/cultureQuizz/edit/:uid`}>
          <CultureQuizzEditor />
        </Route>
        <Route exact path={`${match.url}/cultureQuizz/:uid/start`}>
          <CultureQuizzQuizzStart />
        </Route>
        <Route exact path={`${match.url}/cultureQuizz/:uid/play`}>
          <CultureQuizzQuizzPlay />
        </Route>
        <Route exact path={`${match.url}/cultureQuizz/:uid/results`}>
          <CultureQuizzQuizzResults />
        </Route>
        <Route path={"*"}><Redirect to={`${match.url}/one`} /></Route>
      </Switch>
    </DashboardLayout>
  );
};

GroupementRouter.propTypes = {
  match : PropTypes.object.isRequired,
};

export default GroupementRouter;