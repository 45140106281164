import {useState, useEffect, useMemo} from "react";
import { getAllLogs_rt, addLog } from "@/services/ressources/logger";
import moment from "moment";
import { Button, Table } from "@/lib/components";
import { useHistory } from "react-router-dom";
import useUI from "@/hooks/ui.hook";
import Filter from "./filter";

const Logguer = () => {
  const [ui] = useUI();
  const [logs, setLogs] = useState([]);
  const [filter, setFilter] = useState({
    establishment: null,
    user: null,
    start: null,
    end: null,
  });

  const history = useHistory();

  useEffect(() => getAllLogs_rt(null, setLogs), []);

  const logsFiltered = useMemo(() => (
    logs
      .filter(log => {
        if (filter.establishment && log.etabId !== filter.establishment) return false;
        if (filter.user && log.userId !== filter.user) return false;
        if (filter.start && moment(log.createdAt.toDate()).isBefore(moment(filter.start).startOf("day"))) return false;
        if (filter.end && moment(log.createdAt.toDate()).isAfter(moment(filter.end).endOf("day"))) return false;
        return true;
      })
      .sort((a, b) => moment(b.createdAt.toDate()).diff(moment(a.createdAt.toDate())))
  ), [logs, filter]);

  console.log(filter);

  const log = () => {
    addLog({
      feature: "logguer",
      userId: ui.user.uid,
      title: "Test",
      message: "Test",
      position: "superadmin/logguer/index.js->log()",
      level: "info"
    })
  }

  return (
    <div style={{width: "100%"}}>
      <h2>Logs</h2>
      <Filter value={filter} onChange={setFilter} />
      <Table style={{marginTop: 10}}>
        <thead>
          <tr>
            <th>uid</th>
            <th>Date</th>
            <th>App</th>
            <th>Feature</th>
            <th>Title</th>
            <th>Message</th>
            <th>Position</th>
            <th>Serial</th>
          </tr>
        </thead>
        <tbody>
          {logsFiltered.map(log => (
            <tr key={log.uid} style={{cursor: "pointer"}} onClick={() => history.push(`/dashboard/logguer/${log.uid}`)}>
              <td>{log.uid}</td>
              <td>{moment(log.createdAt.toDate()).format("DD/MM/YYYY HH:mm:ss")}</td>
              <td>{log.app}</td>
              <td>{log.feature}</td>
              <td>{log.title}</td>
              <td>{log.message.substring(0,100)}</td>
              <td>{log.position}</td>
              <td>{log.serial}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default Logguer;