import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import PropTypes from "prop-types";
import React, { useMemo } from "react";
import moment from "moment";
import useUI from "hooks/ui.hook";
// import illustration from "./assets/Image1.jpg";
import Logo from "./assets/LogoApiProvence.jpg";
import Roboto from "fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "fonts/roboto/Roboto-BoldCondensed.ttf";
import dayjs from "dayjs";

Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});

const styles = StyleSheet.create({
  title: {
    margin: 10,
    fontFamily: "Roboto",
  },
  table: {
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "black",
    margin: 10,
  },
  columnHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderStyle: "solid",
    borderBottomWidth: 2,
    borderColor: "black",
    backgroundColor: "#e79d62"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderStyle: "solid",
    borderBottomWidth: 2,
    borderColor: "black",
  },
  dayCell: {
    width: "20%",
    borderStyle: "solid",
    borderRightWidth: 2,
    borderColor: "black",
    paddingVertical: 5,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "lightgrey",
  },
  animationCell: {
    display: "flex",
    flexDirection: "row",
    width: "80%", 
    textAlign: "center",
  },
  halfDay: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "50%",
  },
  eventContainer: {
    width:"100%",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  eventText: {
    fontSize: 12,
    padding: 2,
  }
});

const MonthPlanningPdf = (props) => {
  const { ui, firstDay, events, logo, additionnalData, categories } = props;
  const day = new Date(firstDay);


  const _computeDays = useMemo(() => {
    let firstDayOfMonth = dayjs(new Date(firstDay));
  
    return [
      ...Array.from({ length: firstDayOfMonth.daysInMonth() }, (_, i) => {
        return dayjs(firstDayOfMonth).add(i, "day").toDate();
      })].filter(date => {
        const dayOfWeek = dayjs(date).day();
        return dayOfWeek !== 6 && dayOfWeek !== 0; // 6 = samedi, 0 = dimanche
      });
  }, [firstDay]);

  const _getEventByDay = (day) => {
    let start = new Date(day);
    let end = new Date(day);

    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(1);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);

    return events.filter((event) => event.start > start && event.end < end);
  };

  const _hourToString = (date) => {
    return dayjs(date).format("HH:mm");
  };

  const getEventColorFromCategory = (categoryId) => {
    if (!categoryId) return "lightgrey";
    const category = categories.find((i) => i.uid === categoryId);
    if (!category) return "lightgrey";
    return category.color;
  };

  const  getColorByContrast = (hexcolor) => {
    hexcolor = hexcolor.replace('#', '');
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = ((r*299) + (g*587) + (b*114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

  const Event = ({event, style}) => {
    return (
      <View style={[styles.eventContainer, style]}>
        <Text key={event.id} style={[styles.eventText, {color: getColorByContrast(getEventColorFromCategory(event.categoryId))}]}>{_hourToString(event.start)} - {_hourToString(event.end)} {event.title}</Text>
      </View>
    )
  }
  const Row = ({date, events, style}) => {
    const morningEvents = events.filter(_e => dayjs(_e?.start).hour() < 12)
    const eveningEvents = events.filter(_e => dayjs(_e?.start).hour() >= 12)
    return (
      <View style={[styles.row, style]}>
        <View style={styles.dayCell}>
          <Text style={{fontSize: 12, textAlign: "center", fontFamily: "Roboto",}}>{dayjs(date).format("dddd DD MMMM YYYY")}</Text>
        </View>
        
        <View style={[styles.cell, styles.animationCell]}>
          <View id="morningEvents"style={styles.halfDay}>
            {morningEvents.map((event) => (
              <Event key={event.id} event={event} style={{backgroundColor: getEventColorFromCategory(event.categoryId)}}/>
            ))}
          </View>
          <View id="eveningEvents" style={styles.halfDay}>
            {eveningEvents.map((event) => (
              <Event key={event.id} event={event} style={{backgroundColor: getEventColorFromCategory(event.categoryId)}}/>
            ))}
          </View>
          
        </View>
      </View>
    )
  }



  return (
    <Document>
      <Page size="A3" orientation="portrait" style={{ position: "relative"}}>
        <Text style={styles.title}>Planning des animations {dayjs(day).format("MMMM YYYY").toLocaleUpperCase()}</Text>
        <View id="table" style={styles.table}>
          <View id="ColumnHeaderContainer" style={styles.columnHeaderContainer}>
            <Text style={{width: "20%", textAlign: "center", borderRightWidth: 2, borderColor: "black", borderStyle: "solid", fontFamily: "Roboto"}}>Jour</Text>
    
            <Text style={{width: "80%", textAlign: "center", fontFamily: "Roboto" }}>Animations</Text>
          </View>
          {/* Rows */}
          {_computeDays.map((_day, index) => (
            <Row
              key={index}
              date={_day}
              events={_getEventByDay(_day)}
              style={index === _computeDays.length - 1 && { borderBottomWidth: 0 }}
            />
          ))}
        </View>
        <Image src={Logo} style={{position: "absolute", top: 5, right: 10, width: 55, height: 40, objectFit: "contain"}}/>
      </Page>
    </Document>
  )
};

MonthPlanningPdf.propTypes = {
  firstDay: PropTypes.any.required,
  events: PropTypes.array.required,
  logo: PropTypes.string,
  additionnalData: PropTypes.string,
  ui: PropTypes.object.required,
};

export default MonthPlanningPdf;