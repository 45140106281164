import React from "react";
import { Tab } from "lib/components";

import EtabXgroupement from "./etabXgroupement";
import UserAuthXFirestore from "./UserAuthxFirestore";
import AnimationReservationObject from "./animationReservationObject";
import UserApartmentRoom from "./userApartmentRoom";
import UserBirthday from "./UserBirthday";
import RbmDoubleOrder from "./rbmDoubleOrder";

const Healthcheck = () => {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <div style={{width: "100%"}}>
      <Tab 
        activeTab={activeTab}
        onClick={setActiveTab}
        render={[
          {
            title: "Etab <-> Groupement",
            content: <EtabXgroupement />
          },
          {
            title: "User auth -> Firestore",
            content: <UserAuthXFirestore />
          },
          {
            title: "Animation réservation object",
            content: <AnimationReservationObject />
          },
          {
            title: "User Apartment -> User Room",
            content: <UserApartmentRoom />
          }, 
          {
            title: "User Birthday",
            content: <UserBirthday />
          }, {
            title: "rbm double commande",
            content: <RbmDoubleOrder />
          }
        ]}
      />
    </div>
  );
};

export default Healthcheck;