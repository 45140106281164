import React from "react";
import {SidebarLink, SidebarImage} from "../lib/components/dashboard";
import Logo from "../assets/images/jabiLogo/MAJOR_BLANC.png";

import {Home, Users, BarChart, FileText, Award, BookOpen} from "react-feather";

import styles from "../assets/scss/config/groupementAdmin.module.scss";
import useUI from "hooks/ui.hook";

const GroupementAdmin = () => {
  const [ui] = useUI();

  return (
    <div className={styles.menu}>
      <SidebarImage img={Logo}  />
      <SidebarLink to="/dashboard/one"        icon={Home}>Accueil</SidebarLink>
      <SidebarLink to="/dashboard/establishments" >Etablissements</SidebarLink>
      <SidebarLink to="/dashboard/news" icon={FileText}>Actualités</SidebarLink>
      <SidebarLink to="/dashboard/users" icon={Users}>Utilisateurs</SidebarLink>
      <SidebarLink to="/dashboard/statistics" icon={BarChart}>Statistiques</SidebarLink>
      <SidebarLink to="/dashboard/cultureQuizz" icon={Award}>Culture Quiz</SidebarLink>
      {["ovelia", "heurus"].includes(ui?.groupement?.specific) ? <SidebarLink to="/dashboard/menus" icon={BookOpen}>Menus</SidebarLink> : null}

    </div>
  );
};

export default GroupementAdmin;