import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Input, FormGroup, Select,
} from "lib/components";
import roles from "enums/roles";
import firebase from "firebase";

import { toast } from "react-toastify";
import useUI from "hooks/ui.hook";
import { getUsersByEtab } from "services/ressources/user";


import { createIntervention, updateInterventionByEtab, removeIntervention } from "services/ressources/interventions";
import useMicroService from "../../../helpers/microService";
import { sendNotifToCentreNotif } from "../../../services/ressources/notification";

const getUserName = (user) => {
    if(user){
        return user.name + " " + user.surname;
    }else{
        return "";
    }
    
};

const AddInterventionModal = (props) => {
  const [ui] = useUI();
  const ms = useMicroService();

  const {isOpen, users, toggle, currentIntervention} = props;

  const [userId, setUserId] = useState("");
  
  const [interventionCategory, setInterventionCategory] = useState("");
  const [otherCategoryText, setOtherCategoryText] = useState("");
  const [loading,setLoading] = useState(false);
  const interventionCategories = ["Électricité", "Ménage", "Plomberie", "Autre"];
  const colors = ["#dbad07", "#83bc53", "#518cc4", "#5c5c5c"];
  // const colors =  {"Électricité":"#f3cf00", "Ménage":"#83bc53","Plomberie": "#518cc4", "Autre":"#5c5c5c"};



  const [intervention, setIntervention] = useState("");

  useEffect(() => {
    if(currentIntervention){
        setUserId(currentIntervention.userId);
        setIntervention(currentIntervention.intervention);
        setInterventionCategory(currentIntervention.category);
        setOtherCategoryText(currentIntervention.category);
    }
  }, [currentIntervention]);

  const _add = async() => {
    const name = getUserName(users.find(_u => _u.uid === userId));
    const room = users.find(_u => _u.uid === userId)?.room ?? "";
    const date = firebase.firestore.FieldValue.serverTimestamp();
    const state = "todo";

    const _data = {
        userId,
        category: interventionCategory,
        intervention,
        name,
        room,
        state
    };

    setLoading(true);
    if(currentIntervention){
        _data["updatedAt"] = date;
        updateInterventionByEtab({etabId: ui.establishment.uid, data: _data, interventionId: currentIntervention.uid});
    } else {
      _data["date"] = date;
      createIntervention({ etabId: ui.establishment.uid, data: _data });
      await ms("sendPushToUserById", {
        userId: userId,
        title: "Confirmation de votre demande d'intervention",
        body: "Votre demande d'intervention à bien été prise en compte. Pour toute demande particulière ou suivi vous pouvez vous rendre à l'accueil.",
        type:"intervention",
        etabId:ui.user.establishment,
      });
      sendNotifToCentreNotif({
        ui,
        data: {
          type: "intervention",
          action: "add",
          data: {
            userId: userId,
            category: interventionCategory,
            date: date,
            ...(intervention !== "" && { interventionDetails: intervention }),
          }
        }
      });
    }
    setLoading(false);
    onClose();
  };

  const _delete = () => {
    removeIntervention({etabId: ui.establishment.uid, interventionId: currentIntervention.uid});
    onClose();
  };


  const onClose = () => {
    setIntervention("");
    setUserId("");
    setInterventionCategory("");
    setOtherCategoryText("");
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg">
      <ModalHeader>{`${currentIntervention ? "Modification" : "Ajout"} d'une demande d'intervention`}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Select label="Utilisateur"
            placeholder="Nom..."
            value={{label: getUserName(users.find(_u => _u.uid === userId)), value: userId}}
            onChange={e => setUserId(e.value)}
            options={users.map(_u => {return {label: getUserName(_u),value: _u.uid};})}
          />
        </FormGroup>
        <FormGroup>
          <div style={{display: "flex", gap: 10, marginBottom: 10}}>
            {interventionCategories.map((type, index) => (
              <Button style={{backgroundColor: colors[index], opacity: interventionCategory === type || (!interventionCategories.includes(interventionCategory) && type === "Autre") ? 1 : 0.3}} key={index} onClick={() => setInterventionCategory(type)}>{type}</Button>
            ))}
          </div>
        </FormGroup>
        <FormGroup><Input type="textarea" label="Catégorie" placeholder="Type d'intervention..." value={interventionCategory} onChange={e => setInterventionCategory(e.target.value)} style={{display : !interventionCategories.includes(interventionCategory) || interventionCategory === "Autre" ? "block" : "none"}}/></FormGroup>
        <FormGroup><Input type="textarea" label="intervention" placeholder="ex: changer une ampoule..." value={intervention} onChange={e => setIntervention(e.target.value)} /></FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={onClose}>Retour</Button>
        {currentIntervention ? <Button color='danger' onClick={_delete}>Supprimer</Button> : null}
        <Button color='primary' disabled={userId === "" || (interventionCategory === "" || interventionCategory === undefined)} loading={loading} onClick={_add} >{currentIntervention ? "Modifier" : "Ajouter"}</Button>
      </ModalFooter>
    </Modal>
  );
};

AddInterventionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  currentIntervention: PropTypes.object,
};

export default AddInterventionModal;
