import React from "react";
import PropTypes from "prop-types";
import useAuth from "./hooks/auth.hook";
import useUI from "./hooks/ui.hook";

import useHotjar from "./hooks/useHotjar";
import useGoogleAnalytics from "./hooks/useGoogleAnalytics";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import {
  PublicLayout
} from "./lib/layouts";

import {
  ErrorPage,
  LoaderPage
} from "./lib/pages";

import PublicHeader from "./config/PublicHeader";

import LoginPage from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import ForgetAction from "./pages/ForgetAction";

import Groupement from "./pages/Groupement";
import Region from "./pages/Region";
import Collaborateur from "./pages/Collaborateur";
import Major from "./pages/Major";
import SuperAdmin from "./pages/SuperAdmin";
import NoFeature from "./pages/NoFeature";
import Restaurant from "./pages/Restaurant";
import Senior from "./pages/Senior";

import { AnimationProvider } from "./resources/animations";
import { ActuProvider } from "./resources/actus";
import RessourceBundler from "./resources/ressourceWrapper";
import useSentry from "lib/hooks/useSentry";
import LoginDemoPage from "./pages/LoginDemo";

const AppRouter = () => {
  useHotjar();
  useGoogleAnalytics("G-14XC1XV36L");
  useSentry();

  const [ui, dispatch] = useUI();

  const RouterConditions = ({match}) =>{
    if(ui.connected){

      if(ui.user.role === "superadmin"){
        return <SuperAdmin match={match}/>;
      }else if(ui.user.role === "groupementAdmin"){
        return <Groupement match={match} />;
      }else if(ui.user.role === "regionAdmin"){
        return <Region match={match} />;
      }else if(ui.establishment && ui.establishment.features && ui.establishment.features.includes("major")){

        if(["owner", "admin", "employee", "restaurant"].includes(ui.user.role)){
          return (
            <RessourceBundler resources={[AnimationProvider, ActuProvider]}>
              {["owner", "admin"].includes(ui.user.role) ? <Major match={match} /> :
                ui.user.role === "employee" ? <Collaborateur match={match} /> :
                  ui.user.role === "restaurant" ? <Restaurant match={match} /> : null}
            </RessourceBundler>
          );
        }else{
          return (
            <div>
              <p>Vous n&apos;avez pas l&apos;autorisation d&apos;accéder à cette page</p>
              <span style={{cursor: "pointer"}} onClick={() => {
                dispatch({type: "logout"});
                window.location.reload();
              }}>Se déconnecter</span>
            </div>
          );

        }
      }else{
        return <NoFeature match={match} />;
      }
    }else{
      return <Redirect to="/login" />;
    }
  };

  RouterConditions.propTypes = {
    match : PropTypes.object.isRequired,
  };
  
  return (
    <>
      <Switch>
        {/* error routes */}
        <Route path="/401"><PublicLayout><ErrorPage code="401" /></PublicLayout></Route>
        <Route path="/403"><PublicLayout><ErrorPage code="403" /></PublicLayout></Route>
        <Route path="/404"><PublicLayout><ErrorPage code="404" /></PublicLayout></Route>
        <Route path="/500"><PublicLayout><ErrorPage code="500" /></PublicLayout></Route>

        {ui?.user?.role === "senior" && <Route path="*"><Senior /></Route>}
  
        {/* Dashboard */}
        <Route path="/dashboard">
          {({match}) => (
            <>
              <RouterConditions match={match}/>
            </>
          )}
        </Route>

        {/* Public */}
        <Route path="/demo-request"><LoginDemoPage /></Route>
        <Route path="/">
          <PublicLayout header={<PublicHeader />}>
            <Switch>
              {ui.connected && <Route path="*"><Redirect to='/dashboard' /></Route>}
              <Route exact path="/login"><LoginPage /></Route>
              <Route exact path="/forget"><ForgetPassword /></Route>
              <Route exact path="/forget/action"><ForgetAction /></Route>
              <Route path="*"><Redirect to="/login" /></Route>
            </Switch>
          </PublicLayout>
        </Route>
      </Switch>
    </>
  );
};

const RouterHOC = () => {
  useAuth();
  const [ui] = useUI();

  return (
    <>
      {ui.appLoaded ?
        <Router>
          <AppRouter />
        </Router>
        :
        <LoaderPage show />
      }
    </>
  );
};


export default RouterHOC;