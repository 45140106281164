import React, {useState, useEffect, useMemo, useCallback} from "react";
import { Table, Button, Input } from "lib/components";
import { Plus, Edit, Trash, Settings} from "react-feather";
import { getGroupementSupplements_rt, deleteGroupementSupplement, getGroupementSupplementCategories_rt, createGroupementSupplement, updateGroupementSupplement } from "@/services/ressources/supplement_restaurant";
import useUI from "@/hooks/ui.hook";
import { CategorySupplementModalEditor, ValidationImportSupplementModal, SupplementModalEditor } from "../../router_component";
import useSearch from "@/hooks/useSearch";
import {SheetImport} from "lib/components";

const parameters = ["id", "name"];
const SupplementsEditor = (props) => {
  const [ui] = useUI();
  const [filter, setFilter] = useState("");
  const [supplements, setSupplements] = useState([]);
  const [validationSupplements, setValidationSupplements] = useState([]);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  
  const [selectedSupplement, setSelectedSupplement] = useState(null);
  const [supplementsCategories, setSupplementsCategories] = useState([]);
  const [isModalSupplementOpen, setIsModalSupplementOpen] = useState(false);
  const [isModalCategorySupplementOpen, setIsModalCategorySupplementOpen] = useState(false);
  const [isModalValidationSupplementOpen, setIsModalValidationSupplementOpen] = useState(false);

  const {filtered: filteredSupplements} = useSearch(supplements, filter, parameters);

  const applyKeys = (categories) => {

    const applyLevelKeys = (categories, level) => {
      return categories.map((category, index) => {
        return {
          ...category,
          key: level + "-" + index,
          children: category.children ? applyLevelKeys(category.children, level + "-" + index) : []
        }
      })
    }
    
    return applyLevelKeys(categories, 0);
  }

  useEffect(() => {
    const unsubscribe = getGroupementSupplementCategories_rt(
      { groupementId: ui.user.groupement },
      (d) => setSupplementsCategories(applyKeys(d))
    );
    return unsubscribe;
  }, []);

  useEffect(() => {
    const subscribe = getGroupementSupplements_rt({groupementId: ui.user.groupement}, (d) => setSupplements(d));
    return subscribe;
  }, []);

  const onDelete = (supplement) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
      deleteGroupementSupplement({ groupementId: ui.user.groupement, supplementId: supplement.uid }, () => {},(e) => console.log(e))
    } else {
      console.log("Suppression annulée");
    }
  }

  const _submitSupplements = (data) => {
    data.forEach(supplement=> {
      // NOUVEAU SUPPLEMENT
      if(supplement.reason === "new"){
        createGroupementSupplement({groupementId: ui.user.groupement, supplement: supplement.imported}, () => {},(e) => console.log(e))
      }

      // SUPPLEMENT MODIFIE
      if(supplement.reason === "modified" && supplement.keep) {
        updateGroupementSupplement({groupementId: ui.user.groupement, supplementId: supplement.original.uid, supplement: supplement.imported}, () => {},(e) => console.log(e))
      }
      // SUPPLEMENT SUPPRIME
      if(supplement.reason === "deleted" && !supplement.keep) {
        deleteGroupementSupplement({groupementId: ui.user.groupement, supplementId: supplement.original.uid}, () => {},(e) => console.log(e))
      }
    })
  }

  const submitImport = (data) => {
    setIsImportModalOpen(false);
    const _data = data.map(
      importedSupplement => { return { 
        id: importedSupplement["Code Produit"], 
        name: importedSupplement["Libellé"], 
        price: importedSupplement["Prix HT"], 
        category: importedSupplement["Categories"]?.split("/").map(c => c.trim()).join("|/|") ?? "", 
        isFileImported: true,
        isFromGroupement: true,
      }}
    );

    // Eléments déjà présents mais absents de l'import 
    const missingFromImport = supplements.filter(importedSupplement => !_data.find(origin => origin.name === importedSupplement.name));
    // Eléments modifiés
    const modified = _data.filter(importedSupplement => {
      const res = supplements.find(origin => origin.name === importedSupplement.name);
      if(res) {
        return !Object.keys(importedSupplement).filter(key => !["uid","isFileImported"].includes(importedSupplement[key])).every(key => importedSupplement[key] === res[key])
      } else return false
    });

    if(missingFromImport.length > 0) {
      setIsModalValidationSupplementOpen(true);
      setValidationSupplements(_data);
    } else {
      _submitSupplements(modified.map(
        importedSupplement => { 
          return { 
            imported: {...importedSupplement}, 
            original: {...supplements.find(origin => origin.name === importedSupplement.name)}, 
            reason: "modified", 
            keep: true,
          }
        }
      ));
      _submitSupplements(_data.filter(
        importedSupplement => !supplements.find(origin => origin.name === importedSupplement.name))
        .map(importedSupplement => { 
          return { imported: {...importedSupplement}, reason: "new" }}));
    }
  }



  return (
    <div style={{width: "100%"}}>
      <h4>Liste des suppléments</h4>
      <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10}}>
        <Button style={{display: "flex", alignItems: "center", gap: 5}} onClick={() => setIsModalCategorySupplementOpen(true)}>
          <Settings /> Editer les catégories
        </Button>
      </div>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 20,marginBottom: 20}}>
        <div style={{display: "flex", flexDirection: "row",alignItems: "center", gap: 10}}>
          <div>Recherche :</div>
          <Input placeholder="Rechercher un supplément" value={filter} onChange={(e) => setFilter(e.target.value)}/>
        </div>
        <Button style={{display: "flex", alignItems: "center", gap: 5}} color="primary" onClick={() => {setSelectedSupplement(null); setIsModalSupplementOpen(true)}}>
          <Plus /> Ajouter un supplément
        </Button>
        <Button style={{display: "flex", alignItems: "center", gap: 5}} color="primary" onClick={() => setIsImportModalOpen(true)}>
          <Plus /> Importer des suppléments
        </Button>
        <SheetImport isOpen={isImportModalOpen} toggle={() => setIsImportModalOpen(!isImportModalOpen)} wantedData={[{id: "Code Produit", isMandatory: true}, {id: "Libellé", isMandatory: true}, {id: "Prix HT"}, {id:"Categories"}]} onFileProcessed={(data) => submitImport(data)}/>
      </div>
      
      <Table>
        <thead>
          <tr>
            <th>Code produit</th>
            <th>Libellé</th>
            <th>Catégorie</th>
            <th>Prix</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredSupplements && filteredSupplements.map((s, index) => (
            
            <tr key={`supplement-${s.id}-${index}`} style={{backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white"}}>
              <td>{s.id ?? ""}</td>
              <td>{s.name ?? ""}</td>
              <td>{s?.category?.replaceAll("|/|", " / ") ?? ""}</td>
              <td>{s.price ? `${s.price}€` : ""}</td>
              <td style={{display: "flex", flexDirection: "row", justifyContent: "center",gap: 10}}>
                <Edit style={{cursor: "pointer", color: "#51075e"}} onClick={() => {setSelectedSupplement(s); setIsModalSupplementOpen(true);}}/>
                <Trash style={{cursor: "pointer", color: "red"}}  onClick={() => onDelete(s)}/>
              </td>
            </tr>
          
          ))}
        </tbody>
      </Table>
      <SupplementModalEditor isModalOpen={isModalSupplementOpen} onClose={() => setIsModalSupplementOpen(false)} supplement={selectedSupplement} supplementsCategories={supplementsCategories} supplements={supplements}/>
      <CategorySupplementModalEditor isModalOpen={isModalCategorySupplementOpen} onClose={() => setIsModalCategorySupplementOpen(false)} supplementsCategories={supplementsCategories}/>
      <ValidationImportSupplementModal isOpen={isModalValidationSupplementOpen} onSubmit={_submitSupplements} onClose={() => setIsModalValidationSupplementOpen(false)} supplementsOrigine={supplements} importedSupplements={validationSupplements} />
    </div>
  );
};

export default SupplementsEditor;