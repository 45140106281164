import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import PropTypes from "prop-types";
import React from "react";
import useUI from "hooks/ui.hook";
// import illustration from "./assets/Image1.jpg";
import Logo from "./assets/LogoApiProvence.jpg";
import Roboto from "fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "fonts/roboto/Roboto-BoldCondensed.ttf";
import dayjs from "dayjs";

Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});

const styles = StyleSheet.create({
  background: {
    backgroundColor: "#b5cae7",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainContainer: {
    width: "99%",
    height: "98%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    // position: "relative",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "10%",
  },
  title: {
    color: "#2d5065",
    position: "absolute",
    top:0,
    left:0,
    padding: 20,
    fontSize: 32,
    width: "100%",
    fontFamily: "Roboto",
    fontWeight: "bold",

    // overflow: "hidden",
  },
  logo: {
    width: 150,
    height: 65,
    position: "absolute",
    objectFit: "contain",
    right: 10,
    top: 5,
    zIndex: 999,
  },
  body: {
    display: "flex",
    height: "90%",
    backgroundColor: "#0bb1b3",
    display: "flex",
    flexDirection: "row",
    gap: 15,
  },
  daysGroup: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingLeft: 10,
    paddingRight: 10,
    top: -15,
    gap: 10,
  },
  dayContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    backgroundColor: "white",
    flex: 1
  },
  dayNameContainer: {
    backgroundColor: "#fbe474",
    width: "100%",
    padding: 10,
    textAlign: "center"
  },
  dayName: {
    color: "black",
    fontSize: 18,
    fontFamily: "Roboto",
  },
  dayContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
    flex: 1,
    gap: 10
  },
  halfDay: {
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    gap: 10,
  },
  sepatator: {
    width: "80%",
    height: 4,
    backgroundColor: "#0bb1b3",
    borderRadius: 5,
  },
  event: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
  },
  eventHour: {
    fontSize: 20,
    fontFamily: "Roboto",
  },
  eventTitle: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 16,
    textAlign: "center",
    whiteSpace: 'pre-wrap', 
    overflowWrap: 'break-word'
  },
  eventImage: {
    width: 150,
    height: 75,
    objectFit: "contain",
    marginTop: 10,
    zIndex: 999,
  }
});

const WeekPlanningPdf = (props) => {
  const { ui, monday, events, logo, additionnalData } = props;
  const day = new Date(monday);

  const _getDays = () => {
    let _days = [];

    for (let i = 0; i < 7; i++) {
      let day = new Date(monday);
      day.setDate(day.getDate() + i);
      _days.push(day);
    }
    return _days;
  };
  const _getEventByDay = (day) => {
    let start = new Date(day);
    let end = new Date(day);

    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(1);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);

    return events.filter((event) => event.start > start && event.end < end);
  };
  const _hourToString = (date) => {
    return dayjs(date).format("HH:mm");
  };


  const renderDay = (day) => {
    const morningEvents = _getEventByDay(day).filter(_e => dayjs(_e?.start).hour() < 12)
    const eveningEvents = _getEventByDay(day).filter(_e => dayjs(_e?.start).hour() >= 12)
    return (
      <View id="dayContainer" style={styles.dayContainer}>
        <View id="dayNameContainer" style={styles.dayNameContainer}>
          <Text style={styles.dayName}>{dayjs(day).format("dddd DD").toLocaleUpperCase()}</Text>
        </View>
        <View id="dayContent" style={styles.dayContent}>
          <View id="firstHalf" style={styles.halfDay}>
          {morningEvents.map((event, index) => (
            index < 4 ?
              <View style={styles.event} key={index}>
                <Text style={styles.eventHour}>
                  {_hourToString(event.start)} - {_hourToString(event.end)}
                </Text>
                <Text style={styles.eventTitle}>{event.title}</Text>
                { morningEvents.length < 4 && <Image src={{ uri : event.image }} style={styles.eventImage}/>}
              </View> : null
          ))}
          </View>
          <View id="separator" style={styles.sepatator}/>
          <View id="secondHalf" style={styles.halfDay}>
            {eveningEvents.map((event, index) => (
              index < 4 ?
                <View style={styles.event} key={index}>
                  <Text style={styles.eventHour}>
                    {_hourToString(event.start)} - {_hourToString(event.end)}
                  </Text>
                  <Text style={styles.eventTitle}>{event.title}</Text>
                  { eveningEvents.length < 4 && <Image src={{ uri : event.image }} style={styles.eventImage}/>}
                </View> : null
            ))}
          </View>
        </View>
      </View>
    )
  }

  return (
    <Document>
      <Page size="A3" orientation="landscape">
        <View id="background" style={styles.background}>
          <View id="mainContainer" style={styles.mainContainer}>
            
            <View id="header" style={styles.header}>
              <Image src={Logo} style={styles.logo}/>
              <Text style={styles.title}>Planning des animations du {dayjs(day).startOf("week").format("D")} au {dayjs(day).endOf("week").format("D MMM YYYY")}</Text>
            </View>

            <View id="body" style={styles.body}>
              <View style={styles.daysGroup}>
                {_getDays().slice(0, 5).map((day) => (
                  renderDay(day)
                ))}
              </View>
            </View>
          
          </View>
        </View>
      </Page>
    </Document>
  );
};

WeekPlanningPdf.propTypes = {
  monday: PropTypes.any.required,
  events: PropTypes.array.required,
  logo: PropTypes.string,
  additionnalData: PropTypes.string,
  ui: PropTypes.object.required,
};
export default WeekPlanningPdf;