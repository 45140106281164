import React, { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import initNotifications from "hooks/initNotifications";

import { Animations } from "./Animation/router_pages";
import Ateliers from "./Ateliers";
import CultureQuizzEditor from "./CultureQuizz/QuizzEdit";
import CultureQuizzQuizzList from "./CultureQuizz/QuizzList";
import CultureQuizzQuizzPlay from "./CultureQuizz/QuizzPlay";
import CultureQuizzQuizzResults from "./CultureQuizz/QuizzResults";
import CultureQuizzQuizzStart from "./CultureQuizz/QuizzStart";
import DashboardHeader from "../../config/DashboardHeader";
import { DashboardLayout } from "../../lib/layouts";
import Gazette from "./Gazette";
import GazetteEdit from "./Gazette/edit/index.js";
import Home from "./Home";
import MajorMenu from "../../config/MajorMenu";
import Menu from "./Menu/Menu";
import MenuWeekExport from "./Menu/Basic/Pages/MenuWeekExport";
import MenuDayExport from "./Menu/Basic/Pages/MenuDayExport";
import MenuRestaurantExport from "./Menu/Basic/Pages/MenuRestaurantExport";
import NewsPage from "./News";
import Notifications from "./Notifications";
import { PlanningMonthExport } from "./Animation/router_pages";
import { PlanningWeekExport } from "./Animation/router_pages";
import ProfilePage from "./Profile";
import PropTypes from "prop-types";
import ReservationAnimations from "./Reservation/Planning";
import ReservationMenus from "./Reservation/Menu";
import Tuto from "./Tutoriels/Tuto";
import UserList from "./UserList";
import UserSingle from "./UserList/Basic/Pages/UserSingle";
import useUI from "../../hooks/ui.hook";
import HomeStat from "./Statistics/statistics";
import ServiceManagement from "./Service/Management/index";
import ServiceReservation from "./Service/Reservation/index";
import HomeInterventions from "./Interventions";
import WaitingList from "./WaitingList";


import NotificationCenter from "./Notification/index";
import { SupplementsEditor } from "./Menu/router_pages.js";
import moment from "moment";

const MajorRouter = ({ match }) => {
  const [ui] = useUI();
  initNotifications();

  const features = ui?.establishment?.features;

  const isDemoEnded = useMemo(() => {
    if (ui && ui?.establishment?.demo) {
      const endDate = ui?.establishment?.endDate.toDate();
      if (moment(endDate).isBefore(moment())) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [ui]);

  return (
    <DashboardLayout menu={<MajorMenu />} header={<DashboardHeader />}>
      <Switch>
        <Route  exact path={`${match.url}/one`}>
          <Home />
        </Route>

        {!isDemoEnded && <>
          <Route  exact path={`${match.url}/users`}>
            <UserList />
          </Route>
          <Route  exact path={`${match.url}/users/:id`}>
            <UserSingle />
          </Route>
          <Route  exact path={`${match.url}/profile`}>
            <ProfilePage />
          </Route>
          <Route  exact path={`${match.url}/tuto`}>
            <Tuto />
          </Route>

          <Route  exact path={`${match.url}/notificationCenter`}>
            <NotificationCenter />
          </Route>

          {features &&
            features.includes("menuPlanning") ? (
            <Route  exact path={`${match.url}/menu`}>
              <Menu />
            </Route>
          ) : null}
          {features &&
            features.includes("menuPlanning") && features.includes("restaurant") ? (
            <Route  exact path={`${match.url}/menu/supplements`}>
              <SupplementsEditor />
            </Route>
          ) : null}
          {features &&
            features.includes("menuPlanning") ? (
            <Route  exact path={`${match.url}/menu/export`}>
              <MenuWeekExport />
            </Route>
          ) : null}
          {features &&
            features.includes("menuPlanning") && ["ovelia", "heurus"].includes(ui?.groupement?.specific) ? (
            <Route  exact path={`${match.url}/menu/exportDay`}>
              <MenuDayExport />
            </Route>
          ) : null}

          {features &&
            features.includes("menuPlanning") && (ui?.groupement?.specific === "heurus") ? (
            <Route  exact path={`${match.url}/menu/exportRestaurant`}>
              <MenuRestaurantExport />
            </Route>
          ) : null}

          {features &&
            features.includes("animationPlanning") ? (
            <Route  exact path={`${match.url}/animations`}>
              <Animations />
            </Route>
          ) : null}
          {features &&
            features.includes("animationPlanning") ? (
            <Route  exact path={`${match.url}/animations/weekExport`}>
              <PlanningWeekExport />
            </Route>
          ) : null}
          {features &&
            features.includes("animationPlanning") ? (
            <Route  exact path={`${match.url}/animations/monthExport`}>
              <PlanningMonthExport />
            </Route>
          ) : null}

          {features &&
            features.includes("news") ? (
            <Route  exact path={`${match.url}/news`}>
              <NewsPage />
            </Route>
          ) : null}

          {features &&
            features.includes("AnimationWorkshopDatabase") ? (
            <Route path={`${match.url}/ateliers`}>
              <Ateliers />
            </Route>
          ) : null}

          {features &&
            features.includes("cultureQuizz") ? (
            <Route  exact path={`${match.url}/cultureQuizz`}>
              <CultureQuizzQuizzList />
            </Route>
          ) : null}
          {features &&
            features.includes("cultureQuizz") ? (
            <Route  exact path={`${match.url}/cultureQuizz/edit/:uid`}>
              <CultureQuizzEditor />
            </Route>
          ) : null}
          {features &&
            features.includes("cultureQuizz") ? (
            <Route  exact path={`${match.url}/cultureQuizz/:uid/start`}>
              <CultureQuizzQuizzStart />
            </Route>
          ) : null}
          {features &&
            features.includes("cultureQuizz") ? (
            <Route  exact path={`${match.url}/cultureQuizz/:uid/play`}>
              <CultureQuizzQuizzPlay />
            </Route>
          ) : null}
          {features &&
            features.includes("cultureQuizz") ? (
            <Route  exact path={`${match.url}/cultureQuizz/:uid/results`}>
              <CultureQuizzQuizzResults />
            </Route>
          ) : null}
          {features &&
            features.includes("banner") ? (
            <Route exact path={`${match.url}/banner`}>
              <Notifications />
            </Route>
          ) : null}
          {features &&
            features.includes("reservationMenu") ? (
            <Route exact path={`${match.url}/reservation/menus`}>
              <ReservationMenus />
            </Route>
          ) : null}
          {features &&
            features.includes("reservationPlanning") ? (
            <Route exact path={`${match.url}/reservation/animations`}>
              <ReservationAnimations />
            </Route>
          ) : null}

          {features &&
            features.includes("gazette") ? (
            <Route exact path={`${match.url}/gazette/:uid/edit`}>
              <GazetteEdit />
            </Route>
          ) : null}
          {features &&
            features.includes("gazette") ? (
            <Route exact path={`${match.url}/gazette/edit`}>
              <GazetteEdit />
            </Route>
          ) : null}
          {features &&
            features.includes("gazette") ? (
            <Route  exact path={`${match.url}/gazette`}>
              <Gazette />
            </Route>
          ) : null}

          {features &&
            features.includes("interventionsRequest") ? (
            <Route exact path={`${match.url}/interventions`}>
              <HomeInterventions />
            </Route>
          ) : null}

          {features ? <Route exact path={`${match.url}/statistics`}><HomeStat /></Route> : null}

          {features &&
            features.includes("services") ? (
            <Route exact path={`${match.url}/service-management`}>
              <ServiceManagement />
            </Route>
          ) : null}

          {features &&
            features.includes("services") ? (
            <Route exact path={`${match.url}/reservation/services`}>
              <ServiceReservation />
            </Route>
          ) : null}
          {features &&
            features.includes("banner") || features.includes("animationPlanning") ? (
            <Route exact path={`${match.url}/waitingList`}>
              <WaitingList />
            </Route>
          ) : null}


        </>}
        <Route path={"*"}>
          <Redirect to={`${match.url}/one`} />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};

MajorRouter.propTypes = {
  match: PropTypes.object.isRequired,
};

export default MajorRouter;
