import firebase from "firebase";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

const storage = firebase.storage;
const typesAutorises = ["png", "jpg", "jpeg", "gif", "bmp", "webp", "ico", "svg"];

const _compress = (dataUrl, maxWidth = 800, maxHeight = 600, quality = 0.7) => new Promise((resolve, reject) => {
  const MIME_TYPE = "image/jpeg";

  function calculateSize(img, maxWidth, maxHeight) {
    let width = img.width;
    let height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
        }
    }
    return [width, height];
  }

  const blobURL = dataUrl;
  const img = new Image();
  img.onerror = function () {
    URL.revokeObjectURL(this.src);
    console.log("Cannot load image");
    reject();
  };
  img.onload = function () {
    URL.revokeObjectURL(this.src);
    const [newWidth, newHeight] = calculateSize(img, maxWidth, maxHeight);
    const canvas = document.createElement("canvas");
    canvas.width = newWidth;
    canvas.height = newHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, newWidth, newHeight);
    canvas.toBlob(
      (blob) => {
        resolve(URL.createObjectURL(blob));
      },
      MIME_TYPE,
      quality
    );
  };
  img.src = blobURL;
})

export function formFileToLocalUrl(eTargetFile) {
  let extension = eTargetFile.name.split(".").pop().toLowerCase();
  
  if(!typesAutorises.includes(extension)) {
    toast.warning("Le format ''"+extension+"'' n'est pas autorisé. Seuls les fichiers PNG, JPG, JPEG, GIF, BMP, WEBP, ICO et SVG sont autorisés.");
    return null;
  }
  let localUrl = URL.createObjectURL(eTargetFile);
  return localUrl;
}

export function urlToDataUrl(url) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.onload = function() {
      let reader = new FileReader();
      reader.onloadend = function() {
        resolve(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}

//if you want to compress the image before uploading
//define maxWidth, maxHeight and quality, otherwise no compression applied
export async function uploadDataUrl(ui, url, eName = "", maxWidth = -1, maxHeight = 600, quality = 0.7) {
  const target = ui.user.role === "superadmin" ?  ui.user.role : ui.user.establishment;
  let hash = uuidv4();

  const toUpload = maxWidth > 0 ? await _compress(url, maxWidth, maxHeight, quality) : url;

  await storage().ref(`/uploads/${target}/${ui.user.establishment}/${hash+eName}`).putString(await urlToDataUrl(toUpload), "data_url");
  let downloadUrl = await storage().ref(`/uploads/${target}/${ui.user.establishment}`).child(hash+eName).getDownloadURL();

  return downloadUrl;
}

//if you want to compress the image before uploading
//define maxWidth, maxHeight and quality, otherwise no compression applied
export async function uploadFormFile(ui, eTargetFile, maxWidth = -1, maxHeight = 600, quality = 0.7) {
  const name = eTargetFile.name;
  const localUrl = formFileToLocalUrl(eTargetFile);

  return await uploadDataUrl(ui, localUrl, name, maxWidth, maxHeight, quality);
}

